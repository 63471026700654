.variantHeaderPopup {
  width: 100%;
}
.variantPricePopup {
  float: right;
}
.variantFoodPopup {
  float: left;
}
.linevariantPopup {
  width: 100%;
}
.ProductImagePopup {
  width: 100%;
  height: 175px;
}

.variant-container {
  display: flex;
  flex-direction: column;
}

.variant-title-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 4% auto;
  font-weight: bold;
  font-size: 1.2em;
}

.option-container {
  display: flex;
  flex-direction: row;
  font-size: 1em;
  justify-content: space-between;
  align-items: center;
}

.option-container div {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 100%;
  text-align: center;
}

.amount-variant {
  font-weight: 800;
}

.variant-container .MuiFormControlLabel-root,
.variant-container .MuiFormControlLabel-root .MuiTypography-root {
  width: 100%;
  text-align: left;
}

.variant-container .MuiFormControlLabel-root .MuiChip-root {
  width: max-content;
  margin-left: 10px;
}

.variant-container .reqrdButton {
  background: #ffeae2;
  display: flex;
  align-items: center;
  border: none;
  color: #ff4500;
  font-size: 15px;
  padding: 5px 12px;
  border-radius: 100px;
}
