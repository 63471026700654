/* form {
  width: 30vw;
  min-width: 500px;
  align-self: center; */


/* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07); */


/* border-radius: 7px;
  padding: 40px;
} */

.paymentTrans {
    text-align: center;
    margin-top: 14%;
    height: 18vh;
}

#payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
}

#payment-element {
    margin-bottom: 24px;
}


/* Buttons and links */

.payNowButtonPayment {
    background: #ff6838;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}

button:hover {
    filter: contrast(115%);
}

button:disabled {
    opacity: 0.5;
    cursor: default;
}


/* spinner/processing state, errors */

.spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}

.spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner:before,
.spinner:after {
    position: absolute;
    content: '';
}

.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 576px) {
    form {
        width: 100%;
        min-width: initial;
    }
    button {
        background: #ff6838;
        font-family: Arial, sans-serif;
        color: #ffffff;
        border-radius: 4px;
        border: 0;
        padding: 5px 16px;
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;
        display: block;
        transition: all 0.2s ease;
        box-shadow: 0px 4px 5.5px 0px rgb(0 0 0 / 7%);
        /* width: 87%; */
        margin-left: 7px;
    }
}

@media (min-width:576px) and (max-width: 769px) {
    form {
        width: 100%;
        min-width: initial;
    }
    button {
        background: #ff6838;
        font-family: Arial, sans-serif;
        color: #ffffff;
        border-radius: 4px;
        border: 0;
        padding: 5px 16px;
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;
        display: block;
        transition: all 0.2s ease;
        box-shadow: 0px 4px 5.5px 0px rgb(0 0 0 / 7%);
        /* width: 87%; */
        margin-left: 7px;
    }
}

@media screen and (min-width: 320px) and (max-width: 376px) {
    .paymentTrans {
        margin-top: 64vw;
        margin-bottom: 59vw;
    }
    #payment-form {
        min-width: auto;
        width: 90%;
        margin-left: 3%;
    }
}

@media screen and (min-width: 376px) and (max-width: 430px) {
    .paymentTrans {
        margin-top: 62vw;
        margin-bottom: 34vw;
    }
    #payment-form {
        width: 92%;
        margin-left: 3%;
    }
}

@media screen and (min-width: 431px) and (max-width: 600px) {
    .paymentTrans {
        margin-top: 50vw;
        height: 33vh;
    }
    #payment-form {
        width: 92%!important;
        margin-left: 5%!important;
    }
}

@media screen and (min-width: 431px) and (max-width: 500px) {
    .p-HeightObserverProvider {
        width: 97%;
    }
}

@media screen and (min-width: 601px) and (max-width: 820px) {
    .paymentTrans {
        margin-top: 46%;
        height: 13vh;
    }
}


/* @media screen and (min-width: 821px) and (max-width: 915px) {
    .paymentTrans{
        margin-top: 32%;
        height: 25vh;
    }
} */

@media screen and (min-width: 577px) and (max-width: 800px) {
    #payment-form {
        width: 92%;
        margin-left: 3%;
    }
}

@media screen and (min-width: 801px) and (max-width: 998px) {
    #payment-form {
        width: 72%;
        margin-left: 3%;
        margin-bottom: 4%;
    }
}