.arrow_left {
  color: black !important;
  background-color: white !important;
  border: 1px solid orangered !important;
  border-radius: 25px;
  width: 11%;
}

.arrow-leftIcon {
  font-size: 120%;
  font-weight: 700;
}

.arrow_right {
  color: white;
  background-color: orangered;
  width: 11%;
  border: 1px solid orangered;
  border-radius: 25px;
}

.arrow_rightIcon {
  font-size: 120%;
  font-weight: 700;
}

.trendingImage {
  width: 100%;
  height: 150px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  border: 1px solid rgba(0, 0, 0, 0.03);
  margin-top: -1.2px !important;
  border-top: 2px solid rgba(0, 0, 0, 0.03);
  object-fit: cover;
}

.BestSellerCardHomepage {
  cursor: pointer;
}

@media (min-width: 576px) and(max-width:796px) {
  .minimum_value {
    position: absolute;
    top: 30%;
    left: 32%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 11px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  }
  .SearchButtonSE {
    font-size: 119% !important;
    font-weight: 600 !important;
    height: 30% !important;
    margin-top: 93% !important;
    width: 90% !important;
  }
}
@media only screen and (min-width: 796px) and (max-width: 992px) {
  .SearchButtonSE {
    font-size: 119% !important;
    font-weight: 600 !important;
    height: 30% !important;
    margin-top: 79% !important;
    width: 90% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .SearchButtonSE {
    font-size: 119% !important;
    font-weight: 600 !important;
    height: 30% !important;
    margin-top: 54% !important;
    width: 90% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1444px) {
  .SearchButtonSE {
    font-size: 119% !important;
    font-weight: 600 !important;
    height: 33% !important;
    margin-top: 43% !important;
    width: 49% !important;
  }
}

@media (min-width: 992px) and(max-width:1200px) {
  .SearchButtonSE {
    font-size: 119% !important;
    font-weight: 600 !important;
    height: 30% !important;
    margin-top: 93% !important;
    width: 90% !important;
  }
}
@media (min-width: 796px) and(max-width:1200px) {
  .trendingImage {
    width: 100%;
    height: 150px;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    border: 1px solid rgba(0, 0, 0, 0.03);
    margin-top: -1.2px !important;
    border-top: 2px solid rgba(0, 0, 0, 0.03);
  }
}

@media (min-width: 1200px) and(max-width: 1440px) {
  .trendingImage {
    width: 100%;
    height: 150px;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    border: 1px solid rgba(0, 0, 0, 0.03);
    margin-top: -1.2px !important;
    border-top: 2px solid rgba(0, 0, 0, 0.03);
  }
}
