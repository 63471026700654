.profileContainer .modal-content {
    padding: 20px 20px 40px 20px !important;
}
.EditButtonProfilepop{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.updateButton {
    width: 34%;
    margin: 20% auto;
}

.icons {
    width: 100%;
    text-align: center;
}

.profileImg {
    border-radius: 50%;
    height: 125px;
}

@media (min-width: 320px) and (max-width:376px) {
    .icons {
        width: 100%;
        /* text-align: center; */
        margin-bottom: 15px;
        margin-left: -122px!important;
    }
    .profileImg {
        border-radius: 50%;
        height: auto;
        width: 100px;
    }
}

@media (min-width: 376px) and (max-width:450px) {
    .ProfileDataItems{
        text-align: center;
    }
    .icons {
        width: 100%;
        margin-bottom: 15px;
       
    }
    .profileImg {
        border-radius: 50%;
        height: auto;
        width: 100px;
    }
}

@media (min-width: 450px) and (max-width:576px) {
    .icons {
        width: 100%;
        /* text-align: center; */
        margin-bottom: 15px;
        margin-left: -122px!important;
    }
    .profileImg {
        border-radius: 50%;
        height: auto;
        width: 100px;
    }
}

@media (min-width: 576px) and (max-width:769px) {
    .icons {
        width: 100%;
        text-align: initial;
        margin-bottom: 15px;
    }
    .profileImg {
        border-radius: 50%;
        height: auto;
        width: 100px;
    }
    .profileImg {
        border-radius: 50%;
        height: 100px;
        width: auto;
    }
    .profileContainer .modal-content {
        padding: 20px 20px 40px 20px !important;
        width: 65%;
    }
}

@media (min-width: 769px) and (max-width:992px) {
    .ProfileDataProfile{
        margin-left: 12%;
        }
    .profile-left-orders {
        margin-top: 15%;
    }
    .term-condition {
    font-size: 95%;
    }
    .icons {
        width: 100%;
        text-align: initial;
        margin-bottom: 15px;
        margin-left: 24%!important;
    }
    .profileImg {
        border-radius: 50%;
        height: auto;
        width: 100px;
    }
    .profileImg {
        border-radius: 50%;
        height: 100px;
        width: auto;
        margin-left: 15%!important;
    }
    .profileContainer .modal-content {
        padding: 20px 20px 40px 20px !important;
        width: 65%;
    }
}