.TermsBeverage {
    font-size: 1.1rem;
    font-weight: 700;
    text-align: center;
    width: 90%;
    margin: auto;
}

.terms {
    margin: 4% auto;
    width: 80%;
}

.checkBoxBeverages {
    width: 35%;
    margin-left: 10%;
}

.beverages-btn {
    display: flex;
    width: 60%;
    justify-content: space-around;
    margin: 4% auto;
}

.beverageTermsModal {
    --bs-modal-width: 500px!important;
}

.WarningBeverageText {
    font-size: 1vw;
    font-weight: 400;
    width: 90%;
    margin: auto;
    text-align: justify;
}

.hr-tag {
    margin: 4% auto;
    width: 80%;
}

.hrlinebeverage {
    width: 95%;
}

.conditionTextBeverages {
    font-size: 1vw;
    font-weight: 400;
}

.consumealcoholtext {
    margin-left: 20%;
}

.checkDontAskBeverage {
    margin-left: -2%;
}

.form-check-input {
    border: 1px solid black;
    width: 1.3em;
    height: 1.3em;
}

.AcceptBeverages {
    width: 120px;
    background-color: orangered!important;
    color: white !important;
}

.DeclineBeverages {
    width: 120px;
    color: #FF6838!important;
    background-color: white!important;
    border: 1px solid black!important;
}

@media screen and (max-width: 576px) {
    .checkBoxBeverages {
        width: 50%;
        margin-left: 10%;
    }
    .WarningBeverageText {
        font-size: 10px;
        font-weight: 400;
        width: 90%;
        margin: auto;
        text-align: center;
        margin-top: 10px;
    }
}

@media (min-width: 567px) and (max-width: 769px) {
    .checkBoxBeverages {
        width: 50%;
        margin-left: 10%;
    }
    .WarningBeverageText {
        font-size: 12px;
        font-weight: 400;
        width: 90%;
        margin: auto;
        text-align: center;
        margin-top: 10px;
    }
}