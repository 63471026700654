.AddressCard {
    border-radius: 0px;
    text-align: center;
    /* border: 1px solid black; */
    width: 100%;
}

.AddAddress {
    border-radius: 0px;
    text-align: center;
    /* border: 1px solid black; */
    width: 100%;
}

.address {
    font-weight: 600;
}

.delivere_here {
    font-size: small;
}


/* .DeliveryAddressPaymentPage,.address{
    margin-bottom: 21%!important;
} */

.AddNewAddressPayment {
    text-align: center;
}

.HomeLocationPayment {
    font-size: 1.3vw;
    padding-top: 0%;
}

.AddAddressPayment {
    font-size: 1.4vw;
}

.DeliveryAddressPayment {
    font-weight: 700;
    font-size: 120%;
    margin: 2% 8%;
}

.AddressDetailsContainer {
    width: 80%;
    display: grid;
    grid-template-columns: 50% 50%;
    margin: auto 10%;
    max-width: 100%;
}

.DeliveryAddressPaymentPage {
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    font-size: 0.9em;
    font-weight: 500;
    display: block;
}

.CurruntAddresspayment {
    width: 100%;
    border-radius: 0px!important;
    max-width: 90%;
    border: 1px solid #ddd;
    display: inherit;
}

.CurruntAddresspayment p {
    text-align: center;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    width: 80%;
    margin: auto;
}

.locationIconpayment {
    text-align: center;
    font-weight: 700;
    align-items: center;
    display: inherit;
}

.addAddressPayment {
    text-align: center;
}

.addAddressPaymentCard {
    width: 110%;
    border-radius: 0px!important;
    max-width: 120%;
}

.addNewIconPayment {
    margin: 10% 3%;
}

.Add_newAddressPayment {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin: 3% 30% 10% 30%;
}

.addNewAddressButtonPayment {
  
    border: 1px solid #FF6838!important;
}

@media screen and (max-width: 376px) {
    .DeliveryAddressPayment {
        font-weight: 700;
        font-size: 120%;
        margin: 2% 3%;
    }
    .addAddressPaymentCard {
        width: 100%;
        border-radius: 0px!important;
        max-width: 90%;
    }
    .CurruntAddresspayment {
        width: 128%;
        border-radius: 0px!important;
        max-width: 116%;
        border: 1px solid #ddd;
        display: inherit;
        margin-left: -25px;
    }
    .CurruntAddresspayment p {
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        margin: auto;
    }
    .DeliveryAddressPaymentPage {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size:71%!important;
        font-weight: 500;
        display: block;
    }
    .AddAddressPayment {
        font-size: 1.4vw;
    }
    .Add_newAddressPayment {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin: 3% 30% 10% 30%;
        font-size: 7px;
    }
    .addAddressPayment {
        text-align: center;
        font-size: 13px;
    }
    .addNewAddressButtonPayment {
        color: black !important;
        border: 1px solid #FF6838!important;
        width: 63px;
        font-size: 7.6px;
        font-weight: 600;
        margin-left: -15px;
    }
    .addAddressPaymentCard {
        width: 118%;
        border-radius: 0px!important;
        max-width: 129%;
    }
    .addNewIconPayment {
        margin: 10% 2%;
        font-size: 17px;
    }
    .hr {
        margin: 6rem 8px;
        color: inherit;
        border: 0;
        border-top: 1px solid;
        opacity: 0.25;
    }
    .text {
        font-size: 18px!important;
        margin-left: 6px;
        margin-top: -28px;
    }
}

@media (min-width: 376px) and (max-width:576px) {
    .DeliveryAddressPayment {
        font-weight: 700;
        font-size: 120%;
        margin: 2% 3%;
    }
    .addAddressPaymentCard {
        width: 100%;
        border-radius: 0px!important;
        max-width: 90%;
    }
    .CurruntAddresspayment {
        width: 128%;
        border-radius: 0px!important;
        max-width: 116%;
        border: 1px solid #ddd;
        display: inherit;
        margin-left: -25px;
    }
    .CurruntAddresspayment p {
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        margin: auto;
    }
    .DeliveryAddressPaymentPage {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 70%!important;
        font-weight: 500;
        display: block;
    }
    .AddAddressPayment {
        font-size: 1.4vw;
    }
    .Add_newAddressPayment {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin: 3% 30% 10% 30%;
        font-size: 7px;
    }
    .addAddressPayment {
        text-align: center;
        font-size: 13px;
    }
    .addNewAddressButtonPayment {
        color: black !important;
        border: 1px solid #FF6838!important;
        width: 63px;
        font-size: 7.6px;
        font-weight: 600;
        margin-left: -15px;
    }
    .addAddressPaymentCard {
        width: 118%;
        border-radius: 0px!important;
        max-width: 129%;
    }
    .addNewIconPayment {
        margin: 10% 2%;
        font-size: 17px;
    }
    .hr {
        margin: 6rem 8px;
        color: inherit;
        border: 0;
        border-top: 1px solid;
        opacity: 0.25;
    }
    .text {
        font-size: 18px!important;
        margin-left: 6px;
        margin-top: -28px;
    }
}

@media (min-width: 576px) and (max-width:796px) {
    .DeliveryAddressPayment {
        font-weight: 700;
        font-size: 120%;
        margin: 2% 3%;
    }
    .addAddressPaymentCard {
        width: 100%;
        border-radius: 0px!important;
        max-width: 90%;
    }
    .CurruntAddresspayment {
        width: 128%;
        border-radius: 0px!important;
        max-width: 116%;
        border: 1px solid #ddd;
        display: inherit;
        margin-left: -25px;
    }
    .CurruntAddresspayment p {
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        margin: auto;
        font-size: 20px;
        font-weight: 500;
    }
    .DeliveryAddressPaymentPage {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px!important;
        font-weight: 500;
        display: block;
    }
    .AddAddressPayment {
        font-size: 1.4vw;
    }
    .Add_newAddressPayment {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin: 3% 30% 10% 30%;
        font-size: 7px;
    }
    .addAddressPayment {
        text-align: center;
        font-size: 20px;
    }
    .addNewAddressButtonPayment {
        color: black !important;
        border: 1px solid #FF6838!important;
        width: 115px;
        font-size: 13px;
        font-weight: 600;
        margin-left: -15px;
    }
    .addAddressPaymentCard {
        width: 118%;
        border-radius: 0px!important;
        max-width: 129%;
    }
    .addNewIconPayment {
        margin: 10% 2%;
        font-size: 17px;
    }
    .hr {
        margin: 6rem 8px;
        color: inherit;
        border: 0;
        border-top: 1px solid;
        opacity: 0.25;
    }
    .text {
        font-size: 18px!important;
        margin-left: 6px;
        margin-top: -28px;
    }
}