.title_text {
    text-align: center;
    padding-top: 20px;
    font: bolder;
}

.contact {
    text-align: center;
    color: #FF6838;
    font-family: 'Playfair Display', serif;
    font-size: 3.7vw;
    font-weight: 600;
    margin-top: 2%;
}

.style5 {
    color: #FF6838;
    border-top: 3px dashed #FF6838;
    /* box-shadow: 0 0 0 5px red; */
    height: 1px;
    /* border: none; */
}

.contact_media_buttons_media {
    /* width: 35px;
  height: 47px;
  background-color:#FF6838;
  border: none;
  border-radius: 25px;
cursor: pointer; */
    font-size: 1.5vw;
    font-weight: 900;
}
.name{
    width: 52%;
}
.name input {
    border-radius: 20px;
    height: 52px;
    /* width: 52%; */
    align-items: center;
    justify-content: center;
}
.pass-change-toggle{
    right: 25px;
    top: 10px;
}

.tele_icon,
.message {
    color: white;
}

.contact_button {
    width: 5px;
}

.mediaButonsLanding {
    grid-gap: 10px;
}

.message_button {
    background-color: #FF6838 !important;
    width: 90%;
    height: 53px;
    border-radius: 23px;
    color: white !important;
    border: none;
    font-weight: 400;
    font-size: x-large;
}

.theme-btn {
    background-color: #FF6838 !important;
    color: white !important;
    width: 100%;
}

.message_box {
    border-radius: 25px;
    width: 52%;
}

.contact_media_buttons {
    width: 50px;
    height: 47px;
    background-color: #FF6838 !important;
    color: white !important;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    --bs-btn-hover-bg: #FF6838;
    --bs-btn-focus-shadow-rgb: none;
}

.btn-primary {
    --bs-btn-hover-bg: #FF6838;
    --bs-btn-focus-shadow-rgb: none;
}

.contactUsformLine {
    width: 7vw;
    border: none;
    height: 6px;
    background-color: #FF6838;
    opacity: 100%;
    margin: auto;
}

.SendMessageAddress {
    text-align: center;
    font-size: 2vw;
    margin-top: 2%;
}

.name::placeholder,
.message_box::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1;
    /* Firefox */
    font-weight: 400;
    font-size: 1.3vw;
}

.ContactUsForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message_button {
    max-width: 52%;
    margin-top: 2%;
}

.social-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 25%;
    margin: 3% auto;
}

@media (min-width: 320px) and (max-width: 376px) {
    .name::placeholder,
    .message_box::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: black;
        opacity: 1;
        /* Firefox */
        font-weight: 400;
        font-size: 4.3vw;
    }
    .ContactUsForm {
        margin-right: -30%!important;
    }
    .SendMessageAddress {
        text-align: center;
        font-weight: 600;
        margin-right: 2%!important;
        font-size: 3.7vw;
    }
    .contact {
        text-align: center;
        color: #FF6838;
        font-family: 'Playfair Display', serif;
        font-size: 7vw!important;
        font-weight: 600;
        margin-top: 2%;
    }
    .contactUsformLine {
        width: 22svw;
        border: none;
        height: 6px;
        background-color: #FF6838;
        opacity: 100%;
        margin: auto;
    }
    .name {
        border-radius: 20px;
        height: 52px;
        width: 90%;
        align-items: center;
        justify-content: center;
    }
    .message_box {
        border-radius: 25px;
        width: 90%;
    }
    .message_button {
        max-width: 52%;
        margin-top: 2%;
        font-size: 16px;
    }
    .tele_icon,
    .message {
        color: white;
        font-size: 20px;
    }
    .social-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 25%;
        margin: 3% auto;
        flex-wrap: nowrap
    }
}

@media (min-width: 376px) and (max-width: 450px) {
    .name::placeholder,
    .message_box::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: black;
        opacity: 1;
        /* Firefox */
        font-weight: 400;
        font-size: 4.3vw;
    }
    .ContactUsForm {
        margin-left: 1%!important;
    }
    .SendMessageAddress {
        text-align: center;
        font-weight: 600;
        margin-right: 2%!important;
        font-size: 4vw;
        margin-left: 16px;
    }
    .contact {
        text-align: center;
        color: #FF6838;
        font-family: 'Playfair Display', serif;
        font-size: 7vw!important;
        font-weight: 600;
        margin-top: 2%;
        margin-left: 6px;
    }
    .contactUsformLine {
        width: 22svw;
        border: none;
        height: 6px;
        background-color: #FF6838;
        opacity: 100%;
        margin: auto;
        margin-left: 147px;
    }
    .contact_media_buttons {
        width: 50px;
        height: 47px;
        background-color: #FF6838 !important;
        color: white !important;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        --bs-btn-hover-bg: #FF6838;
        --bs-btn-focus-shadow-rgb: none;
        margin-left: 12px;
    }
    .contact_media_buttons_media {
        /* width: 35px;
      height: 47px;
      background-color:#FF6838;
      border: none;
      border-radius: 25px;
    cursor: pointer; */
        font-size: 1.5vw;
        font-weight: 900;
        margin-left: 50px;
    }
    .name {
        border-radius: 20px;
        height: 52px;
        width: 90%;
        align-items: center;
        justify-content: center;
    }
    .message_box {
        border-radius: 25px;
        width: 90%;
    }
    .message_button {
        max-width: 52%;
        margin-top: 2%;
        font-size: 16px;
    }
    .tele_icon,
    .message {
        color: white;
        font-size: 20px;
    }
    .social-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 38%;
        margin: 3% auto 3% 31%;
        flex-wrap: nowrap;
    }
}

@media (min-width: 451px) and (max-width: 576px) {
    .badge123{
    padding: 2px 15%!important;
    right: -86%!important;
    top: 59%!important;
    }
    .name::placeholder,
    .message_box::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: black;
        opacity: 1;
        /* Firefox */
        font-weight: 400;
        font-size: 4.3vw;
    }
    .ContactUsForm {
        margin-left: 1%!important;
    }
    .SendMessageAddress {
        text-align: center;
        font-weight: 600;
        margin-right: 2%!important;
        font-size: 4vw;
        margin-left: 26px;
    }
    .contact {
        text-align: center;
        color: #FF6838;
        font-family: 'Playfair Display', serif;
        font-size: 7vw!important;
        font-weight: 600;
        margin-top: 2%;
        margin-left: 66px;
    }
    .contactUsformLine {
        width: 22svw;
        border: none;
        height: 6px;
        background-color: #FF6838;
        opacity: 100%;
        margin: auto;
        margin-left: 218px;
    }
    .contact_media_buttons {
        width: 50px;
        height: 47px;
        background-color: #FF6838 !important;
        color: white !important;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        --bs-btn-hover-bg: #FF6838;
        --bs-btn-focus-shadow-rgb: none;
        margin-left: 12px;
    }
    .contact_media_buttons_media {
        /* width: 35px;
      height: 47px;
      background-color:#FF6838;
      border: none;
      border-radius: 25px;
    cursor: pointer; */
        font-size: 1.5vw;
        font-weight: 900;
        margin-left: 50px;
    }
    .name {
        border-radius: 20px;
        height: 52px;
        width: 90%;
        align-items: center;
        justify-content: center;
    }
    .message_box {
        border-radius: 25px;
        width: 90%;
    }
    .message_button {
        max-width: 52%;
        margin-top: 2%;
        font-size: 16px;
    }
    .tele_icon,
    .message {
        color: white;
        font-size: 20px;
    }
    .social-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 38%;
        margin: 3% auto 3% 32%;
        flex-wrap: nowrap;
    }
    .theme-btn {
        background-color: #FF6838 !important;
        color: white !important;
        width: 73%;
    }
}

@media (min-width: 576px) and (max-width: 769px) {
    .name::placeholder,
    .message_box::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: black;
        opacity: 1;
        /* Firefox */
        font-weight: 400;
        font-size: 2.5vw;
    }
    .ContactUsForm {
        margin-left: 0%!important;
    }
    .SendMessageAddress {
        text-align: center;
        font-weight: 600;
        margin-right: -42%!important;
        font-size: 2.7vw;
        margin-left: -210px;
    }
    .contact {
        text-align: center;
        color: #FF6838;
        font-family: 'Playfair Display', serif;
        font-size: 7vw!important;
        font-weight: 600;
        margin-top: 2%;
        margin-left: 178px;
        align-items: center;
        text-align: inherit;
    }
    .contactUsformLine {
        width: 15svw;
        border: none;
        height: 6px;
        background-color: #FF6838;
        opacity: 100%;
        margin: auto;
        margin-left: 230px;
        position: static;
    }
    .contact_media_buttons {
        width: 50px;
        height: 47px;
        background-color: #FF6838 !important;
        color: white !important;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        --bs-btn-hover-bg: #FF6838;
        --bs-btn-focus-shadow-rgb: none;
        margin-left: 12px;
    }
    .contact_media_buttons_media {
        /* width: 35px;
      height: 47px;
      background-color:#FF6838;
      border: none;
      border-radius: 25px;
    cursor: pointer; */
        font-size: 1.5vw;
        font-weight: 900;
        margin-left: 50px;
    }
    .name {
        border-radius: 20px;
        height: 52px;
        width: 90%;
        align-items: center;
        justify-content: center;
    }
    .message_box {
        border-radius: 25px;
        width: 90%;
    }
    .message_button {
        max-width: 52%;
        margin-top: 2%;
        font-size: 20px;
    }
    .tele_icon,
    .message {
        color: white;
        font-size: 20px;
    }
    .social-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 38%;
        margin: 3% auto 3% 32%;
        flex-wrap: nowrap;
    }
    .theme-btn {
        background-color: #FF6838 !important;
        color: white !important;
        width: 90%;
    }
}

@media (min-width:769px) and (max-width:948px) {
    .name::placeholder,
    .message_box::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: black;
        opacity: 1;
        /* Firefox */
        font-weight: 400;
        font-size: 2.5vw;
    }
    .ContactUsForm {
        margin-left: 0%!important;
    }
    .SendMessageAddress {
        text-align: center;
        font-weight: 600;
        margin-right: -42%!important;
        font-size: 2.7vw;
        margin-left: -276px;
    }
    .contact {
        text-align: center;
        color: #FF6838;
        font-family: 'Playfair Display', serif;
        font-size: 7vw!important;
        font-weight: 600;
        margin-top: 2%;
        margin-left: 30px;
    }
    .contactUsformLine {
        width: 15svw;
        border: none;
        height: 6px;
        background-color: #FF6838;
        opacity: 100%;
        margin: auto;
        margin-left: 286px;
        position: static;
    }
    .contact_media_buttons {
        width: 50px;
        height: 47px;
        background-color: #FF6838 !important;
        color: white !important;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        --bs-btn-hover-bg: #FF6838;
        --bs-btn-focus-shadow-rgb: none;
        margin-left: 12px;
    }
    .contact_media_buttons_media {
        /* width: 35px;
      height: 47px;
      background-color:#FF6838;
      border: none;
      border-radius: 25px;
    cursor: pointer; */
        font-size: 1.5vw;
        font-weight: 900;
        margin-left: 50px;
    }
    .name {
        border-radius: 20px;
        height: 52px;
        width: 90%;
        align-items: center;
        justify-content: center;
    }
    .message_box {
        border-radius: 25px;
        width: 90%;
    }
    .message_button {
        max-width: 52%;
        margin-top: 2%;
        font-size: 22px;
    }
    .tele_icon,
    .message {
        color: white;
        font-size: 20px;
    }
    .social-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 38%;
        margin: 3% auto 3% 31%;
        flex-wrap: nowrap;
    }
}