.minimum_valueGrocery {
  position: absolute;
  top: 43%;
  left: 72%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  background-color: orangered !important;
  font-size: 11px;
  border: none;
  cursor: pointer;
  border-radius: 1px;
}
.mobileViewScrollCatagory {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.PreOrderLable {
  width: 100%;
  border-radius: 4px !important;
  background-color: #ff6838 !important;
  position: absolute;
  top: 111px;
  max-width: 70px;
  left: 5px;
}
.DeliveryHandledByGrocery {
  position: absolute;
  top: 53%;
  left: 68%;
  width: 12vw;
  background: white !important;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: black;
  background-color: white !important;
  font-size: 65%;
  border: none;
  cursor: pointer;
  border-radius: 1px;
}
.GroceryCardHomePage {
  cursor: pointer;
}
