.Myorders {
  border-radius: 0px;
  width: 57vw;
  border-radius: "none";
}
.RatingRestImage {
  margin-bottom: 2%;
  width: 12%;
  margin-left: 4%;
  object-fit: cover;
}
.ButtonMyOrderReview {
  height: 38px;
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.WrirReviewButton {
  background-color: orangered !important;
  color: white !important;
  width: 66%;
}
.Details,
.Details1,
.Details2,
.Details3,
.Details4,
.Details5,
.Details6 {
  font-weight: 600;
  font-size: 1vw;
}
@media only screen and (min-width: 796px) and (max-width: 1800px) {
  .addr-name {
    font-size: 1.2vw !important;
    font-weight: 500 !important;
  }
}
.verticalLine {
  height: 70%;
  border-left: 1px solid #7d757b;
  white-space: pre;
}

.verticalLine3 {
  border-left: 1px solid #7d757b;
}

.detailContent {
  font-size: small;
}

.DeliveryAddress {
  font-size: small;
}

.itemDeliveryAddressProfile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #000000;
  border: none !important;
  width: 110% !important;
}

.order-container {
  display: flex;
  flex-direction: row;
  width: 95%;
  margin: 2% auto;
}

.restaurant_redirect {
  cursor: pointer;
}

.order-img {
  width: 12%;
}

.order-img img {
  width: 90%;
  height: 90%;
}

.order-title {
  width: 80%;
}

.shedule-content {
  justify-content: space-between;
}

.shedule-content div {
  /* border-right: 1px solid #ddd; */
  width: 100%;
  text-align: center;
}

.shedule-content div:last-child {
  border-right: 0 !important;
}

.rest-name {
  font-size: 1.5vw;
}

.addr-name {
  font-size: 1vw;
  font-weight: 500;
}

.order-global-container {
  border: 1px solid #ddd;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}

.schedule-time {
  display: flex;
  width: 85%;
}

.schedule-time div:last-child {
  margin-left: auto;
  border-right: 0 !important;
}

.time1 {
  width: 40%;
  border-right: 1px solid #ddd;
  text-align: center;
}

.order-scroll {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.track-order {
  background-color: orangered !important;
  color: white !important;
  width: 108%;
}
.Re-orderButton {
  background-color: orangered !important;
  color: white !important;
  width: 66%;
}
.order-scroll::-webkit-scrollbar-thumb {
  background-color: orangered !important;
  /* color of the scroll thumb */
  border-radius: 20px !important;
  /* roundness of the scroll thumb */
  border: 3px solid orangered !important;
  /* creates padding around scroll thumb */
}

.order-scroll::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

.order-scroll::-webkit-scrollbar-track {
  background: none;
  /* color of the tracking area */
}

.Details,
.address-text {
  /* margin: 6px 16%; */
  /* width: 102% !important;   */
  /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  width: 97% !important;
  margin-left: 2%;
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .track-order {
    margin-left: 11%;
    margin-top: 12%;
  }
  .ButtonMyOrderReview {
    margin-top: 3%;
  }
  .Details {
    margin: 6px 6%;
    font-size: 80% !important;
  }
  .CancelDineInItemProfile {
    width: 8vw;
    font-size: 1.5vw;
  }
  .ChangeDineInItemProfile {
    width: 8vw;
    font-size: 1.4vw;
  }

  .shedule-content {
    margin-left: 1%;
    font-size: 72%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .Details {
    margin: 6px 5%;
  }
}
@media screen and (min-width: 320px) and (max-width: 376px) {
  .orderDetailProfile {
    width: 10%;
  }
}

@media screen and (max-width: 576px) {
  .bal-content div p,
  .bal-content div b,
  .total-bal b {
    font-size: 79% !important;
  }

  .Deleteicon,
  .EditIcon {
    font-size: 5.9vw !important;
    padding: 5% !important;
  }
  .rest-name {
    font-size: 18px;
    /* margin-left: 4%; */
    text-align: center;
  }
  .addr-name {
    font-size: 1.9vw;
    font-weight: 500;
    /* margin-left: 7%; */
    text-align: center;
  }
  .order-img img {
    width: 160%;
    height: auto;
  }
  .Details,
  .Details1,
  .Details2,
  .Details3,
  .Details4,
  .Details5,
  .Details6 {
    font-weight: 600;
    font-size: 11px !important;
  }
  .schedule-time {
    display: flex;
    width: 85%;
    flex-wrap: wrap;
  }
  .order-container {
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: 2% auto;
    flex-wrap: wrap;
    justify-content: center;
  }
  .Details,
  .address-text {
    margin: 5px 90px;
    width: 185px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .shedule-content div {
    width: auto;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
  .time1 {
    width: 50%;
    border-right: 0px solid #ddd;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  .track-order {
    background-color: orangered !important;
    color: white !important;
    margin-top: 15px;
    width: 93%;
    font-size: 73%;
  }
  .placement {
    width: 100% !important;
  }
  .order-title {
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 450px) {
  .addr-name {
    font-size: 2.9vw;
  }
}
@media screen and (min-width: 576px) and (max-width: 769px) {
  .rest-name {
    font-size: 18px;
    /* margin-left: 90px; */
    text-align: -webkit-center;
  }
  .addr-name {
    font-size: 1.6vw;
    font-weight: 500;
    /* margin-left: 82px; */
    text-align: center;
  }
  .order-img img {
    width: 160%;
    height: auto;
  }
  .Details,
  .Details1,
  .Details2,
  .Details3,
  .Details4,
  .Details5,
  .Details6 {
    font-weight: 600;
    font-size: 11px !important;
  }
  .schedule-time {
    display: flex;
    width: 85%;
    flex-wrap: wrap;
  }
  .order-container {
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: 2% auto;
    flex-wrap: wrap;
    justify-content: center;
  }
  .Details,
  .address-text {
    margin: 5px 90px;
    width: 185px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .shedule-content div {
    width: auto;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  .time1 {
    width: 50%;
    border-right: 1px solid #ddd;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  .track-order {
    background-color: orangered !important;
    color: white !important;
    margin-top: 15px;
  }
  .placement {
    width: 100% !important;
  }
  .order-title {
    width: 80%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1350px) {
  .myOrdersWidth {
    margin-left: 4%;
    width: 83% !important;
    font-size: 0.9em;
  }
}
@media screen and (min-width: 800px) and (max-width: 2050px) {
  .shedule-content div {
    border-right: 1px solid #ddd;
  }
}

@media (max-width: 600px) {
  .order-img {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
  }

  .order-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 600px) {
  .schedule-time .time1 .EstimatedTimeProfile {
    width: 100% !important;
    margin: 0 !important;
  }
}
