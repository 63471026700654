.Restaurent_SubTitle {
  font-size: 75%;
}
/* .openHoursPositionTrend {
  position: absolute;
  top: 20%;
  right: 0;
} */
/* .breakHoursPositionTrend {
  position: absolute;
  top: 23%;
  right: 0;
} */
/* .deliveryHoursPositionTrend {
  position: absolute;
  top: 25%;
  right: 0;
} */
.css-177ic5c {
  opacity: 1;
  transform: none;
  transition: opacity 396ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 16px;
  left: 63%;
  transform-origin: 0px 614.062px;
  width: 17%;
}
.Restaurent_Title {
  font-weight: 600;
  font-size: medium;
}

.Restaurent_Open_Close {
  background-color: #79bc43 !important;
  border-radius: 5px;
  height: auto;
  width: auto;
  text-align: center;
  font-size: 15px;
}

.Restaurent_Close_home {
  background-color: red !important;
  border-radius: 5px;
  /* height: 3.5vh; */
  width: 4vw;
  text-align: center;
}

.css-1nr2wod.Mui-checked {
  color: #ff6838 !important;
}

.Restaurent_Rating_star {
  color: yellow;
  border: 1px black;
  width: 25px;
}

.favourite {
  color: orangered;
}

/* .Favourite_Badge_HomePage {
    width: 2.5vw;
    height: 4vh;
    position: absolute;
    top: 6%;
    left: 81%;
    border-radius: 25px;
    background-color: white!important;
} */

.OpenBackTime {
  font-size: 0.85em;
  color: black !important;
  font-weight: 700;
}

.DeliveryHandledBy {
  position: absolute;
  top: 49%;
  left: 68%;
  width: 12vw;
  background: white !important;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: black;
  background-color: white !important;
  font-size: 65%;
  border: none;
  cursor: pointer;
  border-radius: 1px;
}

/* .minimum_value_trending {
  position: absolute;
  top: 8%;
  left: 27%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  background-color: orangered !important;
  font-size: 11px;
  border: none;
  cursor: pointer;
  border-radius: 1px;
} */

/* .DeliveryHandledByTrending {
  position: absolute;
  top: 42%;
  left: 68%;
  width: 12vw;
  background: white !important;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: black;
  background-color: white !important;
  font-size: 65%;
  border: none;
  cursor: pointer;
  border-radius: 1px;
} */

.TrendingCardHomepage {
  cursor: pointer;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}

.trending-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}

.navigation-left-arrow {
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}

.navigation-btn {
  display: inherit;
  gap: 16px;
  width: 12%;
}

.navigation-left-arrow {
  color: black !important;
  background-color: white !important;
  border: 0px solid orangered !important;
  border-radius: 25px;
  width: 45%;
  box-shadow: 4px 4px 6px 6px #ddd !important;
}

.navigation-right-arrow {
  color: white !important;
  background-color: orangered !important;
  border: 0px solid white !important;
  border-radius: 25px;
  width: 47%;
  box-shadow: 2px 3px 6px 4px #ddd !important;
}

/* .openBackTimeBadge {
  position: absolute;
  top: unset;
  color: white;
  background-color: whitesmoke !important;
  bottom: 0;
  padding: 23px 10px;
  left: 0;
  width: 100%;
  border-radius: 0 0 35px 35px;
} */

.trending-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 2% 0;
}

.trending-card {
  max-width: 20%;
  min-width: 20%;
}

.minimum-order-badge {
  position: absolute;
  bottom: 40%;
  right: 0;
  background-color: orangered !important;
}

.delivery-order-badge {
  position: absolute;
  bottom: 24%;
  right: 0;
  background-color: white !important;
  color: black !important;
}

.wishlist-badge {
  position: absolute;
  right: 8%;
  top: 8%;
  background-color: white !important;
  color: black !important;
  border-radius: 50%;
}

.trending-img-cont {
  position: relative;
  min-height: 85%;
}

@media (max-width: 576px) {
  .TrendingCard_Homepage {
    width: 80vw;
    min-height: 280px;
    margin-right: 10px !important;
  }
  /* .minimum_value_trending {
    position: absolute;
    width: 45vw;
    height: 6vw;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 3.3vw;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -18px;
  } */
  .DeliveryHandledBy {
    position: absolute;
    top: dashed;
    width: 53vw;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 3.3vw;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -40px !important;
  }
  .Restaurent_Open_Close {
    background-color: #79bc43 !important;
    border-radius: 5px;
    height: auto;
    width: auto;
    font-size: 15px;
    margin-left: 7px;
  }
  .restaurantCloseMobile {
    margin-left: -28% !important;
  }
  .Restaurent_Close_home {
    background-color: red !important;
    border-radius: 5px;
    height: auto;
    width: auto;
    text-align: center;
    margin-left: 10px;
  }
  .Restaurent_Rating_star {
    font-size: 5vw;
  }
  .rating_count_homepage {
    font-size: 14px;
    width: 100%;
    display: block;
    margin-top: 9px;
  }
  .trendingImage {
    width: 100%;
    height: 150px;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
  }
  /* .open_hours_badge,
  .break_hours_badge {
    width: 150px !important;
    font-size: 9px !important;
    bottom: 599%;
    text-align: left;
    left: 46.2%;
  } */
  /* 
  .delivery_time {
    width: 27vw !important;
    top: 47%;
    top: 47%;
    left: 74%;
    padding-bottom: 11px;
  } */
  .favourite {
    color: orangered;
  }
  /* .Favourite_Badge_HomePage {
        width: 40px;
        height: 40px;
        position: absolute;
        margin-top: -23px;
        top: 10%;
        left: 81%;
        border-radius: 25px;
        background-color: white!important;
    } */
  .trendingCarousel .carousel-indicators {
    position: absolute;
    bottom: 9%;
    left: -12%;
    /* left: 0; */
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
  }
  .viewMore_AllRestaurant {
    font-size: 16px;
    width: 10px !important;
  }
  .navigation-btn {
    display: inherit;
    width: 25%;
    margin-right: 6px;
  }
  .navigation-btn {
    /* display: inherit; */
    gap: 7px;
    /* width: 12%; */
  }
  .Restaurent_Rating_star {
    color: yellow;
    border: 1px black;
    width: 18px;
    margin-top: -2px;
    margin-left: -16px !important;
  }
  .trending-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
    margin-top: 25px;
  }
}

@media screen and (min-width: 477px) and (max-width: 575px) {
  .starSearchPayment {
    margin-left: 5px !important;
  }
}

@media (min-width: 576px) and (max-width: 769px) {
  .TrendingCard_Homepage {
    width: 101vw;
    height: 77vw;
  }
  .restaurant-card {
    width: 50%;
  }
  /* .minimum_value_trending {
    position: absolute;
    width: 127px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 10px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 6px;
    margin-top: -16px;
  } */
  /* .DeliveryHandledByTrending {
    position: absolute;
    top: dashed;
    width: 175px;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 10px;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: -9px;
    margin-top: -27px !important;
  } */
  .DeliveryHandledBy {
    position: absolute;
    top: 36%;
    left: 63%;
    width: 32vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 70%;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  }
  .Restaurent_Open_Close {
    background-color: #79bc43 !important;
    border-radius: 5px;
    height: auto;
    width: auto;
    font-size: 15px;
    margin-left: 7px;
  }
  .Restaurent_Close_home {
    background-color: red !important;
    border-radius: 5px;
    height: auto;
    width: auto;
    text-align: center;
    margin-left: 10px;
  }
  .restCloseSearch {
    margin-left: -37% !important;
  }
  .Restaurent_Rating_star {
    font-size: 5vw;
  }
  .rating_count_homepage {
    font-size: 5vw;
  }
  .trendingImage {
    width: 100%;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    max-width: auto !important;
    height: 150px;
  }
  /* .open_hours_badge {
    width: 20vw !important;

    height: 4vw!important;
    font-size: 2.3vw !important;
    bottom: 599%;
    text-align: left;
    left: 46.2%;
    font-size: 9px !important;
  } */
  /* .break_hours_badge {
    width: 20.3vw !important;
    height: 4vw!important;
    font-size: 2.3vw !important;
    bottom: 50%;
    left: 48.1%;
    text-align: left;
    font-size: 9px !important;
  } */
  /* .delivery_time {
    width: 14vw !important;
    top: 47%;
    left: 74%;
    padding-bottom: 11px;
    margin-left: 10px !important;
    margin-top: 10px;
    font-size: 8px !important;
  } */
  .favourite {
    color: orangered;
  }
  /* .Favourite_Badge_HomePage {
        width: 40px;
        height: 40px;
        position: absolute;
        margin-top: -23px;
        top: 10%;
        left: 86%;
        border-radius: 25px;
        background-color: white!important;
    } */
  .trendingCarousel .carousel-indicators {
    position: absolute;
    bottom: 9%;
    left: -12%;
    /* left: 0; */
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
  }
  .viewMore_AllRestaurant {
    font-size: 20px;
    width: 30px !important;
  }
  .navigation-btn {
    display: inherit;
    width: 24%;
  }
  .Restaurent_Rating_star {
    color: yellow;
    border: 1px black;
    width: 18px;
  }
  .Restaurent_Title {
    font-weight: 600;
    font-size: 77%;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .TrendingCard_Homepage {
    width: 101vw;
    height: 77vw;
  }
  .restaurant-card {
    width: 50%;
  }
  /* .minimum_value_trending {
    position: absolute;
    width: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 10px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 24px;
    margin-top: -8%;
  } */
  /* .DeliveryHandledByTrending {
    position: absolute;
    top: dashed;
    width: 175px;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 10px;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 18px;
    margin-top: -34px !important;
  } */
  .DeliveryHandledBy {
    position: absolute;
    top: 36%;
    left: 63%;
    width: 32vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 70%;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  }
  .Restaurent_Open_Close {
    background-color: #79bc43 !important;
    border-radius: 5px;
    height: auto;
    width: auto;
    font-size: medium;
    margin-left: 7px;
  }
  .Restaurent_Close_home {
    background-color: red !important;
    border-radius: 5px;
    height: auto;
    width: auto;
    text-align: center;
    margin-left: 10px;
  }
  .Restaurent_Rating_star {
    font-size: 5vw;
  }
  .rating_count_homepage {
    font-size: 5vw;
  }
  .trendingImage {
    width: 100%;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    max-width: auto !important;
    height: 150px;
  }
  /* .open_hours_badge {
    width: 23vw !important;
    height: 4vw!important;
    font-size: 2.3vw !important;
    bottom: 599%;
    text-align: left;
    left: 46.2%;
    font-size: 10px !important;
  } */
  /* .break_hours_badge {
    width: 23.1vw !important;
    height: 4vw!important;
    font-size: 2.3vw !important;
    bottom: 50%;
    left: 48.1%;
    text-align: left;
    font-size: 10px !important;
  } */
  /* .delivery_time {
    width: 15vw !important;
    top: 47%;
    left: 74%;
    padding-bottom: 11px;
    margin-left: 10px !important;
    margin-top: 10px;
    font-size: 8px !important;
  } */
  .favourite {
    color: orangered;
  }
  /* .Favourite_Badge_HomePage {
        width: 40px;
        height: 40px;
        position: absolute;
        margin-top: -23px;
        top: 10%;
        left: 88%;
        border-radius: 25px;
        background-color: white!important;
    } */
  .trendingCarousel .carousel-indicators {
    position: absolute;
    bottom: 9%;
    left: -12%;
    /* left: 0; */
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
  }
  .viewMore_AllRestaurant {
    font-size: 20px;
    width: 30px !important;
  }
  .navigation-btn {
    display: inherit;
    width: 24%;
  }
  .Restaurent_Rating_star {
    color: yellow;
    border: 1px black;
    width: 20px;
  }
  .Restaurent_Title {
    font-weight: 600;
    font-size: 93%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .TrendingCard_Homepage {
    width: 101vw;
    height: 77vw;
  }
  .restaurant-card {
    width: 50%;
  }
  /* .minimum_value_trending {
    position: absolute;
    width: 159px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 12px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 45px;
    margin-top: -12px;
  } */
  /* .DeliveryHandledByTrending {
    position: absolute;
    top: dashed;
    width: 196px;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 12px;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 44px;
    margin-top: -9px !important;
  } */
  .DeliveryHandledBy {
    position: absolute;
    top: 36%;
    left: 63%;
    width: 32vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 70%;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  }
  .Restaurent_Open_Close {
    background-color: #79bc43 !important;
    border-radius: 5px;
    height: auto;
    width: 61px;
    font-size: 16px;
    margin-left: 69px;
  }
  .Restaurent_Close_home {
    background-color: red !important;
    border-radius: 5px;
    height: auto;
    width: auto;
    text-align: center;
    margin-left: 74px;
    font-size: 14px;
  }
  .Restaurent_Rating_star {
    font-size: 5vw;
  }
  .rating_count_homepage {
    font-size: 5vw;
  }
  .trendingImage {
    width: 100%;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    max-width: auto !important;
    height: 150px;
  }
  /* .open_hours_badge {
    width: 23vw !important;
    height: 4vw!important;
    font-size: 2.3vw !important;
    bottom: 599%;
    text-align: left;
    left: 46.2%;
    font-size: 10px !important;
  } */
  /* .break_hours_badge {
    width: 23.1vw !important;
    height: 4vw!important;
    font-size: 2.3vw !important;
    bottom: 50%;
    left: 48.1%;
    text-align: left;
    font-size: 10px !important;
  } */
  /* .delivery_time {
    width: 15vw !important;
    top: 47%;
    left: 74%;
    padding-bottom: 11px;
    margin-left: 10px !important;
    margin-top: 10px;
    font-size: 8px !important;
  } */
  .favourite {
    color: orangered;
  }
  /* .Favourite_Badge_HomePage {
        width: 40px;
        height: 40px;
        position: absolute;
        margin-top: -23px;
        top: 10%;
        left: 88%;
        border-radius: 25px;
        background-color: white!important;
    } */
  .trendingCarousel .carousel-indicators {
    position: absolute;
    bottom: 9%;
    left: -12%;
    /* left: 0; */
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
  }
  .viewMore_AllRestaurant {
    font-size: 800%;
  }
  .navigation-btn {
    display: inherit;
    width: 24%;
  }
  .Restaurent_Rating_star {
    color: yellow;
    border: 1px black;
    width: 20px;
  }
  .Restaurent_Title {
    font-weight: 600;
    font-size: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 376px) {
  .css-bvz20t {
    width: 97% !important;
  }
  .TrendingCard_Homepage {
    width: 92vw;
    height: 100vw;
  }

  .carousel .carousel-indicators button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #ff6838 !important;
    margin-bottom: -18px;
  }
  /* .minimum_value_trending {
    position: relative;
    top: -12%;
    margin-left: -9%;
  } */
  .trendingImage {
    width: 100%;
  }
  /* .DeliveryHandledByTrending {
    position: relative;
    top: -9%;
    margin-left: -11%;
  } */
  .Restaurent_Title {
    color: black !important;
  }
  .itemRatingRestHome {
    font-size: 0.7em !important;
  }
  .Restaurent_Open_Close {
    width: 3.3em;
    height: 1.6em;
    text-align: center;
  }
  .Restaurent_Close_home {
    width: 4em;
    margin-left: -46%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .TrendingCard_Homepage {
    width: 101vw;
    height: 77vw;
  }
  /* .Favourite_Badge_HomePage {
        width: 35px;
        height: 35px;
        position: absolute;
        top: 6%;
        left: 81%;
        border-radius: 25px;
        background-color: white!important;
    } */
  .favourite {
    color: orangered;
  }
  /* .minimum_value_trending {
    position: absolute;
    top: 30%;
    left: 66%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 11px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  } */
  /* .DeliveryHandledByTrending {
    position: absolute;
    top: 37%;
    left: 64%;
    width: 180px;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 65%;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  } */
  .Restaurent_Close_home {
    background-color: red !important;
    border-radius: 5px;
    height: auto;
    width: 50px;
    text-align: center;
  }
  .Restaurent_Open_Close {
    background-color: #79bc43 !important;
    border-radius: 5px;
    height: auto;
    width: 50px;
    text-align: center;
  }
  .trendingImage {
    width: 100%;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    max-width: auto !important;
    height: 150px;
  }
}

.RatingCountItemsRestaurant {
  font-weight: 700;
}

@media screen and (min-width: 516px) and (max-width: 769px) {
}

@media screen and (min-width: 576px) and (max-width: 700px) {
  .restOpenTrending {
    margin-left: 0% !important;
  }
  .restCloseTrending {
    margin-left: 0% !important;
  }
  /* .openHoursTrending {
    width: 17vw !important;
    font-size: 1.35vw !important;
  }
  .deliveryTrending {
    top: -9% !important;
    margin-left: -13px !important;
  }
  .openHoursTrending {
    height: 2.7vw !important;
    font-size: 1.2vw !important;
    width: 20vw !important;
  } */
}

/* @media screen and (min-width: 701px) and (max-width: 769px) {
  .openHoursTrending {
    height: 3vw !important;
    font-size: 1.2vw !important;
    width: 17vw !important;
  }
} */

@media screen and (min-width: 501px) and (max-width: 575px) {
  .RatingStarTrending {
    margin-top: 1px !important;
    margin-left: 8px !important;
  }
}

/* @media screen and (min-width: 701px) and (max-width: 766px) {
  .deliveryTrendRest {
    top: -10% !important;
    left: -31% !important;
    font-size: 50% !important;
    width: 147% !important;
  }
  .openHoursTrending {
    font-size: 1.05vw !important;
    width: 17.4vw !important;
    height: 16px !important;
  }
} */

.trendingCarousel.carousel-control-next {
  right: -30px;
}

.trendingCarousel.carousel-control-previous {
  right: -30px;
}

.mobileViewScrollTrending {
  display: flex;
  flex-direction: row;
  overflow: auto;
}
