/* Header */

.header-container {
  width: 100%;
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: white;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}

.LogOutButtonText {
  text-align: center;
  margin-top: 4%;
  margin-bottom: 4%;
  font-size: 1em !important;
  font-weight: 500;
}

.LogoutButtonProfile {
  background-color: #ff6838;
  width: 11%;
  height: 53px;
  border-radius: 30px;
  margin-top: 1.5%;
  font-weight: 500;
  background-color: white;
}

.image-container {
  width: 55%;
  padding: 1%;
}

.adress-container {
  width: 33%;
  display: flex;
  padding: 1%;
  margin-left: -29%;
}

.adress-content {
  display: inherit;
  align-items: center;
  white-space: nowrap;
}

.data-content {
  display: flex;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 60px;
  box-shadow: 1px 1px 1px 1px rgb(173 172 172);
}

.adress-specific {
  color: #ff6838 !important;
}

.address_view_data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #000000;
  border: none !important;
  width: 100%;
  font-size: small;
  font-weight: 500;
  cursor: text;
}

.menu-container {
  width: 25%;
  padding: 1%;
}

.user-container {
  width: 20%;
  display: flex;
  align-items: center;
  padding: 1% 0;
  box-shadow: none;
}

.sign {
  min-width: 50%;
  max-width: 65%;
  font-size: large;
  border-radius: 25px;
  font-weight: 500;
  height: 71%;
  overflow: hidden;
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: white !important;
  color: black;
  box-shadow: 4px 4px 6px 6px #ddd !important;
}

.sign small {
  margin: 0 8%;
}

/* Toggle */

.toggle-container {
  width: 100%;
  position: relative;
}

.button-container {
  position: absolute;
  left: 24%;
}

.menu {
  position: absolute;
  top: 7%;
  right: 0%;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background: white;
  color: black;
  box-shadow: 4px 4px 6px 6px #ddd !important;
}

.menuButton {
  border-radius: 50%;
  font-size: 1.5vw;
  box-shadow: none;
  background-color: white !important;
  color: black !important;
  box-shadow: 4px 4px 6px 6px #ddd;
  width: 50px;
  margin-top: 0%;
  margin-left: 4%;
  --bs-btn-hover-border-color: black;
}

.button-toggle {
  display: flex;
  margin-left: 55px;
}

.mobileUserNameHeader {
  width: 10%;
  font-size: large;
  border-radius: 25px;
  font-weight: 500;
  height: 71%;
  background-color: white !important;
  color: black;
  box-shadow: 4px 4px 6px 6px #ddd !important;
}

.collapse-horizontal {
  transition: 1s ease-in;
}

.header-container .menu-container .menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

@media screen and (min-width: 1600px) and (max-width: 1900px) {
  .button-toggle {
    display: flex;
    flex-wrap: nowrap;
    margin-left: -25px !important;
    margin-top: 13px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .button-toggle {
    display: flex;
    flex-wrap: nowrap;
    margin-left: -25px !important;
    margin-top: 13px;
  }
  .sign small {
    margin: 0 4%;
    font-size: 13px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .button-toggle {
    display: flex;
    flex-wrap: nowrap;
    margin-left: -42px !important;
    margin-top: 16px;
  }
  .sign small {
    margin: 0 4%;
    font-size: 13px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .sign {
    min-width: 60%;
    max-width: 60%;
    font-size: large;
    border-radius: 25px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 30px;
    box-shadow: 4px 4px 6px 6px #ddd !important;
  }
  .image-container {
    width: 25%;
    padding: 1%;
  }
  .adress-container {
    width: 30%;
    display: flex;
    padding: 1%;
    margin-left: 0%;
  }
  .adress-content {
    display: inherit;
    align-items: center;
    white-space: nowrap;
  }
  .data-content {
    display: flex;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 60px;
    box-shadow: 1px 1px 1px 1px rgb(173 172 172);
  }
  .adress-specific {
    color: #ff6838 !important;
  }
  .address_view_data {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #000000;
    border: none !important;
    width: 100%;
    font-size: small;
    font-weight: 500;
  }
  .menu-container {
    width: 25%;
    padding: 1%;
  }
  .user-container {
    width: 20%;
    display: flex;
    align-items: center;
    padding: 1% 0;
    box-shadow: none;
  }
  .menu {
    position: absolute;
    top: 7%;
    right: 0%;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background: white;
    color: black;
    box-shadow: 4px 4px 6px 6px #ddd !important;
  }
  .button-toggle {
    display: flex;
    flex-wrap: nowrap;
    margin-left: -55px !important;
    margin-top: 16px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .tab-container {
    width: 100% !important;
  }

  .open_Hours,
  .Rating_out_of,
  .kilometer_verticalLine,
  .Delivery_Time,
  .delivery_Time,
  .Rating_Count {
    font-size: 0.775rem !important;
  }
  .foodTitleProductList {
    font-weight: 700;
    font-size: 75% !important;
  }
  .address-size {
    font-weight: 600;
    width: 147%;
    font-size: 86%;
  }
  .in-de-btn {
    width: 244% !important;
  }
  .count {
    margin-left: 4%;
    margin-right: 4%;
  }
  .res-cart-container {
    margin-top: 6.4% !important;
    top: 4% !important;
  }

  .add-btn button {
    width: 100% !important;
  }
  .itemDescriptionRestaurant {
    font-size: 69% !important;
  }
  .css-1q2h7u5 {
    font-size: 0.75rem !important;
  }
  .sign {
    min-width: 55%;
    max-width: 71%;
    font-size: 95%;
    border-radius: 25px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow: 4px 4px 6px 6px #ddd !important;
    margin-left: 45%;
    height: 40px;
  }
  .badge123 {
    top: 32%;
    right: 34%;
  }
  .image-container {
    width: 30%;
    padding: 1%;
  }
  .adress-container {
    width: 43%;
    display: flex;
    padding: 3%;
    margin-left: 5px;
  }
  .adress-content {
    display: inherit;
    align-items: center;
    white-space: nowrap;
  }
  .data-content {
    display: flex;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 60px;
    box-shadow: 1px 1px 1px 1px rgb(173 172 172);
  }
  .adress-specific {
    color: #ff6838 !important;
  }
  .address_view_data {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #000000;
    border: none !important;
    width: 100%;
    font-size: small;
    font-weight: 500;
  }
  .menu-container {
    width: 24%;
    padding: 1%;
    display: flex;
    align-items: baseline;
  }
  .user-container {
    width: 54%;
    display: flex;
    align-items: center;
    padding: 1% 0;
    box-shadow: none;
    margin-right: 20px;
  }
  .menu {
    position: relative;
    top: 7%;
    right: 26%;
    left: unset;
    border-radius: 62%;
    height: 55px;
    width: 55px;
    background: white;
    color: black;
    box-shadow: -1px 4px 6px 6px #ddd !important;
    margin-right: -61px;
    margin-top: 12px;
  }
  .button-toggle {
    display: flex;
    flex-wrap: nowrap;
    margin-left: -38% !important;
    margin-top: 4%;
    width: 135%;
  }

  .menuButton {
    border-radius: 50%;
    font-size: 15px;
    box-shadow: none;
    background-color: white !important;
    color: black !important;
    box-shadow: 4px 2px 6px 3px #afafaf;
    width: 22% !important;
    margin-top: 8%;
    /* margin-left: 133%; */
    height: 40px;
    max-width: 70px;
  }
  .LocationIconHeader {
    font-size: 2.3vw;
  }
  .LogoutButtonProfile {
    width: 18%;
    height: 49px;
    border-radius: 30px;
    margin-top: 3%;
    font-weight: 500;
    background-color: white;
    margin-left: 6%;
  }
}

@media screen and (min-width: 799px) and (max-width: 1200px) {
  .toggleSpaceHome {
    margin-left: 31%;
  }
}

@media screen and (min-width: 567px) and (max-width: 769px) {
  .sign {
    min-width: 56%;
    max-width: 50%;
    font-size: 12px;
    border-radius: 25px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow: 4px 4px 6px 6px #ddd !important;
    margin-left: 70px;
  }
  .image-container {
    width: 40%;
    padding: 1%;
  }
  .menu-container {
    width: 45%;
    padding: 1%;
  }
  .user-container {
    width: 75%;
    display: flex;
    align-items: center;
    padding: 1% 0;
    box-shadow: none;
  }
  .menu {
    position: relative;
    top: 620%;
    margin-left: -30%;
    right: 246px !important;
    margin-top: 3%;
    padding: 10%;
    border-radius: 30px;
    height: 40px;
    width: 40px;
    background: white;
    color: black;
    box-shadow: -1px 4px 6px 6px #ddd !important;
    margin-top: 0%;
  }
  .adress-container {
    width: 95%;
    display: flex;
    padding: 2%;
    margin-left: -10px;
    height: 67px;
    margin-top: 1%;
  }
  .adress-content {
    display: inherit;
    align-items: center;
    white-space: nowrap;
  }
  .data-content {
    display: flex;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 60px;
    box-shadow: 1px 1px 1px 1px rgb(173 172 172);
  }
  .adress-specific {
    color: #ff6838 !important;
  }
  .address_view_data {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #000000;
    border: none !important;
    width: 141%;
    font-size: small;
    font-weight: 500;
    margin-left: 37px;
  }
  .mobileUserNameHeader {
    width: 25%;
    font-size: 21px;
    border-radius: 25px;
    font-weight: 500;
    height: 65%;
    align-items: center;
    display: flex;
    margin-left: 55%;
    margin-top: -4%;
  }
  .button-container {
    position: absolute;
    left: 185%;
    margin-top: 7%;
  }
  .LogoutButtonProfile {
    width: 13%;
    height: 43px;
    border-radius: 30px;
    margin-top: 2%;
    font-weight: 500;
    background-color: #ff6838;
    margin-left: 20%;
  }
}

@media screen and (min-width: 567px) and (max-width: 700px) {
  .button-toggle {
    display: flex;
    flex-wrap: nowrap;
    margin-left: -182px !important;
    margin-top: -116px;
  }
  .menuButton {
    background-color: #fff !important;
    border-radius: 52%;
    box-shadow: none;
    box-shadow: 4px 2px 6px 3px #afafaf;
    color: #000 !important;
    font-size: 13px;
    height: 37px;
    margin-left: 3%;
    margin-top: 95%;
    width: 36px;
  }
}

@media screen and (min-width: 701px) and (max-width: 769px) {
  .button-toggle {
    display: flex;
    flex-wrap: nowrap;
    margin-left: -152% !important;
    margin-top: -236%;
  }
  .menuButton {
    border-radius: 52%;
    font-size: 16px;
    box-shadow: none;
    background-color: white !important;
    color: black !important;
    box-shadow: 4px 2px 6px 3px #afafaf;
    width: 35px;
    margin-top: 95%;
    margin-left: 4%;
    height: 35px;
  }
}

@media screen and (min-width: 451px) and (max-width: 576px) {
  .sign {
    min-width: 84%;
    max-width: 70%;
    font-size: 12px;
    border-radius: 25px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow: 4px 4px 6px 6px #ddd !important;
    margin-left: 8px;
  }
  .image-container {
    width: 40%;
    padding: 1%;
  }
  .menu-container {
    width: 45%;
    padding: 1%;
  }
  .user-container {
    width: 75%;
    display: flex;
    align-items: center;
    padding: 1% 0;
    box-shadow: none;
  }
  .menu {
    position: relative;
    display: flex;
    flex-direction: row;
    top: 620%;
    margin-left: -30%;
    right: -64%;
    margin-top: 3%;
    padding: 10%;
    border-radius: 30px;
    height: 45px;
    width: 45px;
    background: white;
    color: black;
    box-shadow: -1px 4px 6px 6px #ddd !important;
  }
  .menuButton {
    border-radius: 52%;
    font-size: 3.5vw;
    box-shadow: none;
    background-color: white !important;
    color: black !important;
    box-shadow: 4px 2px 6px 3px #afafaf;
    width: 59px;
    margin-top: 15%;
    margin-left: 11%;
    height: 40px;
  }
  .button-toggle {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 30px !important;
    margin-left: -221px !important;
    margin-top: -29px;
  }
  .adress-container {
    width: 57%;
    display: flex;
    padding: 1%;
    margin-left: 0%;
  }
  .adress-content {
    display: inherit;
    align-items: center;
    white-space: nowrap;
  }
  .data-content {
    display: flex;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 60px;
    box-shadow: 1px 1px 1px 1px rgb(173 172 172);
  }
  .adress-specific {
    color: #ff6838 !important;
  }
  .address_view_data {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #000000;
    border: none !important;
    width: 100%;
    font-size: small;
    font-weight: 500;
    margin-left: 23px;
  }
  .mobileUserNameHeader {
    width: 37%;
    /* font-size: 18px; */
    border-radius: 25px;
    font-weight: 500;
    height: 92%;
    background-color: white !important;
    color: black;
    box-shadow: 4px 4px 6px 6px #ddd !important;
    margin-right: 3px;
    align-items: center;
    display: flex;
    margin-left: 70px;
  }
  .LogoutButtonProfile {
    width: 9%;
    height: 42px;
    border-radius: 30px;
    margin-top: 2%;
    font-weight: 500;
    background-color: #ff6838;
    margin-left: 16%;
  }
}

@media screen and (min-width: 376px) and (max-width: 450px) {
  .sign {
    min-width: 84% !important;
    max-width: 70%;
    font-size: 12px;
    border-radius: 25px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-shadow: 4px 4px 6px 6px #ddd !important;
    margin-left: 8px;
  }
  .image-container {
    width: 80%;
    padding: 1%;
  }
  .menu-container {
    width: 45%;
    padding: 1%;
  }
  .user-container {
    width: 17%;
    display: flex;
    align-items: center;
    padding: 1% 0;
    box-shadow: none;
    height: 65px;
  }
  .menu {
    position: relative;
    display: flex;
    flex-direction: row;
    top: 620%;
    margin-left: 0%;
    right: 30%;
    margin-top: 3%;
    padding: 10%;
    border-radius: 30px;
    height: 45px;
    width: 45px;
    background: white;
    color: black;
    box-shadow: -1px 4px 6px 6px #ddd !important;
  }
  .menuButton {
    border-radius: 52%;
    font-size: 15px;
    box-shadow: none;
    background-color: white !important;
    color: black !important;
    box-shadow: 4px 2px 6px 3px #afafaf;
    width: 50px;
    margin-top: 12%;
    margin-left: 8%;
    height: 39px;
    width: 25%;
  }
  .button-toggle {
    display: flex;
    flex-wrap: nowrap;
    width: 330%;
    margin-left: -170px;
    margin-top: 30px;
  }
  .adress-container {
    width: 58%;
    display: flex;
    padding: 1%;
    margin-left: 0%;
    height: 43px;
    margin-top: 2%;
  }
  .adress-content {
    display: inherit;
    align-items: center;
    white-space: nowrap;
  }
  .data-content {
    display: flex;
    border: 1px solid #ddd;
    padding: 7px;
    border-radius: 60px;
    box-shadow: 1px 1px 1px 1px rgb(173 172 172);
  }
  .adress-specific {
    color: #ff6838 !important;
  }
  .LocationIconHeader {
    color: #ff6838;
    font-size: 1em;
    margin-left: 9% !important;
  }
  .address_view_data {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #000000;
    border: none !important;
    width: 100%;
    font-size: 10px;
    font-weight: 500;
    margin-left: 2px;
    border-radius: 20px;
  }
  .mobileUserNameHeader {
    width: 78%;
    font-size: 19px;
    border-radius: 25px;
    font-weight: 500;
    height: 68%;
    background-color: white !important;
    color: black;
    box-shadow: 4px 4px 6px 6px #ddd !important;
    /* margin-right: 34px; */
    align-items: center;
    display: flex;
    margin-top: -16%;
  }
  .LogoutButtonProfile {
    width: 10%;
    height: 37px;
    border-radius: 30px;
    margin-top: 2.5%;
    font-weight: 500;
  }
}

@media (min-width: 320px) and (max-width: 376px) {
  .Re-orderButton,
  .WrirReviewButton {
    width: 70% !important;
  }
  .ButtonMyOrderReview {
    width: 83% !important;
  }
  .sign {
    width: 50%;
    height: 62%;
    margin-top: -5%;
    margin-left: 24%;
  }
  .userNameSignIn {
    font-size: 106%;
    text-align: center;
    padding-top: -2px;
    margin-top: -15%;
    position: absolute;
    top: 10%;
    left: 90%;
    padding: 1px 1px;
  }
  .image-container {
    width: 40%;
    padding: 1%;
  }
  .menu-container {
    width: 45%;
    padding: 1%;
  }
  .user-container {
    align-items: center;
    box-shadow: none;
    display: flex;
    height: 55px;
    /* padding: 0% 0px; */
    width: 15.5%;
    margin-top: 0%;
    /* margin-left: -1%; */
    position: relative;
    left: -3%;
  }
  .menu {
    position: relative;
    display: flex;
    flex-direction: row;
    top: 620%;
    margin-left: 0%;
    right: 25%;
    margin-top: 11%;
    padding: 10%;
    border-radius: 30px;
    height: 33px;
    width: 34px;
    background: white;
    color: black;
    box-shadow: -1px 4px 6px 6px #ddd !important;
  }
  /* .ThreeDotsIcon {
        width: 20px!important;
        height: 18px!important;
        margin-left: 2px;
        margin-top: 2px;
    } */
  .menuButton {
    border-radius: 33px;
    font-size: 15px;
    box-shadow: none;
    background-color: white !important;
    color: black !important;
    box-shadow: 4px 2px 6px 3px #afafaf;
    width: 24%;
    margin-top: 13%;
    position: relative;
    /* left: 36%; */
    padding: 5px 5px;
    width: 30%;
    display: flex;
    flex-direction: row;
  }
  .button-toggle {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 30px !important;
    margin-left: -155px;
    margin-top: 30px;
    align-content: stretch;
  }
  .adress-container {
    width: 47%;
    display: flex;
    padding: 0%;
    margin-left: 0%;
    height: 33px;
    margin-top: 2%;
  }
  .adress-content {
    display: inherit;
    align-items: center;
    white-space: nowrap;
  }
  .data-content {
    display: flex;
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 60px;
    box-shadow: 1px 1px 1px 1px rgb(173 172 172);
  }
  .navigation-btn {
    display: inherit;
    height: 10%;
    width: 34%;
  }
  .adress-specific {
    color: #ff6838 !important;
  }
  .address_view_data {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #000000;
    border: none !important;
    width: 2%;
    font-size: 10px;
    font-weight: 500;
    margin-left: -3px;
    border-radius: 20px;
  }
  .button-container {
    margin-top: -1%;
  }
  .mobileUserNameHeader {
    width: 79%;
    /* font-size: 19px; */
    border-radius: 25px;
    font-weight: 500;
    height: 74%;
    background-color: white !important;
    color: black;
    box-shadow: 4px 4px 6px 6px #ddd !important;
    /* margin-right: 12px; */
    align-items: center;
    display: flex;
    /* padding: 0%; */
    margin-top: -19%;
    top: 3%;
  }
}

@media screen and (min-width: 320px) and (max-width: 769px) {
  .DeliveryTo {
    font-size: 0.75em;
  }
  .LocationIconHeader {
    color: #ff6838;
    font-size: 18px !important;
    margin-left: 18% !important;
    margin-top: -11% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 566px) {
  .toggleSpaceHome {
    margin-left: 43%;
  }
}

@media screen and (min-width: 320px) and (max-width: 376px) {
  .userIconMobile {
    font-size: 174%;
  }
}

@media screen and (min-width: 376px) and (max-width: 576px) {
  .userIconMobile {
    font-size: 74%;
  }
}

@media screen and (min-width: 576px) and (max-width: 700px) {
  .toggleSpaceHome {
    margin-left: 31%;
    margin-top: -1%;
  }
}

@media screen and (min-width: 701px) and (max-width: 799px) {
  .toggleSpaceHome {
    margin-left: 36%;
  }
  .mobileUserNameHeader {
    width: 24% !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .LoginSignUpForms .modal-body {
    margin-top: -11%;
  }
  .verify {
    margin-left: -4% !important;
  }
}

.adressTypeClass {
  color: red;
}

@media (min-width: 800px) {
  .LoginSignUpForms .modal-body .form-containers .btn-container .btn {
    font-size: 15px;
  }
}

@media (max-width: 800px) and (min-width: 700px) {
  .toggle-container .button-container {
    left: 150%;
  }
}

.menu.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1000px) and (min-width: 750px) {
  .header-container {
    min-height: 100px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .header-container {
    min-height: 75px;
  }
}

@media (max-width: 700) {
  .logOutButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
