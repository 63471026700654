.activeRestaurent {
    color: orangered;
    font-size: x-large;
    font-weight: 700;
    cursor: pointer;
}

.FoodsCatagory {
    font-size: x-large;
    font-weight: 700;
    cursor: pointer;
}

.RestaurantFoodsHome,
.HomeMadeFoodsHomePage {
    cursor: pointer;
}

@media (min-width: 576px) and (max-width:796px) {
    .restaurant {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        margin-left: -300px;
    }
}

@media (min-width: 797px) and (max-width:992px) {
    .restaurant {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: center;
        margin-left: -450px;
    }
}