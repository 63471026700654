.cart_cardbutton {
  border: 1px solid black;
  border-radius: 25px;
  color: orangered;
  width: 2vw;
  height: 2vw;
}
.cartpopup_cardPayment .card-body {
  margin-left: 3% !important;
}
.SpecialInstructions {
  width: 90%;
  border-top: 0px !important;
  border-radius: 1px !important;
  border-left: 0px !important;
  border-right: 0px !important;
}
.form-control:focus {
  outline: 0;
  border-color: white !important;
  box-shadow: black !important;
}
.SpecialInstructions:focus {
  border-color: black;
  /* default B5 color or set your own color*/
  border-top: none !important;
  /* if you want to remove borders at all*/
  border-left: none !important;
  /* if you want to remove borders at all*/
  border-right: none !important;
  /* if you want to remove borders at all*/
  border-radius: 0%;
  outline: none !important;
  box-shadow: none !important;
}
.cartpopup_card {
  top: 14%;
  position: fixed;
  height: 85vh;
  overflow-x: hidden;
  border-radius: 0px !important;
  box-shadow: 0 0 0 2px rgb(229 225 225 / 11%);
}

.menu_button1,
.menu_button2,
.menu_button3,
.menu_button4 {
  border: 1px solid black;
  color: black;
  background-color: white;
  border-radius: 25px;
}

.cart_cardbutton2 {
  border: 1px solid black;
  border-radius: 25px;
  color: orangered;
  width: 55%;
  height: 76%;
}

.promocode {
  width: 95%;
  height: 40px;
}

.Apply_button {
  width: 85%;
  height: 40px;
  background-color: orangered !important;
  color: white !important;
}

.cont-promo {
  width: 100%;
  /* margin: auto; */
  margin: 15px 0px 0px 15px;
  font-size: 14px;
}

.total-container {
  display: grid;
  grid-template-columns: 71% 32%;
  width: 92%;
  margin: auto;
}

.clear-all {
  cursor: pointer;
}

.checkout_button {
  width: 84%;
  background-color: orangered !important;
  color: white !important;
}

.cartbutton_headerpage {
  background-color: white;
  color: black;
  border-radius: 25px;
  border: 1px solid black;
}

.increament {
  width: 10%;
  height: 10%;
}

.dncreament {
  width: 10%;
  height: 10%;
}

.horizontalLine {
  border-top: 2px solid black !important;
}

.amount_details {
  font-weight: 500;
}

.popup_item_image {
  width: 3vw;
  height: 7vh;
}

.count_button {
  position: relative;
  /* width: 4.3vw;
    height: 3.3vh; */
  top: -8%;
  left: -7%;
  text-align: center;
  border-radius: 25px;
  width: 4.5vw;
  height: 2vw;
  font-size: 1.2vw;
  padding-top: 0px;
  padding-bottom: 23px;
}

.item_count_ButtonPopup {
  position: relative;
  width: 4vw;
  height: 4vh;
  top: -13%;
  left: -9%;
  text-align: center;
  border-radius: 25px;
}

.order-text {
  font-size: 16px;
  /* font-weight: bold; */
  text-align: center;
  cursor: pointer;
}

.order-text p {
  margin: 0;
}

.image-cart-div {
  width: 70%;
  margin-bottom: -5%;
}

.itemCartProductDivider {
  margin: 0px auto 10px auto;
}

.button-cart-div {
  width: 30%;
  position: relative;
}

.add-btn {
  /* position: absolute;
    right: 25%;
    bottom: 10%; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.add-btn button {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
}

.in-de-btn {
  width: 50%;
}

.geenColor {
  color: green;
}

.redColor {
  color: red;
}

.checkoutBtn-align {
  text-align: center;
}

.cart-scroll-container {
  max-height: 250px;
  font-size: 1vw !important;
  overflow-x: hidden;
  overflow-y: auto;
  /* margin-top: 2%; */
  margin: 2px 12px 0px 14px;
  padding: 10px;
}

.promo-container {
  display: flex;
}

.input-promo {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.btn-promo {
  width: 40%;
}

.error-msg {
  color: red;
}

.item-card-cart {
  display: flex;
  padding-bottom: 5px;
}

.ldgnCartItem {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;
  margin: 30px 0;
}

.ldgnCartItem img {
  filter: hue-rotate(92deg) saturate(8) brightness(1.2);
}

.ldgnCartItem p {
  font-weight: 500;
}

.discountPromoWrapper {
  background: rgb(255, 234, 227);
  padding: 7px 0;
  margin: 5px 0;
}

.discountPromoWrapper .total-container div {
  margin: 0;
  display: flex;
  align-items: center;
}

.count.chkOutCount {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .cartpopup_card {
    top: 0%;
    position: sticky;
    border-radius: 0px !important;
    box-shadow: 0 0 0 2px rgb(229 225 225 / 11%);
    margin-top: 40px;
  }
  .cart_cardbutton {
    border: 1px solid black;
    border-radius: 25px;
    color: orangered;
    width: 6vw !important;
    height: 6vw !important;
  }
  .order-text {
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
  }
  .cart-scroll-container {
    max-height: 250px;
    font-size: 4vw !important;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .checkout_button {
    width: 100%;
    margin-left: 2% !important;
    font-size: 14px !important;
  }
  .Apply_button {
    width: 85%;
    height: 40px;
    background-color: orangered !important;
    color: white !important;
    font-size: 17px !important;
  }
}

@media (min-width: 576px) and (max-width: 769px) {
  .cartpopup_card {
    top: 0%;
    position: sticky;
    border-radius: 0px !important;
    box-shadow: 0 0 0 2px rgb(229 225 225 / 11%);
    margin-top: 10%;
    /* margin-left: -250px; */
  }
  .cart_cardbutton {
    border: 1px solid black;
    border-radius: 25px;
    color: orangered;
    width: 6vw !important;
    height: 6vw !important;
  }
  .order-text {
    font-size: 2vw;
    font-weight: bold;
    text-align: center;
  }
  .cart-scroll-container {
    max-height: 250px;
    font-size: 2.5vw !important;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .checkout_button {
    width: 100%;
    background-color: orangered !important;
    color: white !important;
    font-size: 14px !important;
  }
  .Apply_button {
    width: 85%;
    height: 40px;
    background-color: orangered !important;
    color: white !important;
    font-size: 17px !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .cartpopup_card {
    /* height: 130vh; */
  }
  .SpecialInstructions {
    width: 104%;
    border-top: 0px !important;
    border-radius: 1px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    font-size: 68%;
  }
  .Favourite_Badge_ProductListing {
    height: 3.3% !important;
    right: 34% !important;
    top: 12% !important;
    width: 5% !important;
  }
  .ProductNameSearch,
  .PriceItemSearch {
    font-size: 66% !important;
  }
  .currently-unavailableSearch {
    font-size: 0.6em !important;
    height: 23px !important;
    width: 228% !important;
  }
  .deliveryBySearch {
    margin-left: -12% !important;
  }
  .RestNameWalletDetails {
    font-size: 92%;
  }
  .add-btn {
    width: 128%;
    margin-left: -31%;
  }
  .green-text,
  .walletDebitedProfile {
    font-size: 1.8vw !important;
  }
  .address-display {
    font-size: 83%;
  }
  .itemRatingResText {
    font-size: 93%;
  }
  .user-detail,
  .email-data,
  .profile-items p {
    font-size: 82%;
  }
  .EstimatedTimeProfile {
    font-size: 68% !important;
  }
  .myOrdersWidth {
    margin-left: 5% !important;
  }
  .profile-heading-titles {
    font-size: 87% !important;
  }
  .choosePaymentMethod {
    margin-left: 5%;
    margin-bottom: -3% !important;
  }
  .desiEats_Description {
    font-size: 1.5vw !important;
  }

  .promo-container {
    margin-left: -9%;
  }
  .amount_details,
  .GrandTotalProductList {
    font-size: 74%;
  }
  .cartpopup_card .card-body {
    margin-left: -3%;
  }

  .cartpopup_card {
    top: 0%;
    position: sticky;
    border-radius: 0px !important;
    box-shadow: 0 0 0 2px rgb(229 225 225 / 11%);
  }
  .cart_cardbutton {
    border: 1px solid black;
    border-radius: 25px;
    color: orangered;
    width: 6vw !important;
    height: 6vw !important;
  }
  .order-text {
    font-size: 70%;
    font-weight: bold;
    text-align: center;
  }
  .cart-scroll-container {
    max-height: 250px;
    font-size: 13px !important;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .checkout_button {
    width: 100%;
    font-size: 79% !important;
    margin-left: -1% !important;
    font-weight: 500;
  }
  .Apply_button {
    width: 85%;
    height: 40px;
    font-size: 100% !important;
  }
  .promocode {
    width: 94%;
    height: 34px;
    margin-top: 2%;
  }
  .error-msg {
    font-size: 83%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .cartpopup_card {
    top: 0%;
    position: sticky;
    border-radius: 0px !important;
    box-shadow: 0 0 0 2px rgb(229 225 225 / 11%);
    /* margin-left: -250px; */
  }
  .cart_cardbutton {
    border: 1px solid black;
    border-radius: 25px;
    color: orangered;
    width: 6vw !important;
    height: 6vw !important;
  }
  .order-text {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  .cart-scroll-container {
    max-height: 250px;
    font-size: 18px !important;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .checkout_button {
    width: 100%;
    background-color: orangered !important;
    color: white !important;
    font-size: 14px !important;
    margin-left: 3% !important;
  }
  .Apply_button {
    width: 85%;
    height: 40px;
    background-color: orangered !important;
    color: white !important;
    font-size: 17px !important;
  }
}

.cartpopup_card .formSelectContainer {
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 0 15px;
  width: 100% !important;
}

.cartpopup_card .formSelectContainer .EstimatedDate {
  width: 100%;
}

.cartpopup_card .formSelectContainer .MuiFormControl-root {
  width: 100%;
}

.cartpopup_card .formSelectContainer .selectBoxPaymentTwo.form-select {
  width: 100%;
  top: 0;
  margin-left: 0;
  height: 56px;
  left: 5px !important;
}

@media (max-width: 980px) {
  .cartpopup_card .formSelectContainer {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 15px;
    width: 100% !important;
    gap: 15px;
  }

  .cartpopup_card .formSelectContainer.chckOtCntnr {
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    padding: 0 15px;
    width: 100% !important;
    gap: 15px;
  }

  .cartpopup_card .formSelectContainer.chckOtCntnr .LoginAllTimeDate {
    margin-left: 1.5%;
  }

  .cartpopup_card .formSelectContainer .selectBoxPaymentTwo.form-select {
    left: 0 !important;
  }

  .cartpopup_card .formSelectContainer .EstimatedDate {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .SpecialInstructions {
    width: 96%;
    margin-left: 12px;
  }

  .res-container .Favourite_Badge_ProductListing {
    background-color: #fff !important;
    border-radius: 25px;
    cursor: pointer;
    font-size: 112%;
    height: 40px !important;
    position: absolute;
    right: 15px !important;
    top: 120px !important;
    width: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
