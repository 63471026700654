.tittle {
    font-weight: 700;
    padding: 3%;
}

.item-card {
    border-radius: 10px;
    min-height: 125px;
}

.no-notification-data {
    padding: 12%;
    margin-left: -22%;
    font-weight: 500;
    text-align: center;
}

.notification-tittle {
    font-weight: 600;
    font-size: 1em;
}

.itemImage {
    border-radius: 10px;
}

.modal-container .modal-content {
    min-height: 500px;
}

.notification-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1% 6%;
}

.notification-body {
    padding: 0% 15% 0% 1%;
    width: 180%;
}

.notification-card {
    padding: 3%;
}

.item-container {
    display: flex;
}

.content-container {
    width: 70%;
    margin: auto;
    display: inline;
}

.img-container {
    width: 30%;
}

.desc {
    margin-top: 5px;
}

.no-data {
    text-align: center;
}

@media (min-width: 320px) and (max-width:376px) {
    .notification-body {
        padding: 0% 15% 0% 1%;
        width: 126%;
    }
}

@media (min-width: 376px) and (max-width:450px) {
    .notification-body {
        padding: 0% 15% 0% 1%;
        width: 126%;
    }
}

@media (min-width: 450px) and (max-width:576px) {
    .notification-body {
        padding: 0% 15% 0% 1%;
        width: 126%;
    }
}

@media (min-width: 576px) and (max-width:780px) {
    .notification-body {
        padding: 0% 15% 0% 1%;
        width: 126%;
    }
}
@media (min-width: 768px) and (max-width:992px) {
    .no-notification-data{
padding: 28%!important;
margin-left: -6%!important;
    }
    .no-data {
        padding: 7%!important;
    }
}
@media (min-width: 780px) and (max-width:992px) {
    .notification-body {
        padding: 0% 15% 0% 1%;
        width: 126%;
    }
}

@media (min-width: 992px) and (max-width:1800px) {
    .notification-body {
        padding: 0% 15% 0% 1%;
        width: 196%;
    }
    .notification-card {
        padding: 3%;
        display: grid;
    }
}