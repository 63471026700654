.food {
  background-color: white;
  height: 41px;
  width: 142px;
}

.QuickestText {
  font-size: 3.6vw;
  font-weight: bolder;
  font-weight: 600;
  font-family: "Playfair Display", serif;
  margin-top: 2%;
}

.FoodLanding {
  color: #ff6838;
  font-size: 3.6vw;
  font-weight: 900;
  inline-size: 8vw;
}
/* .textDivision {
inline-size: 36vw;
overflow-wrap: break-word;
} */

.text2 {
  color: #ff6838;
  font-size: 3.6vw;
  /*font-size - vw */
  font-weight: 900;
}

.DeliveryLanding {
  font-size: 3.5vw;
  font-weight: 900;
  color: #ff6838;
}

.inTownTextLanding {
  font-size: 3.6vw;
  font-weight: 600;
  color: black;
  font-family: "Playfair Display";
}

.text2 span {
  color: black;
}

.FoodDeliveryButtonLanding {
  width: auto;
  height: auto;
  border-radius: 10px 10px 10px 10px;
  font-size: large;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  color: #ff6838 !important;
  background-color: white !important;
  box-shadow: 1px 1px 1px 1px rgb(173 172 172);
}

.our_text {
  font-weight: 400;
  font-size: 1vw;
  color: #302f3c;
  margin-top: 3%;
}

.BurgerImageLanding {
  width: 2vw;
  height: 2vw;
}

.order_button {
  width: auto;
  height: auto;
  background-color: #ff6838 !important;
  color: white !important;
  border: none;
  border-radius: 21px;
  font-weight: 600;
  border: 1px solid orangered !important;
  min-width: 39%;
  padding: 10px 0;
  margin-top: 3%;
}

.ellipse {
  height: 100%;
}

.SearchIconLanding {
  font-size: 1.7vw;
}

.mobile {
  width: 67%;
  height: 115%;
  top: -19%;
  position: absolute;
  right: -7%;
}

.title_card {
  font-size: 1.7vw;
  font-weight: 900 !important;
  text-align: center;
  /* font-family: "Playfair Display"; */
}

.appstore_image {
  width: 95%;
}

.appstore_rating {
  background-color: #ff6838 !important;
  text-align: center;
  margin-left: 17px !important;
}

.playstore_image {
  width: 48%;
  margin-left: 26% !important;
}

.RatingsCountLanding {
  text-align: center;
}

.playstore_rating {
  background-color: #ff6838 !important;
  size: 15%;
}

.fillstart {
  color: yellow;
  font-size: 1.5vw;
  padding-bottom: 5px;
}

.playstore_rating span,
.appstore_rating span {
  color: black;
  font-weight: 800;
  justify-content: center;
  font-size: 1vw;
}

.ReviewCountlanding {
  font-size: 1vw;
  color: #000000;
  opacity: 60%;
}

.download_button {
  width: 91%;
  border-radius: 25px;
  background-color: white !important;
  color: black !important;
  font-weight: 700;
  height: 3.7vw;
  font-size: 1.3vw;
  border: 1px solid black !important;
  margin-left: 7px;
}

.download_button:hover {
  border: none !important;
}

.card_playstore,
.card_appstore {
  background-color: #ff6838;
  border-radius: 50px 50px;
  width: 86%;
  height: 100%;
}

.landingCard {
  border-radius: 60px;
  border: 1px solid black;
  height: 93%;
}

.landingCard_Title {
  text-align: justify;
  font-weight: 900;
  /* font-family: 'Playfair Display', serif; */
  /* font-family: 'Rowdies'; */
  font-size: 3.2vw;
}
/* Media Query For Mobile Phone Width 400*/

@media (min-width: 320px) and (max-width: 376px) {
  .body {
    overflow-x: hidden !important;
  }
  .imageLandingFood {
    display: flex;
    justify-content: center;
  }
  .BurgerImageLanding {
    width: 25px;
    height: 25px;
  }
  .containerLandingData {
    width: 100% !important;
  }
  .landingCard {
    width: 90vw;
    height: 72vw;
  }
  .title_card {
    font-size: 3vw;
    font-weight: 900 !important;
    text-align: center;
    /* font-family: "Playfair Display"; */
  }
  .ReviewCountlanding {
    font-size: 3vw;
    color: #000000;
    opacity: 60%;
  }
  .playstore_image {
    width: 48%;
    align-items: center;
    margin-left: 25px !important;
  }
  .playstore_rating span,
  .appstore_rating span {
    color: black;
    font-weight: 800;
    justify-content: center;
    font-size: 4vw;
  }
  .playstore_rating {
    background-color: #ff6838 !important;
    size: 15%;
    margin-left: -17px;
    width: 104px;
  }
  .appstore_rating {
    background-color: #ff6838 !important;
    text-align: center;
    margin-left: -17px !important;
  }
  .download_button {
    text-align: center;
    width: 70px;
    border-radius: 25px;
    background-color: white !important;
    color: black !important;
    font-weight: 700;
    height: 25px;
    font-size: 9px;
    border: 1px solid black !important;
    margin-left: 7px;
  }
  .order_button {
    width: 47vw !important;
  }
  .SearchIconLanding {
    font-size: 6.7vw;
  }
  .FoodLanding {
    color: #ff6838;
    font-size: 10vw;
    font-weight: 800;
    /* inline-size: 8vw; */
  }
  /* .ellipse {
        width: 100%;
        height: 100px;
        position: absolute;
        z-index: 999;
        left: 0;
        right: 0;
    } */
  .QuickestText {
    font-size: 7vw;
    font-weight: 800;
    font-size: 10vw;
    margin-top: 10px;
  }
  .DeliveryLanding {
    font-size: 7vw;
    margin-left: 1px;
    font-size: 10vw;
    font-weight: 800;
  }
  .our_text {
    font-weight: 400;
    font-size: 10px;
    min-width: 20px !important;
    margin-right: -27% !important;
    /* padding-right: 0px!important; */
    max-width: inherit;
  }
  .inTownTextLanding {
    text-align: center;
    font-size: 10vw;
  }
  .order_button {
    width: 54%;
    height: 57%;
    background-color: #ff6838;
    color: white;
    border: none;
    font-size: 4vw;
    border-radius: 20px;
    font-weight: 600;
    /* margin-left: 23%; */
    text-align: center;
    margin-top: 18px;
  }
  .mobile {
    width: 85%;
    height: 85%;
    top: -101%;
    position: absolute;
    right: 12%;
  }
  .card_appstore {
    background-color: #ff6838;
    border-radius: 30px 30px;
    width: 35vw;
    height: 60vw;
    margin-top: -64% !important;
  }
  .card_playstore {
    background-color: #ff6838;
    border-radius: 30px 30px;
    width: 35vw;
    height: 60vw;
    margin-top: -64% !important;
    margin-left: 130%;
  }
  .landingCard {
    margin-top: 70% !important;
    width: 94vw;
    margin-left: -16px;
    height: 50%;
  }
  .landingCard_Title {
    text-align: center;
    font-size: 7vw;
    margin-top: -2% !important;
  }
  .ellipse {
    height: 93%;
    width: 305px;
    margin-top: 25px;
    margin-left: -7px;
    align-items: center;
  }
  .body-container {
    margin-top: 27% !important;
    margin-left: 3% !important;
  }
}

@media (min-width: 376px) and (max-width: 450px) {
  .body {
    overflow-x: hidden !important;
  }
  .badge123 {
    right: -3%;
  }

  .imageLandingFood {
    display: flex;
    justify-content: center;
  }
  .BurgerImageLanding {
    width: 25px;
    height: 25px;
    margin-top: 3px;
  }
  .containerLandingData {
    width: 100% !important;
  }
  .landingCard {
    width: 90vw;
    height: 72vw;
  }
  .title_card {
    font-size: 3vw;
    font-weight: 900 !important;
    text-align: center;
    /* font-family: "Playfair Display"; */
  }
  .ReviewCountlanding {
    font-size: 3vw;
    color: #000000;
    opacity: 60%;
  }
  .playstore_image {
    width: 48%;
    align-items: center;
    margin-left: 25px !important;
  }
  .playstore_rating span,
  .appstore_rating span {
    color: black;
    font-weight: 800;
    justify-content: center;
    font-size: 4vw;
  }
  .playstore_rating {
    background-color: #ff6838 !important;
    size: 15%;
    margin-left: -17px;
  }
  .appstore_rating {
    background-color: #ff6838 !important;
    text-align: center;
    margin-left: -17px !important;
  }
  .download_button {
    width: 91%;
    border-radius: 25px;
    background-color: white !important;
    color: black !important;
    font-weight: 800;
    height: 6vw;
    font-size: 2.3vw;
    border: 1px solid black !important;
    margin-left: 7px;
  }
  .order_button {
    width: 47vw !important;
  }
  .SearchIconLanding {
    font-size: 6.7vw;
  }
  .FoodLanding {
    color: #ff6838;
    font-size: 10vw !important;
    font-weight: 800;
    /* inline-size: 8vw; */
  }

  .QuickestText {
    font-size: 10vw !important;
    font-weight: 800;
    font-size: 14vw;
    margin-top: 10px;
  }
  .DeliveryLanding {
    font-size: 10vw !important;
    margin-left: 1px;
    font-size: 12vw;
    font-weight: 800;
  }
  .our_text {
    font-weight: 400;
    font-size: 10px;
    min-width: 20px !important;
    margin-right: 4% !important;
    /* padding-right: 0px!important; */
    max-width: inherit;
  }
  .inTownTextLanding {
    text-align: center;
    font-size: 10vw;
  }
  .order_button {
    width: 54%;
    height: 57%;
    background-color: #ff6838;
    color: white;
    border: none;
    font-size: 4vw;
    border-radius: 20px;
    font-weight: 600;
    padding: 7px 0;
    text-align: center;
    margin-top: 18px;
  }
  .mobile {
    width: 96%;
    height: 80%;
    top: -102%;
    position: absolute;
    right: 4%;
  }
  .card_appstore {
    background-color: #ff6838;
    border-radius: 40px 40px;
    width: 35vw;
    height: 55vw;
    margin-top: -28% !important;
    margin-left: 4% !important;
  }
  .card_playstore {
    background-color: #ff6838;
    border-radius: 40px 40px;
    width: 35vw;
    height: 55vw;
    margin-top: -28% !important;
    margin-left: 147% !important;
  }
  .landingCard {
    margin-top: 78% !important;
    width: 91vw;
    height: 80vw;
  }
  .landingCard_Title {
    text-align: center;
    font-size: 7vw;
    margin-top: -2% !important;
  }
  .ellipse {
    height: 93%;
    width: 300px;
    margin-top: 25px;
    align-items: center;
  }
  .body-container {
    margin-top: 27% !important;
    margin-left: 3% !important;
  }
}

@media (min-width: 451px) and (max-width: 576px) {
  .body {
    overflow-x: hidden !important;
  }
  .imageLandingFood {
    display: flex;
    justify-content: center;
  }
  .BurgerImageLanding {
    width: 25px;
    height: 25px;
    margin-top: 3px;
  }
  .containerLandingData {
    width: 100% !important;
  }
  .landingCard {
    width: 90vw;
    height: 72vw;
  }
  .title_card {
    font-size: 3vw;
    font-weight: 900 !important;
    text-align: center;
    /* font-family: "Playfair Display"; */
  }
  .ReviewCountlanding {
    font-size: 3vw;
    color: #000000;
    opacity: 60%;
  }
  .playstore_image {
    width: 48%;
    align-items: center;
    margin-left: 25px !important;
  }
  .playstore_rating span,
  .appstore_rating span {
    color: black;
    font-weight: 800;
    justify-content: center;
    font-size: 4vw;
  }
  .playstore_rating {
    background-color: #ff6838 !important;
    size: 15%;
    margin-left: -17px;
  }
  .appstore_rating {
    background-color: #ff6838 !important;
    text-align: center;
    margin-left: -17px !important;
  }
  .download_button {
    width: 91%;
    border-radius: 25px;
    background-color: white !important;
    color: black !important;
    font-weight: 800;
    height: 6vw;
    font-size: 2.3vw;
    border: 1px solid black !important;
    margin-left: 7px;
  }
  .order_button {
    width: 47vw !important;
  }
  .SearchIconLanding {
    font-size: 5.7vw;
  }
  .FoodLanding {
    color: #ff6838;
    font-size: 10vw;
    font-weight: 800;
    /* inline-size: 8vw; */
  }
  /* .ellipse {
        width: 100%;
        height: 100px;
        position: absolute;
        z-index: 999;
        left: 0;
        right: 0;
    } */
  .QuickestText {
    font-size: 10vw !important;
    font-weight: 800;
    font-size: 14vw;
    margin-top: 10px;
  }
  .DeliveryLanding {
    font-size: 10vw !important;
    margin-left: 1px;
    font-size: 12vw;
    font-weight: 800;
  }
  .our_text {
    font-weight: 400;
    font-size: 10px;
    min-width: 20px !important;
    margin-right: 4% !important;
    /* padding-right: 0px!important; */
    max-width: inherit;
  }
  .inTownTextLanding {
    text-align: center;
    font-size: 10vw !important;
  }
  .order_button {
    width: 40% !important;
    height: 57%;
    background-color: #ff6838;
    color: white;
    border: none;
    font-size: 3vw;
    border-radius: 20px;
    font-weight: 600;
    /* margin-left: 23%; */
    text-align: center;
    margin-top: 18px;
  }
  .mobile {
    width: 102%;
    height: 90%;
    top: -113%;
    position: absolute;
    right: 0%;
  }
  .card_appstore {
    background-color: #ff6838;
    border-radius: 40px 40px;
    width: 35vw;
    height: 55vw;
    margin-top: -28% !important;
    margin-left: 20% !important;
  }
  .card_playstore {
    background-color: #ff6838;
    border-radius: 40px 40px;
    width: 35vw;
    height: 55vw;
    margin-top: -28% !important;
    margin-left: 143% !important;
  }
  .landingCard {
    margin-top: 78% !important;
    width: 90vw;
    height: 80vw;
  }
  .landingCard_Title {
    text-align: center;
    font-size: 7vw;
    margin-top: -2% !important;
  }
  .ellipse {
    height: 70%;
    width: auto;
    margin-top: 27px;
    margin-left: -50px;
    align-items: center;
  }
  .body-container {
    margin-top: 21% !important;
    margin-left: 8%;
  }
}

@media (min-width: 567px) and (max-width: 769px) {
  .body {
    overflow-x: hidden !important;
  }
  .imageLandingFood {
    display: flex;
    justify-content: center;
  }
  .BurgerImageLanding {
    width: 25px;
    height: 25px;
  }
  .containerLandingData {
    width: 100% !important;
  }
  .landingCard {
    width: 90vw;
    height: 72vw;
  }
  .title_card {
    font-size: 3vw;
    font-weight: 900 !important;
    text-align: center;
    /* font-family: "Playfair Display"; */
  }
  .ReviewCountlanding {
    font-size: 3vw;
    color: #000000;
    opacity: 60%;
  }
  .playstore_image {
    width: 48%;
    align-items: center;
    margin-left: 25px !important;
  }
  .playstore_rating span,
  .appstore_rating span {
    color: black;
    font-weight: 800;
    justify-content: center;
    font-size: 4vw;
  }
  .playstore_rating {
    background-color: #ff6838 !important;
    size: 15%;
    margin-left: -17px;
  }
  .appstore_rating {
    background-color: #ff6838 !important;
    text-align: center;
    margin-left: -17px !important;
  }
  .download_button {
    width: 91%;
    border-radius: 25px;
    background-color: white !important;
    color: black !important;
    font-weight: 800;
    height: 6vw;
    font-size: 2.3vw;
    border: 1px solid black !important;
    margin-left: 7px;
  }
  .order_button {
    width: 47vw !important;
  }
  .SearchIconLanding {
    font-size: 4.5vw;
  }
  .FoodLanding {
    color: #ff6838;
    font-size: 7vw !important;
    font-weight: 800;
    /* inline-size: 8vw; */
  }
  /* .ellipse {
        width: 100%;
        height: 100px;
        position: absolute;
        z-index: 999;
        left: 0;
        right: 0;
    } */
  .QuickestText {
    font-size: 7vw;
    font-weight: 800;
    margin-top: 10px;
  }
  .DeliveryLanding {
    font-size: 7vw;
    margin-left: 1px;
    font-weight: 800;
  }
  .our_text {
    font-weight: 400;
    font-size: 14px;
    min-width: 20px !important;
    margin-right: 4% !important;
    /* padding-right: 0px!important; */
    max-width: inherit;
  }
  .inTownTextLanding {
    text-align: center;
    font-size: 7vw;
  }
  .order_button {
    width: 38% !important;
    height: 57%;
    background-color: #ff6838;
    color: white;
    border: none;
    font-size: 2.5vw;
    border-radius: 20px;
    font-weight: 600;
    /* margin-left: 23%; */
    text-align: center;
    margin-top: 18px;
  }
  .mobile {
    width: 95%;
    height: 85%;
    top: -91%;
    position: absolute;
    right: 2%;
  }
  .card_appstore {
    background-color: #ff6838;
    border-radius: 40px 40px;
    width: 35vw;
    height: 55vw;
    margin-top: -4% !important;
    margin-left: 15% !important;
  }
  .card_playstore {
    background-color: #ff6838;
    border-radius: 40px 40px;
    width: 35vw;
    height: 55vw;
    margin-top: -4% !important;
    margin-left: 146% !important;
  }
  .landingCard {
    margin-top: 78% !important;
    width: 90vw;
    height: 80vw;
    margin-left: -56px;
    height: auto;
  }
  .landingCard_Title {
    text-align: center;
    font-size: 7vw;
    margin-top: -2% !important;
  }
  .ellipse {
    height: 90%;
    width: auto;
    margin-top: 25px;
    align-items: center;
  }
  .body-container {
    margin-top: 20% !important;
    margin-left: 8%;
  }
}

@media (min-width: 769px) and (max-width: 948px) {
  .body {
    overflow-x: hidden !important;
  }
  .imageLandingFood {
    display: flex;
    justify-content: center;
  }
  .BurgerImageLanding {
    width: 25px;
    height: 25px;
  }
  .containerLandingData {
    width: 100% !important;
  }
  .landingCard {
    width: 90vw;
    height: 72vw;
  }
  .title_card {
    font-size: 3vw;
    font-weight: 900 !important;
    text-align: center;
    /* font-family: "Playfair Display"; */
  }
  .ReviewCountlanding {
    font-size: 2vw;
    color: #000000;
    opacity: 60%;
  }
  .playstore_image {
    width: 48%;
    align-items: center;
    margin-left: 29% !important;
  }
  .playstore_rating span,
  .appstore_rating span {
    color: black;
    font-weight: 800;
    justify-content: center;
    font-size: 2vw;
  }
  .playstore_rating {
    background-color: #ff6838 !important;
    size: 15%;
    margin-left: -17px;
  }
  .appstore_rating {
    background-color: #ff6838 !important;
    text-align: center;
    margin-left: -17px !important;
  }
  .download_button {
    width: 91%;
    border-radius: 25px;
    background-color: white !important;
    color: black !important;
    font-weight: 800;
    height: 6vw;
    font-size: 2.3vw;
    border: 1px solid black !important;
    margin-left: 7px;
  }
  .order_button {
    width: 47vw !important;
  }
  .SearchIconLanding {
    font-size: 3.7vw;
  }
  .FoodLanding {
    color: #ff6838;
    font-size: 108% !important;
    font-weight: 800;
  }
  /* .ellipse {
        width: 100%;
        height: 100px;
        position: absolute;
        z-index: 999;
        left: 0;
        right: 0;
    } */
  .QuickestText {
    font-weight: 800;
    font-size: 6vw;
    margin-top: 10px;
  }
  .DeliveryLanding {
    margin-left: 1px;
    font-size: 7vw;
    font-weight: 800;
  }
  .our_text {
    font-weight: 400;
    font-size: 110%;
    min-width: 20px !important;
    margin-right: 4% !important;
    max-width: inherit;
  }
  .inTownTextLanding {
    text-align: center;
    font-size: 108%;
  }
  .order_button {
    width: 27% !important;
    height: 56%;
    background-color: #ff6838;
    color: white;
    border: none;
    font-size: 2vw;
    border-radius: 20px;
    font-weight: 600;
    /* margin-left: 23%; */
    text-align: center;
    margin-top: 4%;
  }

  .mobile {
    width: 73%;
    height: 78%;
    top: -91%;
    position: absolute;
    right: 13%;
  }
  .card_appstore {
    background-color: #ff6838;
    border-radius: 40px 40px;
    width: 35vw;
    height: 50vw;
    margin-top: -4% !important;
    margin-left: 15% !important;
  }
  .card_playstore {
    background-color: #ff6838;
    border-radius: 40px 40px;
    width: 35vw;
    height: 50vw;
    margin-top: -4% !important;
    margin-left: 146% !important;
  }
  .landingCard {
    margin-top: 78% !important;
    width: 90vw;
    height: 80vw;
    margin-left: -56px;
    height: auto;
  }
  .landingCard_Title {
    text-align: center;
    font-size: 7vw;
    margin-top: -2% !important;
  }
  .ellipse {
    height: 90%;
    width: auto;
    margin-top: 40px;
    align-items: center;
    margin-left: 50px;
  }
  .body-container {
    margin-top: 20% !important;
    margin-left: 8%;
  }
}

@media (min-width: 948px) and (max-width: 1290px) {
  .ellipse {
    height: 100%;
    width: 400px;
    /* margin-top: 25px; */
    align-items: center;
    margin-left: 71px;
  }
  .body-container {
    margin-top: 12% !important;
    margin-left: 8%;
  }
}

@media (min-width: 1290px) and (max-width: 1430px) {
  .ellipse {
    height: auto;
    width: 520px;
    /* margin-top: 25px; */
    align-items: center;
    margin-left: 71px;
  }
  .body-container {
    margin-top: 12% !important;
    margin-left: 8%;
  }
}

@media (min-width: 1430px) and (max-width: 1800px) {
  .body-container {
    margin-top: 12% !important;
    margin-left: 8%;
  }
}

.body-container {
  margin-top: 10%;
  margin-left: 8%;
}

.CardDesignLanding {
  margin-top: 10%;
}

@media (min-width: 948px) and (max-width: 992px) {
  .fillstart {
    font-size: 2.5vw;
    padding-bottom: 3px;
  }
  .RatingsCountLanding {
    margin-left: -12%;
    width: 34%;
  }
}
@media (min-width: 576px) and (max-width: 948px) {
  .fillstart {
    font-size: 3.5vw;
    padding-bottom: 6px;
    margin-top: -6%;
    margin-left: -3%;
  }
}
@media (min-width: 450px) and (max-width: 576px) {
  .appstore_rating {
    width: 73%;
  }
  .RatingsCountLanding {
    margin-left: -15%;
  }
  .fillstart {
    font-size: 4vw;
  }
  .playstore_rating {
    width: 73%;
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .fillstart {
    font-size: 5vw;
  }
  .ReviewCountlanding {
    font-size: 2.5vw;
  }
  .appstore_rating {
    width: 110%;
  }
}
