.transactionBadge {
  width: 48vw;
  float: left;
  background-color: rgba(238, 228, 228, 0.963) !important;
}
.walletDebitedProfile {
  color: red;
}
.transactionFont {
  float: left;
  color: black;
  font-size: 140%;
  font-weight: 700;
}

.filterIcon {
  color: black;
  font-size: 200%;
  font-weight: 600;
  cursor: pointer;
}

.Wallet_Card {
  width: 57vw;
  border-radius: 0px;
}

.TotalBlance {
  width: 22vw;
  height: 21vh;
}

.AddMoneyText {
  text-align: center;
  float: center;
}

.WalletDeatilsProfile {
  overflow-y: auto;
  overflow-x: hidden;
  height: 168px !important;
  border-radius: 16px;
}

.WalletDeatilsProfile::-webkit-scrollbar-thumb {
  background-color: orangered !important;
  /* color of the scroll thumb */
  border-radius: 20px !important;
  /* roundness of the scroll thumb */
  border: 3px solid orangered !important;
  /* creates padding around scroll thumb */
}

.WalletDeatilsProfile::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

.WalletDeatilsProfile::-webkit-scrollbar-track {
  background: none;
  /* color of the tracking area */
}

.CashBackinProfile,
.TotalMoneyAddedinProfile {
  text-align: center;
}

.TotalMoneyAddedCard,
.CashBackCard {
  width: 22vw;
}

.TotalBalanceProfile,
.TotalAmountProfile {
  text-align: center;
}

.AmountCreditedProfilepage {
  float: right;
}

.expiryDateProfile {
  float: right;
  margin-left: 20%;
  margin-bottom: 0%;
}

.hrLineinWalletDetails {
  margin-top: -16% !important;
  margin-bottom: 0px !important;
}

.textdataWalletDetails {
  margin-top: 0px;
  margin-bottom: 0px;
}

.title-container {
  width: 80%;
  margin: 2% auto;
}

.total-bal-container {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  justify-content: space-between;
}

.bal-content {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bal-content div {
  width: 100%;
  border: 1px solid #ddd;
  min-height: 90px;
  display: inherit;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.bal-content div p {
  font-weight: 600;
}

.total-bal {
  width: 45%;
  border: 1px solid #ddd;
  min-height: 200px;
  display: inherit;
  align-items: center;
  justify-content: center;
  font-size: 1.5vw;
  flex-direction: column;
}

.green-text {
  color: rgb(4, 140, 4);
  font-size: 1.2vw;
  margin-right: 6% !important;
}

.transaction-title {
  padding: 2% 0 2% 2%;
  width: 80%;
}

.transaction-icon {
  padding: 2% 2% 2% 0;
}

.transaction-header-container {
  background: rgba(238, 228, 228, 0.963) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: 4% auto;
}

.transaction-list {
  width: 80%;
  margin: 1% auto;
  max-height: 180px;
  overflow: auto;
}

.transaction-content {
  display: flex;
  flex-direction: row;
  margin: 0 0 1% 0;
  border-bottom: 1px solid #ddd;
}

.transaction-content div:last-child {
  margin-left: auto;
}

.add-money-btn {
  width: 20%;
  background-color: orangered !important;
  color: white !important;
}

.add-money-container {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2% auto;
}

.transaction-list::-webkit-scrollbar-thumb {
  background-color: orangered !important;
  /* color of the scroll thumb */
  border-radius: 20px !important;
  /* roundness of the scroll thumb */
  border: 3px solid orangered !important;
  /* creates padding around scroll thumb */
}

.transaction-list::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

.transaction-list::-webkit-scrollbar-track {
  background: none;
  /* color of the tracking area */
}
@media screen and (max-width: 576px) {
  .total-bal {
    width: 45%;
    border: 1px solid #ddd;
    min-height: 200px;
    display: inherit;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    flex-direction: column;
  }
  .RestNameWalletDetails {
    font-size: 73%;
  }
  .address-display {
    font-size: 78%;
  }
  /* .locationPopupForm .modal-body{
margin-left: 4%;
    } */
  .green-text {
    color: rgb(4, 140, 4);
    font-size: 76%;
  }
  .add-money-btn {
    width: 35%;
    background-color: orangered !important;
    color: white !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 769px) {
  .total-bal {
    width: 45%;
    border: 1px solid #ddd;
    min-height: 200px;
    display: inherit;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    flex-direction: column;
  }
  .green-text {
    color: rgb(4, 140, 4);
    font-size: 15px;
  }
  .add-money-btn {
    width: 30%;
    background-color: orangered !important;
    color: white !important;
  }
}
