.itemImageSearch {
  width: 18.5vw;
  margin-top: -6%;
  margin-left: -6%;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 18px !important;
  border-top-right-radius: 18px !important;
  height: 20.5vh;
}
.Seach_HistoryIcon {
  color: orangered;
  font-size: 160%;
  cursor: pointer;
}
.searchHistoryName {
  cursor: pointer;
}
.SearchItemHistoryDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
}
.no-dataSearchItems {
  padding: 2%;
  text-align: center;
  font-size: 127%;
  font-weight: 500;
}
.card-body {
  min-width: 80%;
  max-width: 250%;
  margin-right: 4%;
  margin-left: 2%;
  border-radius: 1px;
}
.currently-unavailableSearch {
  background-color: #ff4500;
  border-radius: 10px;
  color: #fff;
  font-size: 0.78em;
  font-weight: 500;
  height: 25px;
  padding: 1px 3px;
  width: 250%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.UnavailableDiv {
  display: flex;
  flex-direction: row;
  align-items: end;
}

.searchDataCards {
  width: 100%;
  border-radius: 1px !important;
  border: none !important;
}
.displayyy {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.displayyy::-webkit-scrollbar-thumb {
  background-color: orangered !important;
  /* color of the scroll thumb */
  border-radius: 20px !important;
  /* roundness of the scroll thumb */
  border: 3px solid orangered !important;
}
.displayyy::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* width of the entire scrollbar */
}
.ProductNameSearch {
  font-size: 76%;
  font-weight: 600;
  width: 151%;
}
.ItemDetailsSear {
  width: 165%;
}

.searchItemImage {
  width: 14%;
  margin-right: 2%;
}

.PriceItemSearch {
  font-size: 78%;
  font-weight: 600;
}
.ProductItemCart {
  width: 75%;
  margin-left: 12%;
  height: 12%;
  border-radius: 0px !important;
  margin-top: 4%;
}
@media screen and (min-width: 320px) and (max-width: 376px) {
  .subTitleTextSearch {
    width: 100%;
  }
  .itemNextOpenTime {
    position: absolute;
    top: 19%;
  }
  .restCloseSearch {
    margin-left: -19% !important;
  }
  .searchDeliveryHandle {
    font-size: 68% !important;
    height: 17px;
  }
}
/* @media screen and (min-width: 378px) and (max-width: 450px) {
  .deliveryTimeSearch {
    width: 29vw !important;
  }
}
@media screen and (min-width: 378px) and (max-width: 450px) {
  .deliveryTimeSearch {
    width: 29vw !important;
  }
} */
