.popular {
  font-weight: 700;
  font-size: 120%;
}
.addReviewsPopUP .modal-body {
  width: 90%;
  margin-left: 6%;
}
.RatingProfileData {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}
.ScrollBarRatingDiv {
  overflow-y: auto;
  overflow-x: hidden;
  height: 300px !important;
  /* border-radius: 16px; */
}
.RestDataHeader {
  margin-bottom: 3%;
}
.review-content {
  /* margin: 0 0 1% 0; */
  border-bottom: 1px solid #ddd;
  margin-bottom: 2%;
}
.itemReviewRESTlistPage {
  width: 70%;
}
.RatingReviewProfileData {
  display: flex;
  flex-direction: row;
  width: 60%;
}
.itemREviewProfile {
  font-weight: 400;
}
.itemRatingResText {
  font-size: 110%;
  font-weight: 600;
  width: 63%;
}
.RateItemStarDiv {
  margin-top: 4%;
}
.ReviewsContentScrollDiv {
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px !important;
}
.ReviewsContentScrollDiv::-webkit-scrollbar-track {
  background: none;
  /* color of the tracking area */
}
.ReviewsContentScrollDiv::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}
.ReviewsContentScrollDiv::-webkit-scrollbar-thumb {
  background-color: orangered !important; /* color of the scroll thumb */
  border-radius: 20px !important; /* roundness of the scroll thumb */
  border: 3px solid orangered !important; /* creates padding around scroll thumb */
}

.ScrollBarRatingDiv::-webkit-scrollbar-track {
  background: none;
  /* color of the tracking area */
}
.ScrollBarRatingDiv::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}
.ScrollBarRatingDiv::-webkit-scrollbar-thumb {
  background-color: orangered !important; /* color of the scroll thumb */
  border-radius: 20px !important; /* roundness of the scroll thumb */
  border: 3px solid orangered !important; /* creates padding around scroll thumb */
}
.itemContentDataDiv {
  display: flex;
  flex-direction: row;
  width: 97%;
}
.itemReviewName {
  font-weight: 600;
  font-size: 119%;
}
.itemRatingstar {
  color: #faaf00;
}
.ReviewsTextHead {
  text-align: center;
  font-size: 135%;
  font-weight: 500;
}
.itemRatingDataProduct {
  font-size: 102%;
  font-weight: 600;
  margin-left: 5%;
}
.itemRatingdataProfile {
  margin-left: -55%;
}
.itemRatingRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 11%;
}
.itemRatingContent {
}
.RatingItemsStarDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.RatingSendButton {
  width: 70%;
}
.css-177ic5c {
  overflow-x: scroll !important;
}
.addRatingsText {
  margin-left: -60%;
  font-size: x-large;
  font-weight: 700;
}
.itemTypeNonVeg {
  border: 2px solid red;
  color: red;
  font-size: 127%;
  padding: 2px;
  margin: 14% auto auto 26%;
}

.itemTypeVeg {
  border: 2px solid green;
  color: green;
  font-size: 127%;
  padding: 2px;
  margin: 14% auto auto 26%;
}

.itemDescription {
  font-weight: "500";
  font-size: small;
}

.price {
  font-weight: 700;
  font-size: 14px;
}

.itemImage {
  width: 83%;
  height: auto;
  max-height: 100px;
  object-fit: cover;
  border-radius: 15px 15px 15px 15px;
  margin: 3px;
}

.itemImagePayment {
  width: 100%;
  height: 68px;
  border-radius: 15px 15px 15px 15px;
  /* margin-left: -35%; */
}

.item_count_button {
  border-radius: 25px;
  background-color: orangered;
  position: relative;
  bottom: 53%;
  left: 62%;
  width: 6vw;
  height: 2vw;
  font-size: 1.2vw;
  padding-top: 2px;
  padding-bottom: 26px;
}

.increament {
  float: right;
  font-weight: 700;
  color: black;
  font-size: larger;
  text-align: justify;
  padding-bottom: 10%;
  margin-top: -3px;
  padding-bottom: 30px;
}

.count {
  float: center;
  font-size: 16px;
  width: inherit;
}

.decreament {
  float: left;
  font-weight: 700;
  color: black;
  font-size: larger;
  margin-top: -3px;
}

.css-1q2h7u5.Mui-selected {
  color: #ff6838 !important;
}
.css-orq8zk {
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}
.css-1q2h7u5 {
  text-transform: capitalize !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: orangered !important;
  width: auto !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: orangered !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  width: auto !important;
  text-transform: capitalize !important;
}

.css-ttwr4n {
  background-color: #ff6838 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #000 !important;
}

@media screen and (max-width: 500px) {
  .price {
    font-size: 13px;
    font-weight: 800;
    width: fit-content;
  }
  .itemDescription {
    font-weight: "500";
    font-size: small;
  }
}

@media (min-width: 576px) and (max-width: 769px) {
  .price {
    font-size: 14px;
    font-weight: 800;
    width: fit-content;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .price {
    font-weight: 800;
    font-size: 13px;
    width: fit-content;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .price {
    font-weight: 700;
    font-size: 70%;
  }
  .css-vubbuv {
    left: -211%;
    position: relative;
  }
}

/* .css-1km1ehz {
    overflow-x: scroll!important;
} */

.css-6hp17o-MuiList-root-MuiMenu-list {
  width: 270px !important;
}
@media (min-width: 1200px) and (max-width: 1444px) {
  .selectBoxPaymentTwo {
    margin-left: 20%;
  }
}
