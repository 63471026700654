.Track_Order_Title {
  color: #ff6838;
  font-weight: 700;
  text-align: center;
  margin-bottom: -1%;
  font-size: 25px;
}
.trackTextOrder {
  cursor: pointer;
}
.track_order_title {
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  margin-bottom: 35px;
}

.order_Placed,
.order_Confirmed,
.order_Preparing,
.order_Assigning,
.order_Assigned,
.order_is_Ready,
.order_Dispatched,
.order_Delivered {
  width: 39%;
}

.Order_Placed_Text,
.Order_Confirmed_Text,
.Order_Preparing_Text,
.Order_Assigning_Text,
.Order_Assigned_Text,
.Order_is_Ready_Text,
.Order_Dispatched_Text,
.Order_Delivered_Text {
  font-weight: 700;
  font-size: 1vw;
}

.OrderNumberTrackOrder {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}

.ChatOptionTrack {
  font-weight: 700;
  font-size: 1vw;
}

.DatainTrackOrderDetails {
  font-weight: 700;
  font-size: 1.2vw;
}

.ModalHeaderTrackPopup.modal-header .btn-close {
  position: relative;
  top: 20%;
  left: -4%;
}

.ItemTrackPopup {
  text-align: center;
  font-size: 1.1vw;
}

.QuantityTrackPopup {
  font-size: 1.1vw;
  text-align: center;
}

.PriceTrackPopup {
  font-size: 1.1vw;
  text-align: center;
}

.OrderItemTrackPopup {
  width: 100%;
  background-color: #ff6838 !important;
  border-radius: 0;
  font-size: 15px;
}

.track_modalTrack {
  --bs-modal-width: 920px !important;
}

.TotalAmountTrackOrder {
  font-size: 1.2vw;
}
.BadgeContentItem {
  display: flex;
  justify-content: space-between;
  color: white !important;
}
.displayContentBadge {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  width: 100%;
}
.itemvaluestrackorder {
  font-size: 1.3vw;
  font-weight: 700;
}

.TotalDeliveryChargesTrack {
  font-size: 1.1vw;
}

.itemvaluestrackorderMoney {
  font-size: 1.2vw;
  font-weight: 700;
  margin-top: -3%;
}

.OrderTotalValueTrack {
  font-weight: 700;
  font-size: 1.5vw;
}

.TotalMoneyValueTrack {
  color: #ff6838;
  font-weight: 700;
}
.css-bvz20t .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 12.5px 20px 12.5px 10px !important;
}
.OrderStatusCompleted {
  height: 85%;
  margin-left: 10px;
  border-left: 3.1px solid #ff6838;
}
.table-containerTable {
  width: 62%;
  margin-left: 19%;
}
.itemNameTrackorder {
  font-weight: 700;
  font-size: 14px;
  width: 135%;
  margin-left: 68px;
  color: #000000;
}

.table-header-container {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  color: white;
}

.table-container {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  color: #ff6838;
  margin-left: -25%;
}

.itemDetailsTableTrack tr td:first-child {
  font-size: 12px;
  vertical-align: top;
  width: 145px;
}

.itemDetailsTableTrack tr th {
  font-size: 13px;
}

.OrderItemTrackPopup .BadgeContentItem div {
  padding: 10px;
}

.ordrPopupHdr {
  background: #ff6838;
  color: white;
}

.ordrPopupHdr div {
  padding: 10px;
}

/*new popup css*/
.trckPopWrpr {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 20px;
}

.trckPopWrpr .ordrTblsDtls {
  margin-top: 20px;
}

.trckPopWrpr .displayContentBadge div {
  padding-top: 10px;
  padding-bottom: 10px;
}

.trckPopWrpr .ItemOrderTable {
  border-top: 1px solid rgb(100, 100, 100);
}

.trckPopWrpr .TableClassTrackOrder {
  position: relative;
  z-index: 0;
}

.trckPopWrpr .lftTrkPopup .TableClassTrackOrder::after {
  content: "";
  width: 1px;
  height: calc(100% - 5px);
  background: transparent;
  border-left: 1px dashed gray;
  position: absolute;
  left: 12px;
  top: 11px;
  z-index: -1;
}

.trckPopWrpr
  .lftTrkPopup
  .TrackOrderContainer.last
  .TableClassTrackOrder::after {
  content: none;
}

/*new css end*/

.ItemTotalValueProfile {
  font-size: 1.1em;
  font-weight: 400;
  margin-top: 1%;
}
.OrderTotalProfile {
  font-weight: 700;
  font-size: 1.3em;
  margin-top: -2% !important;
}
.DeliveryChargeValueProfile {
  font-size: 1.1em;
  font-weight: 400;
  margin-top: 10%;
}
th,
td {
  padding: 9px;
}
.itemLeftTrackOrder {
  padding: 3%;
}
.OrderTextTrackOrder {
  margin-left: 0;
}
.OrderTrackOrderContent {
  margin-left: 9%;
}
/* .TableClassTrackOrder {
  width: 16%;
} */
.orderInactiveImage {
  width: 35%;
}
/* td img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;

} */

.imageContainerTable {
  margin-right: -16%;
  width: 13%;
}
.TrackOrderImages {
}
.trackerTextOrder {
  font-weight: normal;
}
.TrackOrderContainer {
  display: grid;
  grid-template-columns: 50px 1fr;
  min-height: 80px;
  /* flex-direction: row;
  margin-top: 4%;
  margin-left: 6%; */
}
.textContainerTrack {
  margin-left: 0;
  width: 100%;
  font-weight: 700;
}
.trackTextOrder {
  text-align: center;
  margin: 21%;
}
.trackTittle {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}

.trackTittle p {
  margin: 0;
}

.orderDataTable {
  width: 100%;
}

@media (max-width: 800px) {
  .trckPopWrpr {
    grid-template-columns: 1fr;
  }
}
