.deleteAccountPopupProfile {
    position: absolute;
    top: 11%;
    right: 6px;
}

.cancelaccountpopupProfile {
    width: 100%;
    background-color: #b8b3b3!important;
}

.deleteaccountpopupProfile {
    width: 100%;
    background-color: #ff4500!important;
    color: white!important;
}

.TrackOrderPopupProfile {
    position: absolute!important;
    top: 10%;
    right: 6px;
}

.deleteaccountPopupProfileModal {
    height: 70%!important;
}

.deleteAccountPopupclose .btn-close {
    margin-top: -15%!important;
    margin-left: -9%;
    margin-right: 5%;
}

.deleteaccountText {
    font-weight: 600;
    font-size: 15px;
    text-align: center;
}

.EditProfileData,
.DeleteProfileData {
    border: 1px solid black;
    border-radius: 25px;
    padding-top: 2px;
    /* margin-top: 10px; */
    padding-bottom: 0px;
    font-size: 2vw;
    color: #FF6838;
    padding: 3px;
    margin-top: 8px;
}

.DeleteAccountPopup {
    text-align: center;
}

.DeleteAccountText {
    text-align: center;
    font-size: 2vw;
    font-weight: 700;
}

@media (min-width: 320px) and (max-width:376px) {
    .EditProfileData,
    .DeleteProfileData {
        border: 1px solid black;
        border-radius: 25px;
        padding-top: 2px;
        /* margin-top: 10px; */
        padding-bottom: 0px;
        font-size: 22px;
        color: #FF6838;
        padding: 3px;
        margin-top: 8px;
    }
    .cancelaccountpopupProfile {
        width: 120%;
        background-color: #b8b3b3!important;
        margin-left: 70px;
    }
    .deleteaccountpopupProfile {
        width: 120%;
        background-color: #ff4500!important;
        color: white!important;
        margin-left: 70px;
    }
    .DeleteAccountText {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        margin-left: 100px;
    }
    .deleteaccountText {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
    }
}

@media (min-width: 376px) and (max-width:450px) {
    .EditProfileData,
    .DeleteProfileData {
        border: 1px solid black;
        border-radius: 25px;
        padding-top: 2px;
        /* margin-top: 10px; */
        padding-bottom: 0px;
        font-size: 22px;
        color: #FF6838;
        padding: 3px;
        margin-top: 8px;
    }
    .cancelaccountpopupProfile {
        width: 100%;
        margin-left: 33%;
    }
    .deleteaccountpopupProfile {
        width: 100%;
        margin-left: 71%;
    }
    .DeleteAccountText {
        text-align: center;
        font-size: 14px!important;
        font-weight: 700;
        margin-left: 150px;
    }
}

@media (min-width: 450px) and (max-width:576px) {
    .EditProfileData,
    .DeleteProfileData {
        border: 1px solid black;
        border-radius: 25px;
        padding-top: 2px;
        /* margin-top: 10px; */
        padding-bottom: 0px;
        font-size: 22px;
        color: #FF6838;
        padding: 3px;
        margin-top: 8px;
    }
    .cancelaccountpopupProfile {
        width: 60%;
        background-color: #b8b3b3!important;
        margin-left: 70px;
    }
    .deleteaccountpopupProfile {
        width: 60%;
        background-color: #ff4500!important;
        color: white!important;
        margin-left: 70px;
    }
    .DeleteAccountText {
        text-align: center;
        font-size: 15px!important;
        font-weight: 700;
        margin-left: 150px;
    }
}

@media (min-width: 576px) and (max-width:769px) {
    .EditProfileData,
    .DeleteProfileData {
        border: 1px solid black;
        border-radius: 25px;
        padding-top: 2px;
        /* margin-top: 10px; */
        padding-bottom: 0px;
        font-size: 22px;
        color: #FF6838;
        padding: 3px;
        margin-top: 8px;
    }
    .cancelaccountpopupProfile {
        width: 60%;
        background-color: #b8b3b3!important;
        margin-left: 70px;
    }
    .deleteaccountpopupProfile {
        width: 60%;
        background-color: #ff4500!important;
        color: white!important;
        margin-left: 70px;
    }
    .DeleteAccountText {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        margin-left: 150px;
    }
}

@media (min-width: 769px) and (max-width:992px) {
    .EditProfileData,
    .DeleteProfileData {
        border: 1px solid black;
        border-radius: 25px;
        padding-top: 2px;
        /* margin-top: 10px; */
        padding-bottom: 0px;
        font-size: 139%;
        color: #FF6838;
        padding: 3px;
        margin-top: 8px;
    }
    .cancelaccountpopupProfile {
        width: 100%;
        background-color: #b8b3b3!important;
        margin-left: 70px;
    }
    .deleteaccountpopupProfile {
        width: 100%;
        background-color: #ff4500!important;
        color: white!important;
        margin-left: 70px;
    }
    .DeleteAccountText {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        margin-left: 150px;
    }
}