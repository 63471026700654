/* .open_hours_badge,
.break_hours_badge {
  border-radius: 1px;
  width: 159px;
  font-size: 9px;
  color: black;
  background-color: white !important;
  opacity: 100%;
  text-align: left;
} */
.deliveryTimeSearch {
  height: 17px;
  border-radius: 1px;
  /* width: 137% !important; */
  font-size: 53% !important;
  color: black;
  background-color: white !important;
  /* border: 0.3px solid #ff9e81; */
  margin-left: -9px;
  margin-top: 6px;
  text-align: left;
}
/* .RestCloseDiv{
    margin-top: -5%;
} */
/* .delivery_time {
  height: 2vh;
  border-radius: 1px;
  width: 6.5vw;
  font-size: 0.55em;
  color: black;
  background-color: white !important;
  border: 0.3px solid #ff9e81;
  margin-left: -14%;
  margin-top: 7%;
} */
.view_more {
  width: 12% !important;
  border-radius: 25px;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%) !important;
}

.card {
  border-radius: 30px;
  grid-column-gap: 50px;
  column-gap: 2rem;
}

.distance_button {
  height: 35px;
  border-radius: 1px;
  width: 147px;
  font-size: 11px;
}

.minimum_value {
  position: absolute;
  top: 40%;
  left: 72%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  background-color: orangered !important;
  font-size: 11px;
  border: none;
  cursor: pointer;
  border-radius: 1px;
}

.delivery_by {
  position: absolute;
  top: 57%;
  left: 62%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white !important;
  color: black;
  background-color: white;
  font-size: 12px;
  border: 1px solid orange;
  cursor: pointer;
  border-radius: 1px;
}

.view_more {
  /* background-color: #ffc3b1!important; */
  color: white !important;
  border: 0px solid white !important;
  width: 14vw;
  font-size: 1.6vw;
  text-align: center;
}

.view_more.btn:hover {
  border: 0px !important;
}

.view_more.btn:focus,
.view_more.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.heart {
  border: 2px solid black;
  border-radius: 25px;
  color: orangered;
}

.DeliveryHandledByAllRestuarant {
  position: absolute;
  top: 52%;
  left: 68%;
  width: 12vw;
  background: white !important;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: black;
  background-color: white !important;
  font-size: 65%;
  border: none;
  cursor: pointer;
  border-radius: 1px;
}

.minimum_valueAllrestaurant {
  position: absolute;
  top: 43%;
  left: 72%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  background-color: orangered !important;
  font-size: 11px;
  border: none;
  cursor: pointer;
  border-radius: 1px;
}

.AllRestaurantHomepage {
  cursor: pointer;
}

.Restaurent_SubTitle {
  width: 117%;
  overflow: hidden;
  display: inline-block;
  white-space: pre-line;

  /* text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap; */
  color: rgb(44, 42, 42) !important;
}

@media (min-width: 320px) and (max-width: 376px) {
  .SearchButtonSE {
    font-size: 113% !important;
    font-weight: 600 !important;
    height: 41% !important;
    margin-top: 91% !important;
    width: 121% !important;
    margin-left: 64%;
  }
  .minimum_value {
    position: absolute;
    width: 45vw;
    height: 6vw;
    /* font-size: 18vh; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 3.3vw;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -30px;
  }

  .deliveryTimeSearch {
    height: 17px;
    border-radius: 1px;
    /* width: 141% !important; */
    font-size: 53% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: -9px;
    margin-top: 6px;
    text-align: left;
  }
  /* .delivery_time {
    height: 17px;
    border-radius: 1px;
    width: 100% !important;
    font-size: 53% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: -9px;
    margin-top: 6px;
    text-align: left;
  } */
  .DeliveryHandledByAllRestuarant {
    position: absolute;
    top: dashed;
    width: 53vw;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 3.3vw;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -43px !important;
  }
  .minimum_valueAllrestaurant {
    position: absolute;
    width: 45vw;
    height: 6vw;
    /* font-size: 18vh; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 3.3vw;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -34px;
  }
  .view_more {
    color: white !important;
    border: 0px solid white !important;
    width: 138px !important;
    font-size: 6px;
    text-align: center;
    margin-top: 33px;
  }

  .view_more {
    width: 35% !important;
    border-radius: 25px;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%) !important;
  }
}

@media (min-width: 376px) and (max-width: 450px) {
  .minimum_value {
    position: absolute;
    width: 45vw;
    height: 6vw;
    /* font-size: 18vh; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 3.3vw;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -30px;
  }

  .SearchButtonSE {
    font-size: 119%;
    font-weight: 600;
    height: 41%;
    margin-top: 107%;
    width: 123%;
  }
  /* .delivery_time {
    height: 1.5vh;
    border-radius: 1px;
    width: 24.5vw !important;
    font-size: 55% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: 5px;
    margin-top: 7px;
    text-align: left;
    font-weight: 700;
  } */
  .DeliveryHandledByAllRestuarant {
    position: absolute;
    top: dashed;
    width: 53vw;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 3.3vw;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -43px !important;
  }
  .minimum_valueAllrestaurant {
    position: absolute;
    width: 45vw;
    height: 6vw;
    /* font-size: 18vh; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 3.3vw;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -34px;
  }
  .view_more {
    color: white !important;
    border: 0px solid white !important;
    width: 120px;
    font-size: 6px;
    text-align: center;
    margin-top: 33px;
  }

  .view_more {
    width: 35% !important;
    border-radius: 25px;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%) !important;
  }
}

@media (min-width: 450px) and (max-width: 576px) {
  .minimum_value {
    position: absolute;
    width: 45vw;
    height: 6vw;
    /* font-size: 18vh; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 3.3vw;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -30px;
  }

  .SearchButtonSE {
    font-size: 119% !important;
    font-weight: 600 !important;
    height: 41% !important;
    margin-top: 97% !important;
    width: 123% !important;
  }
  /* .delivery_time {
    height: 1.5vh;
    border-radius: 1px;
    width: 6vw;
    font-size: 50%;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: 10px;
    margin-top: 7px;
    text-align: left;
  } */
  .DeliveryHandledByAllRestuarant {
    position: absolute;
    top: dashed;
    width: 53vw;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 3.3vw;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -43px !important;
  }
  .minimum_valueAllrestaurant {
    position: absolute;
    width: 45vw;
    height: 6vw;
    /* font-size: 18vh; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 3.3vw;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 10px;
    margin-top: -34px;
  }
  .view_more {
    color: white !important;
    border: 0px solid white !important;
    width: 120px;
    font-size: 6px;
    text-align: center;
    margin-top: 33px;
  }
  .ms-5 {
    margin-left: 0rem !important;
  }
  .view_more {
    width: 35% !important;
    border-radius: 25px;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%) !important;
  }
}

@media (min-width: 576px) and (max-width: 796px) {
  .SearchButtonSE {
    font-size: 119% !important;
    font-weight: 600 !important;
    height: 31% !important;
    margin-top: 97% !important;
    width: 107% !important;
  }
  .minimum_value {
    position: absolute;
    top: 27%;
    left: 71%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 11px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  }
  /* .delivery_time {
        width: 15vw!important;
        height: 17px!important;
        top: 50%;
        left: 67%;
        padding-bottom: 11px;
        text-align: left;
        margin-left: 10px;
        margin-top: 10px;
        font-size: 9px!important;
    } */
  .DeliveryHandledByAllRestuarant {
    position: absolute;
    top: dashed;
    width: 170px;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 10px;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: -10px;
    margin-top: -69px !important;
  }
  .minimum_valueAllrestaurant {
    position: absolute;
    width: 140px;
    height: 2.5vw;
    /* font-size: 18vh; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 10px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: -3px;
    margin-top: -57px;
  }
  .view_more {
    border: 0px solid white !important;
    width: 36vw !important;
    font-size: 1.6vw;
    text-align: center;
    margin-top: 33px;
  }

  /* .open_hours_badge,
  .break_hours_badge {
    width: 19.1vw;
    height: 4vw!important;
    font-size: 1.5vw;
    bottom: 599%;
    text-align: left;
  } */
  .view_more {
    width: 35% !important;
    border-radius: 25px;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%) !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .minimum_value {
    position: absolute;
    top: 109px;
    left: 139px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 11px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  }
  /* .delivery_time {
    width: 15vw !important;
    height: 17px !important;
    top: 50%;
    left: 67%;
    padding-bottom: 11px;
    text-align: left;
    margin-left: 10px;
    margin-top: 10px;
    font-size: 53% !important;
  } */
  .DeliveryHandledByAllRestuarant {
    position: absolute;
    top: dashed;
    width: 170px;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 10px;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: -10px;
    margin-top: -69px !important;
  }
  .DeliveryHandledByAllRestuarant {
    position: absolute;
    top: 202px;
    left: 124px;
    width: 190px;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 70%;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  }
  .minimum_valueAllrestaurant {
    position: absolute;
    width: 140px;
    height: 19px;
    /* font-size: 18vh; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 10px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: -13px;
    margin-top: -57px;
  }
  .view_more {
    color: white !important;
    border: 0px solid white !important;
    width: 146px !important;
    font-size: 2px !important;
    text-align: center;
    margin-top: 33px;
  }
  /* .open_hours_badge,
  .break_hours_badge {
    width: 23.1vw !important;
    height: 3vw !important;
    font-size: 1.5vw !important;
    bottom: 50%;
    left: 48.1%;
    text-align: left;
  } */
  /* .open_hours_badge,
  .break_hours_badge {
    width: 129px !important;
    height: 19px !important;
    font-size: 52% !important;
    bottom: 599%;
    text-align: left;
  } */
  .view_more {
    width: 35% !important;
    border-radius: 25px;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%) !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .minimum_value {
    position: absolute;
    top: 109px;
    left: 139px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 11px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  }
  /* .delivery_time {
    width: 94% !important;
    height: 25px !important;
    top: 50%;
    left: 67%;
    padding-bottom: 11px;
    text-align: left;
    margin-left: 34% !important;
    margin-top: 12px;
    font-size: 10px !important;
  } */
  .DeliveryHandledByAllRestuarant {
    position: absolute;
    top: dashed;
    width: 170px;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 10px;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: -10px;
    margin-top: -69px !important;
  }
  .DeliveryHandledByAllRestuarant {
    position: absolute;
    top: 202px;
    left: 124px;
    width: 190px;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 70%;
    border: none;
    cursor: pointer;
    border-radius: 1px;
  }
  .minimum_valueAllrestaurant {
    position: absolute;
    width: 140px;
    height: 19px;
    /* font-size: 18vh; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 10px;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: -13px;
    margin-top: -57px;
  }
  .view_more {
    color: white !important;
    border: 0px solid white !important;
    width: 146px !important;
    font-size: 2px !important;
    text-align: center;
    margin-top: 33px;
  }
  /* .open_hours_badge,
  .break_hours_badge {
    width: 23.1vw !important;
    height: 3vw !important;
    font-size: 1.5vw !important;
    bottom: 50%;
    left: 48.1%;
    text-align: left;
  }

  .open_hours_badge,
  .break_hours_badge {
    width: 75% !important;
    height: 24px !important;
    font-size: 11px !important;
    bottom: 599%;
    text-align: left;
    margin-top: 5%;
  } */
}

/* @media screen and (min-width: 1200px) and (max-width: 1440px) {
  .delivery_time {
    width: 101px !important;
    height: 19px !important;
    top: 51%;
    left: -40%;
    padding-bottom: 11px;
    text-align: left;
    margin-left: -9px !important;
    margin-top: 8%;
    font-size: 10px !important;
  }
} */
/* .TrendingCardHomepage {
  min-height: 370px;
} */
/* .card .card-block {max-height:300px;overflow:auto;} */
