.ProfileCard {
  border-radius: 0px;
  cursor: pointer;
}
.ItemRestNameReviewDiv {
  width: 60%;
}
.active {
  color: orangered !important;
}

.order {
  text-decoration-color: orangered;
  color: orangered;
  cursor: pointer;
}

.dropdown-toggle.btn {
  height: 24px !important;
  width: 27px;
  text-align: center;
  position: absolute;
  border-radius: 25px;
}

.EmailIdProfilePage {
  text-align: center;
  cursor: default !important;
}

.UserNameProfilePage {
  text-align: center;
}

.nullOrder {
  cursor: pointer;
}

.profile-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 2% auto;
  justify-content: space-between;
}

.profile-left-orders {
  margin-top: 10%;
}

.profile-items {
  display: flex;
  flex-direction: row;
}

.profile-left-container {
  width: 20%;
  border: 1px solid #ddd;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}

.profile-left-container div {
  border-bottom: 1px solid #ddd;
  width: 80%;
  margin: 10% auto;
  display: inherit;
  /* padding-bottom: 5%; */
  cursor: pointer;
}

.profile-items p {
  margin: auto 10%;
  display: inline;
}

.profile-right-container {
  width: 81%;
  border: 0px solid #ddd;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
  /* margin-top: 11%; */
  margin-left: 3%;
  margin-right: -4%;
}

.user-detail {
  border: 0 !important;
  margin: 2% auto !important;
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
}

.email-data {
  border: 0 !important;
  margin: 2% auto !important;
  text-align: center;
}

.profile-heading-container {
  display: flex;
  flex-direction: row;
  margin: 5% auto 0 auto;
}

.ProfileHeaderContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: white;
  width: 100%;
}

.LogOutButtonText {
  text-align: center;
  margin-top: 4%;
  margin-bottom: 4%;
  /* font-size: 1.2em; */
  font-weight: 450;
}

.profile-heading-titles {
  width: 25%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  font-weight: bold;
  font-size: 1.5vw;
  cursor: pointer;
  margin-right: 26%;
  margin-bottom: -2.5%;
}

.profile-icon {
  width: 60%;
  margin: auto;
  display: flex;
}

.profile-left-container-mobile {
  display: none;
}

.profile-heading-container-mobile {
  display: none;
}

@media (max-width: 769px) {
  .profile-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 1% auto;
    justify-content: space-between;
  }
  .profile-left-container {
    width: 30%;
    border: 1px solid #ddd;
  }
  .profile-heading-titles {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 4.5vw;
    cursor: pointer;
  }
  .profile-right-container {
    width: 100%;
    border: 1px solid #ddd;
  }
}

@media (min-width: 769px) and (max-width: 1600px) {
  .profile-heading-titles {
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    margin-right: 16%;
    margin-top: 100px !important;
    margin-bottom: 0.5%;
  }
}

@media (min-width: 320px) and (max-width: 376px) {
  .profile-left-container {
    display: none;
  }
  .profile-heading-container {
    display: none;
  }
  .profile-left-container-mobile {
    display: contents;
  }
  .css-g880av-MuiButtonBase-root-MuiIconButton-root {
    border-radius: 0 !important;
    margin-top: 80px !important;
    /* background-color: blue!important; */
    width: auto;
  }
  .profile-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1% auto;
    justify-content: space-between;
  }
  .profile-items {
    display: flex;
    flex-direction: row;
    padding: 3%;
    border: 1px solid #ddd;
  }
  .profile-heading-container-mobile {
    display: contents;
    display: flex;
    flex-direction: row;
    margin: 3% 0;
    background-color: bisque;
  }
  /* .LogOutbutton_profile {
        width: 70%;
        background-color: #FF6838!important;
        border: 1px solid black!important;
        border-radius: 25px;
        text-align: center;
        z-index: 1000;
        position: relative;
        margin-left: 205px!important;
        margin-top: 8px!important;
    } */
  .LogOutButtonText {
    text-align: center;
    margin-top: 4%;
    margin-bottom: 4%;
    font-size: 50% !important;
    font-weight: 600;
    color: white;
  }
  .profile-icon {
    width: 65px;
    margin: auto;
    display: flex;
    height: 50px;
  }
  .user-detail {
    border: 0 !important;
    margin: 2% auto !important;
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
  }
  .email-data {
    border: 0 !important;
    margin: 2% auto !important;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  .profile-heading-titles {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    margin-top: 20px;
  }
  .profile-right-container {
    width: 97%;
    border: 1px solid #ddd;
    margin-left: 6px;
  }
  .LogoutButtonProfile {
    border-radius: 30px;
    font-weight: 500;
    height: 34px;
    margin-top: 1.5%;
    width: 42%;
    font-size: 118%;
  }
}

@media (min-width: 376px) and (max-width: 450px) {
  .profile-left-container {
    display: none;
  }
  .profile-heading-container {
    display: none;
  }
  .profile-left-container-mobile {
    display: contents;
  }
  .css-g880av-MuiButtonBase-root-MuiIconButton-root {
    border-radius: 0 !important;
    margin-top: 80px !important;
    /* background-color: blue!important; */
    width: auto;
  }
  .profile-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1% auto;
    justify-content: space-between;
  }
  .profile-items {
    display: flex;
    flex-direction: row;
    padding: 3%;
    border: 1px solid #ddd;
  }
  .profile-heading-container-mobile {
    display: contents;
    display: flex;
    flex-direction: row;
    margin: 3% 0;
    background-color: bisque;
  }
  /* .LogOutbutton_profile {
        width: 58%;
        background-color: #FF6838!important;
        border: 1px solid black!important;
        border-radius: 25px;
        text-align: center;
        z-index: 1000;
        position: relative;
        margin-left: 250px!important;
        margin-top: 8px!important;
    } */

  .LogOutButtonText {
    font-size: 67% !important;
    color: black;
    margin-left: 0 !important;
  }

  .profile-icon {
    width: 65px;
    margin: auto;
    display: flex;
    height: 50px;
  }
  .user-detail {
    border: 0 !important;
    margin: 2% auto !important;
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
  }
  .email-data {
    border: 0 !important;
    margin: 2% auto !important;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  .profile-heading-titles {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    margin-top: 20px;
  }
  .profile-right-container {
    width: 97%;
    border: 1px solid #ddd;
    margin-left: 6px;
  }
}

@media (min-width: 450px) and (max-width: 576px) {
  .profile-left-container {
    display: none;
  }
  .profile-heading-container {
    display: none;
  }
  .profile-left-container-mobile {
    display: contents;
  }
  .css-g880av-MuiButtonBase-root-MuiIconButton-root {
    border-radius: 0 !important;
    margin-top: 80px !important;
    /* background-color: blue!important; */
    width: auto;
  }
  .profile-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1% auto;
    justify-content: space-between;
  }
  .profile-items {
    display: flex;
    flex-direction: row;
    padding: 3%;
    border: 1px solid #ddd;
  }
  .profile-heading-container-mobile {
    display: contents;
    display: flex;
    flex-direction: row;
    margin: 3% 0;
    background-color: bisque;
  }
  /* .LogOutbutton_profile {
        width: 63%;
        background-color: #FF6838!important;
        border: 1px solid black!important;
        border-radius: 25px;
        text-align: center;
        z-index: 1000;
        position: relative;
        margin-left: 236px!important;
        margin-top: 8px!important;
    }
    .LogOutButtonText {
        text-align: center;
        margin-top: 4%;
        margin-bottom: 4%;
        font-size: 10px!important;
        font-weight: 600;
        color: white;
    } */
  .LogOutButtonText {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 0%;
    margin-left: -28%;
    font-size: 77% !important;
    font-weight: 450;
  }
  .profile-icon {
    width: 65px;
    margin: auto;
    display: flex;
    height: 50px;
  }
  .user-detail {
    border: 0 !important;
    margin: 2% auto !important;
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
  }
  .email-data {
    border: 0 !important;
    margin: 2% auto !important;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  .profile-heading-titles {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    margin-top: 20px;
  }
  .profile-right-container {
    width: 97%;
    border: 1px solid #ddd;
    margin-left: 6px;
  }
}

@media screen and (min-width: 576px) and (max-width: 769px) {
  .profile-left-container {
    display: none;
  }
  .profile-heading-container {
    display: none;
  }
  .profile-left-container-mobile {
    display: contents;
  }
  .css-g880av-MuiButtonBase-root-MuiIconButton-root {
    border-radius: 0 !important;
    margin-top: 80px !important;
    /* background-color: blue!important; */
    width: auto;
  }
  .profile-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1% auto;
    justify-content: space-between;
  }
  .profile-items {
    display: flex;
    flex-direction: row;
    padding: 3%;
    border: 1px solid #ddd;
  }
  .profile-heading-container-mobile {
    display: contents;
    display: flex;
    flex-direction: row;
    margin: 3% 0;
    background-color: bisque;
  }
  .LogOutbutton_profile {
    width: 125%;
    /* background-color: #FF6838!important; */
    border: 1px solid black !important;
    border-radius: 25px;
    text-align: center;
    margin-left: 320px !important;
  }
  .LogOutButtonText {
    text-align: center;
    margin-top: 4%;
    margin-bottom: 4%;
    font-size: 12px !important;
    font-weight: 450;
  }
  .profile-icon {
    width: 65px;
    margin: auto;
    display: flex;
    height: 50px;
  }
  .user-detail {
    border: 0 !important;
    margin: 2% auto !important;
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
  }
  .email-data {
    border: 0 !important;
    margin: 2% auto !important;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  .profile-heading-titles {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    margin-top: 20px;
  }
  .profile-right-container {
    width: 97%;
    border: 1px solid #ddd;
    margin-left: 6px;
  }
}

@media screen and (max-width: 769px) {
  .css-eux48v {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    padding: 8px;
    border-radius: 50%;
    overflow: visible;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-top: 100px;
    width: auto;
    margin-top: 70px !important;
  }
}
