.cartitem_card {
  border-radius: 1px !important;
  max-height: 90%;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
  /* overflow-x: hidden; */
}
@media only screen and (min-width: 320px) and (max-width: 576px) {
  .loadingTextLoader {
    position: relative !important;
    top: -13% !important;
    margin-top: 44% !important;
    left: 26% !important;
    font-size: 120% !important;
    font-weight: 400 !important;
  }
}
.VegOnlyDivProduct {
  width: 15%;
}
.NodataFoundText {
  margin-top: 30%;
  margin-bottom: 14%;
  margin-left: 43%;
  font-size: 115%;
}
.Favourite_Badge_ProductListing {
  position: absolute;
  top: 21%;
  width: 3.5%;
  height: 6%;
  right: 34%;
  font-size: 112%;
  border-radius: 25px;
  cursor: pointer;
  background-color: white !important;
}

.cartItemCardSpace {
  margin-top: 10%;
}

.delivery_Badge1,
.delivery_Badge2 {
  width: 100%;
  background-color: #ff6838 !important;
  font-size: 1vw;
  text-align: center;
  font-weight: 500;
  padding: 3% 0;
  height: -webkit-fill-available;
  white-space: pre-line;
}

.Ratings_verticalLine {
  height: 75%;
  border-left: 2px solid #000900;
  font-weight: 600;
}

.shopTiming_verticalLine {
  height: 68%;
  border-left: 2px solid #000900;
}

.ItemBanner {
  width: 100%;
  height: 30vw;
  margin-top: 1%;
  object-fit: cover;
}

.Rating_out_of {
  font-size: 0.875rem;
  font-weight: bold;
}

.abt-res {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-evenly;
}

.res-name-cont {
  display: flex;
  flex-direction: row;
  width: 92%;
  margin: 4%;
  justify-content: space-between;
}

.Rating_Count {
  font-weight: 500;
  font-size: 110%;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.open_Hours {
  font-weight: bold;
  font-size: 0.875rem;
}

.delivery_Time {
  font-weight: bold;
  font-size: 0.875rem;
}

.kilometer_verticalLine {
  height: 100%;
  border-left: 2px solid #000900;
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0 10px;
}
/* .rating-res{
    cursor: pointer;

} */
.loadingTextLoader {
  position: relative;
  top: -23%;
  margin-top: 31%;
  left: 50%;
  font-size: 120%;
  font-weight: 400;
}
.css-bvz20t {
  width: 100% !important;
}

.rating-res small {
  margin: 0 4px;
  width: 100%;
  cursor: pointer;
}

.Rating_Count {
  font-size: 0.875rem;
  font-weight: bold;
}

.Delivery_Time {
  font-weight: bold;
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #ff6838 !important;
}

.VegOnlyRestaurant {
  font-weight: 700;
  font-size: 1.1vw;
}

.css-jpln7h-MuiTabs-scroller {
  overflow: auto !important;
  max-width: 96% !important;
}

.badge-res {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 2% 0;
}

.shopTimingText {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.badge-cont-res {
  width: 40%;
}

@media screen and (max-width: 576px) {
  .ItemBanner {
    width: 100%;
    height: 40vw;
  }
  .VegOnlyRestaurant {
    font-weight: 700;
    font-size: 11px;
    margin-right: -56px;
  }
  .rating-res small {
    margin: 0 4px;
  }
  .delivery_Badge1,
  .delivery_Badge2 {
    width: 115%;
    background-color: #ff6838 !important;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    padding: 4% 4%;
    margin-left: -13px;
    margin-bottom: 8px;
  }
  .res-name-cont {
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: 4%;
    justify-content: space-between;
  }
  .open_Hours {
    font-weight: 700;
    font-size: 13px;
    text-align: center;
  }
  .rating-res small {
    margin: 0 4px;
  }
  .Rating_out_of {
    font-weight: 700;
    font-size: 20px;
  }
  .kilometer_verticalLine {
    height: 100%;
    border-left: 2px solid #000900;
    font-size: 13px;
    margin: 0 10px;
  }
  .abt-res {
    display: flex;
    width: 102%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-around;
    margin-bottom: 20px;
  }
  .delivery_Time {
    font-weight: 700;
    font-size: 14px;
  }
  .Delivery_Time {
    font-weight: 500;
    font-size: medium;
    text-align: center;
  }
  .address-size {
    font-size: 11px;
  }
  .Rating_Count {
    font-weight: 500;
    font-size: 14px;
  }
  .badge-cont-res {
    width: 70%;
  }
  .badge-res {
    display: flex;
    flex-direction: column;
    margin: 2% 0px 0px 0px;
    flex-wrap: wrap;
    align-content: center;
  }
}

@media screen and (min-width: 700px) and (max-width: 769px) {
  .rating-res small {
    margin: 0 4px;
    margin-left: 10px;
  }
}

@media screen and (min-width: 576px) and (max-width: 796px) {
  .abt-res {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  .delivery_Badge1,
  .delivery_Badge2 {
    width: 114%;
    background-color: #ff6838 !important;
    font-size: 10px;
    text-align: center;
    font-weight: 700;
    padding: 4% 4%;
    margin-left: -13px; 
  }
  .VegOnlyRestaurant {
    font-weight: 700;
    font-size: 15px;
    margin-right: -56px;
  }
}

@media screen and (min-width: 796px) and (max-width: 992px) {
  .abt-res {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  .delivery_Badge1,
  .delivery_Badge2 {
    width: 101%;
    background-color: #ff6838 !important;
    font-size: 73%;
    text-align: center;
    font-weight: 700;
    padding: 3% 3%;
    margin-left: 3%;
  }
  .VegOnlyRestaurant {
    font-weight: 700;
    font-size: 64%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .abt-res {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  .delivery_Badge1,
  .delivery_Badge2 {
    width: 100%;
    background-color: #ff6838 !important;
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    padding: 4% 4%;
    margin-left: -13px;
  }
  .VegOnlyRestaurant {
    font-weight: 700;
    font-size: 73%;
  }
}

@media (max-width: 900px) {
  .delivery_Badge1,
  .delivery_Badge2 {
    max-height: 50px;
  }
}
