.Modal_Title {
  font-weight: 600;
  font-size: x-large;
}
.noOfPeopleError {
  color: red;
  margin-left: 11%;
  font-size: 116%;
}
.activeDateDineIn {
  color: orangered !important;
  background-color: white !important;
  width: 92%;
  border: 1px solid black !important;
  font-size: 98%;
}
.dateDinePopup {
  margin-top: -2% !important;
}
.Dinein_Address_Change {
  font-weight: 500;
}

.Date_of_Dinein {
  font-weight: 500;
  font-size: large;
}

.today_dinein {
  width: 100%;
  background-color: #ff6838 !important;
  color: white !important;
}

.tomorrow_dinein {
  width: 100%;
  background-color: #ff6838 !important;
  color: white !important;
}

.slot_dinein {
  width: 100%;
  background-color: #ddd !important;
  color: black !important;
}

.book-btn {
  width: 56%;
  background-color: #ff6838 !important;
  color: white !important;
}

.people_count {
  font-weight: 600;
}

.dinein_count {
  border: 2px solid black;
  font-weight: 600;
  font-size: large;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.people_count_change_dinein {
  font-weight: 600;
  font-size: larger;
}

.add_people_count {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px !important;
}

.dinein_Time {
  font-weight: 700;
  font-size: larger;
}

.dinein_slot1_time,
.dinein_slot2_time,
.dinein_slot3_time {
  width: 120%;
}

.book_dinein {
  width: 120%;
}

.changeDineInProfile {
  position: absolute;
  top: 3%;
  right: 1%;
}

.buttonCalander {
  width: 100%;
  height: 120%;
}

/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 2px !important;
    color: white !important;
} */

.mt-3 {
  margin-top: 1rem !important;
  margin-left: 6% !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 85%;
}

.time_container {
  margin-top: 5%;
}

.activePersonTabValue {
  background-color: #ff6838 !important;
  color: white !important;
}

.activeDateTabValue {
  color: #ff6838 !important;
  background-color: white !important;
  border: 1px solid black !important;
}

.dinein-title {
  text-align: center;
}

.date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2% auto;
  width: 90%;
}

.time-slot-btn-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: auto;
  width: 80%;
  justify-content: space-between;
}

.time-slot-data {
  width: 100%;
  display: flex;
  margin: 5%;
}

.book-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2% auto;
  width: 90%;
}

.today-tmr {
  width: 30%;
}

.all-date {
  width: 35%;
  height: 10%;
}

.date-header {
  width: 85%;
  margin: 2% auto;
}

/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    width: 79%!important;
} */

.count-person {
  text-align: center;
  width: 90%;
  margin: 2% auto;
}

.count-display {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.count-display div {
  display: flex;
  width: 6%;
  flex-direction: row;
}

/* @media screen and (max-width: 992px) {
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        background-color: #FF6838ed;
        width: 150px;
    }
} */

@media (max-width: 800px) {
  .time-slot-btn-container {
    width: 90%;
  }

  .date-header {
    width: 90%;
  }
}

@media (min-width: 320px) and (max-width: 376px) {
  .tomorrow_dinein {
    /* width: 93%; */
    background-color: #ff6838 !important;
    color: white !important;
    /* margin-left: -21px;
        font-size: 11px; */
  }
  .count-display div {
    display: flex;
    width: 8%;
    flex-direction: row;
  }
  .today_dinein {
    width: 90%;
    background-color: #ff6838 !important;
    color: white !important;
    font-size: 96%;
  }

  .time-slot-btn-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: auto;
    width: 90%;
    justify-content: space-between;
  }
  .book-btn {
    width: 30%;
    background-color: #ff6838 !important;
    color: white !important;
    margin-top: 13px;
  }
  .date {
    height: 35px;
    margin-top: -5%;
    width: 126%;
  }
  .date2 {
    margin-left: -9% !important;
  }
}

@media (min-width: 376px) and (max-width: 450px) {
  .activeDateDineIn {
    width: 93%;
    margin-top: 10%;
    font-size: 77%;
    margin-left: -15%;
  }

  .tomorrow_dinein {
    width: 93%;
    background-color: #ff6838 !important;
    color: white !important;
    margin-left: -21px;
    margin-top: 10px;
    font-size: 13px;
  }
  .count-display div {
    display: flex;
    width: 8%;
    flex-direction: row;
  }
  .today_dinein {
    width: 79%;
    background-color: #ff6838 !important;
    color: white !important;
    margin-left: 4px !important;
    margin: 10px;
    font-size: 13px;
  }
  /* .Favourite_Badge_ProductListing {
    right: 7% !important;
    top: 18% !important;
    width: 9.5% !important;
    height: 6% !important;
  } */
  .time-slot-btn-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: auto;
    width: 90%;
    justify-content: space-between;
  }
  .book-btn {
    width: 30%;
    background-color: #ff6838 !important;
    color: white !important;
    margin-top: 13px;
  }
  .date {
    height: 35px;
    margin-left: -20px !important;
    margin-top: 3px;
    width: 137%;
  }
  .date2 {
    margin-left: -26px !important;
    font-size: 14px;
  }
}

@media (min-width: 450px) and (max-width: 576px) {
  .tomorrow_dinein {
    width: 135%;
    background-color: #ff6838 !important;
    color: white !important;
  }
  .count-display div {
    display: flex;
    width: 8%;
    flex-direction: row;
  }
  .today_dinein {
    width: 75%;
    background-color: #ff6838 !important;
    color: white !important;
    margin-left: 4px !important;
    margin: 10px;
    font-size: 14px;
  }
  .tomorrow_dinein {
    width: 96%;
    background-color: #ff6838 !important;
    color: white !important;
    margin-left: -21px;
    margin-top: 10px;
    font-size: 15px;
  }
  .date {
    margin-top: 7%;
    width: 122%;
  }

  .date2 {
    margin-left: -26px !important;
    font-size: 14px;
  }
  .mt-3 {
    margin-top: 1rem !important;
    margin-left: 6% !important;
    width: 70%;
  }
}

@media (min-width: 576px) and (max-width: 769px) {
  .tomorrow_dinein {
    width: 135%;
    background-color: #ff6838 !important;
    color: white !important;
  }
  .count-display div {
    display: flex;
    width: 8%;
    flex-direction: row;
  }
  .today_dinein {
    width: 75%;
    background-color: #ff6838 !important;
    color: white !important;
    margin-left: 4px !important;
    margin: 10px;
    font-size: 14px;
  }
  .tomorrow_dinein {
    width: 96%;
    background-color: #ff6838 !important;
    color: white !important;
    margin-left: -21px;
    margin-top: 10px;
    font-size: 15px;
  }
  .date {
    margin-left: -20px !important;
    width: 175px;
    height: 35px;
    margin-top: 8px;
  }
  .date2 {
    margin-left: -26px !important;
    font-size: 14px;
  }
  .mt-3 {
    margin-top: 1rem !important;
    margin-left: 6% !important;
    width: 90%;
  }
}

@media (max-width: 600px) {
  .date-container {
    flex-wrap: wrap;
  }

  .today-tmr {
    width: 50%;
  }

  .today-tmr button {
    width: 100%;
    margin: 0 !important;
  }

  .all-date {
    width: 100%;
    height: auto;
  }

  .all-date .date {
    margin-left: unset;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .all-date .MuiFormControl-root {
    width: 100%;
  }

  .all-date .date {
    width: 100% !important;
    margin-left: auto !important;
  }
}
