.guestLogin_card {
  border-radius: 0px;
  margin-top: 11%;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}
.res-image-containerPayment {
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: white;
  width: 66%;
}
.res-adress-containerPayment {
  width: 64%;
  display: flex;
  padding: 1%;
}
.res-image-containerPayment {
  width: 67%;
  padding: 1%;
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: white;
}

.emptyCartDiv {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 99;
}

.emptyCartDiv .eptyInrDiv {
  text-align: center;
  padding: 20px;
}

.emptyCartDiv .eptyInrDiv img {
  max-width: 350px;
  width: 100%;
  margin-bottom: 35px;
}

.emptyCartDiv .eptyInrDiv h4 {
  margin-bottom: 20px;
  font-size: 25px;
  color: rgb(66, 66, 66);
}

.emptyCartDiv .eptyInrDiv p {
  color: gray;
}

.emptyCartDiv .eptyInrDiv button {
  padding: 15px 30px;
  margin-top: 35px;
}

@media (min-width: 320px) and (max-width: 376px) {
  .guestLogin_card {
    border-radius: 0px;
    width: 96%;
    margin-left: 10px !important;
    margin-top: 22%;
  }
}

@media (min-width: 376px) and (max-width: 576px) {
  .guestLogin_card {
    border-radius: 0px;
    width: 96%;
    margin-left: 10px !important;
  }
}

@media (min-width: 576px) and (max-width: 796px) {
  .guestLogin_card {
    border-radius: 0px;
    width: 96%;
    margin-left: 10px !important;
  }
}
