.cancelDinein {
  font-weight: 600;
  font-size: larger;
}

.cancelButton {
  width: 100%;
  background-color: rgb(184, 179, 179) !important;
  color: black !important;
}

.okButton {
  width: 100%;
  background-color: orangered !important;
  color: white !important;
}

.cancelDineInButton {
  position: absolute;
  top: 4%;
  right: 2%;
}

@media screen and (max-width: 576px) {
  .cancelButton {
    width: 95%;
    margin-left: 33%;
  }
  .cancelDinein {
    font-weight: 700;
    font-size: larger;
    text-align: center;
    margin-bottom: -20px;
  }
  .okButton {
    width: 95%;
    color: white !important;
    margin-left: 66px;
  
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .cancelButton {
    width: 86%;
    background-color: rgb(184, 179, 179) !important;
    color: black !important;
    margin-left: 50px;
  }
  .cancelDinein {
    font-weight: 700;
    font-size: larger;
    text-align: center;
    margin-bottom: -20px;
  }
  .okButton {
    width: 86%;
    background-color: orangered !important;
    color: white !important;
    margin-left: 66px;
  }
}
