.scroll-to-top {
  border-radius: 25px !important;
  background-color: orangered !important;
  width: 56px !important;
  height: 47px !important;
}

.ProductHelp-Left-restpage {
  margin-left: -13%;
}

.useful {
  color: #ff6838;
  font-size: 1.4vw;
  font-weight: bold;
  /* font-family: 'Nunito', sans-serif; */
}

.hometextinfooter {
  font-size: 1vw;
  font-weight: bold;
  cursor: pointer;
}
@media screen and (min-width: 800px) and (max-width: 992px) {
  .hometextinfooter {
    font-size: 76% !important;
  }
}
.HippoChatData {
  float: left !important;
}

.one svg {
  width: 22px;
  height: 22px;
  fill: white;
  font-weight: 30px;
}

.desiEats_Description {
  color: black !important;
  text-align: justify !important;
  font-weight: 700 !important;
  font-size: 1vw;
}

.desi-img {
  width: 150px;
  margin-bottom: 10px;
}

.scroll-to-top {
  border-radius: 30px !important;
  background-color: #ff6838 !important;
  right: 20px !important;
  bottom: 100px !important;
}

@media (max-width: 700px) {
  .footer-content {
    width: 100% !important;
  }

  .footer-content .desi-img {
    margin-left: auto;
    margin-right: auto;
  }

  .desiEats_Description {
    width: 100%;
    font-size: 14px;
  }

  .sclMdaIcon img {
    width: 25px;
    margin-left: 0;
    margin-right: 16px;
  }

  .footer-content.extra {
    margin-left: 0;
  }

  .footer-content .apStreIcon {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .footer-content .apStreIcon .media_icon,
  .footer-content .apStreIcon .AppStoreIcon {
    width: 100%;
    height: auto;
    margin: unset !important;
  }

  .cpyRtCntnr {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
  }
}

@media (max-width: 600px) {
  .footer-content .apStreIcon {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 769px) {
  .badge123 {
    right: 106%;
    top: 15%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 376px) {
  .footerSection {
    margin-left: 2% !important;
    margin-right: 2% !important;
    margin-top: 9% !important;
  }
  .desiEats_Description {
    color: black !important;
    text-align: justify !important;
    font-weight: 700 !important;
    font-size: 12px;
    /* width: 200px; */
  }
  .footer-container {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    justify-content: center;
    /* align-items: baseline; */
  }
  .footer-content {
    width: 100% !important;
    font-size: 10px;
  }
  .scroll-to-top {
    border-radius: 79px !important;
    width: 13% !important;
    height: 7% !important;
  }
  .scroll-to-top {
    /* border-radius: 30px!important;
        background-color: #FF6838!important; */
    right: 20px !important;
    bottom: 116px !important;
  }
  .footer-container {
    display: flex;
    flex-direction: row;
  }
  .desi-img {
    width: 150px;
  }
  .useful {
    color: #ff6838;
    font-size: 18px;
    font-weight: bold;
    /* font-family: 'Nunito', sans-serif; */
    margin-top: 20px !important;
  }
  .hometextinfooter {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  .download-text {
    margin-left: 0% !important;
  }
}

@media (min-width: 376px) and (max-width: 450px) {
  .footerSection {
    margin-left: 2% !important;
    margin-right: 2% !important;
    margin-top: 9% !important;
  }

  .footer-container {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    justify-content: center;
    /* align-items: baseline; */
  }
  .footer-content {
    width: 100% !important;
    font-size: 10px;
  }
  .scroll-to-top {
    border-radius: 79px !important;
    background-color: orangered !important;
    width: 55px !important;
    height: 55px !important;
  }
  .scroll-to-top {
    /* border-radius: 30px!important;
        background-color: #FF6838!important; */
    right: 20px !important;
    bottom: 116px !important;
  }
  .footer-container {
    display: flex;
    flex-direction: row;
  }
  .desi-img {
    width: 150px;
  }
  .useful {
    color: #ff6838;
    font-size: 18px;
    font-weight: bold;
    /* font-family: 'Nunito', sans-serif; */
    margin-top: 20px !important;
  }
  .hometextinfooter {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  .download-text {
    margin-left: 0% !important;
  }
}

@media (min-width: 451px) and (max-width: 576px) {
  .footerSection {
    margin-left: 2% !important;
    margin-right: 2% !important;
    margin-top: 9% !important;
  }
  .desiEats_Description {
    color: black !important;
    text-align: justify !important;
    font-weight: 700 !important;
    font-size: 12px;
    width: 200px;
  }
  .footer-container {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    justify-content: center;
    align-items: baseline;
  }
  .footer-content {
    width: 55% !important;
    font-size: 10px;
  }
  .scroll-to-top {
    border-radius: 79px !important;
    background-color: orangered !important;
    width: 55px !important;
    height: 55px !important;
  }
  .scroll-to-top {
    /* border-radius: 30px!important;
        background-color: #FF6838!important; */
    right: 20px !important;
    bottom: 116px !important;
  }
  .footer-container {
    display: flex;
    flex-direction: row;
  }
  .desi-img {
    width: 150px;
  }
  .useful {
    color: #ff6838;
    font-size: 18px;
    font-weight: bold;
    /* font-family: 'Nunito', sans-serif; */
    margin-top: 20px !important;
  }
  .hometextinfooter {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  .download-text {
    margin-left: 0% !important;
  }
}

@media (min-width: 567px) and (max-width: 769px) {
  .footerSection {
    margin-left: 2% !important;
    margin-right: 2% !important;
    margin-top: 9% !important;
  }
  .desiEats_Description {
    color: black !important;
    text-align: justify !important;
    font-weight: 700 !important;
    font-size: 12px;
    width: 200px;
  }
  .footer-container {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    justify-content: center;
    align-items: baseline;
  }
  .footer-content {
    width: 55% !important;
    font-size: 10px;
  }
  .scroll-to-top {
    border-radius: 79px !important;
    background-color: orangered !important;
    width: 55px !important;
    height: 55px !important;
  }
  .scroll-to-top {
    /* border-radius: 30px!important;
        background-color: #FF6838!important; */
    right: 20px !important;
    bottom: 116px !important;
  }
  .footer-container {
    display: flex;
    flex-direction: row;
  }
  .desi-img {
    /* margin-left: -21%; */
    width: 150px;
  }
  .useful {
    color: #ff6838;
    font-size: 18px;
    font-weight: bold;
    /* font-family: 'Nunito', sans-serif; */
    margin-top: 20px !important;
  }
  .hometextinfooter {
    font-size: 101%;
    font-weight: bold;
    cursor: pointer;
  }
  .download-text {
    margin-left: 0% !important;
  }
}
@media (min-width: 799px) and (max-width: 948px) {
  .ProductHelp-Left-restpage {
    margin-left: -18.2%;
  }
}
@media (min-width: 949px) and (max-width: 1200px) {
  .ProductHelp-Left-restpage {
    margin-left: -11%;
  }
}
@media (min-width: 770px) and (max-width: 799px) {
}
@media (min-width: 769px) and (max-width: 799px) {
  .footerSection {
    margin-left: 2% !important;
    margin-right: 2% !important;
    margin-top: 9% !important;
  }
  .ProductHelp-Left-restpage {
    margin-left: 2% !important;
    margin-right: -4%;
  }
  .desiEats_Description {
    color: black !important;
    text-align: justify !important;
    font-weight: 700 !important;
    font-size: 12px;
    width: 200px;
  }
  .footer-container {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    justify-content: center;
    align-items: baseline;
  }
  .footer-content {
    width: 55% !important;
    font-size: 10px;
  }
  .scroll-to-top {
    border-radius: 79px !important;
    background-color: orangered !important;
    width: 55px !important;
    height: 55px !important;
  }
  .scroll-to-top {
    /* border-radius: 30px!important;
        background-color: #FF6838!important; */
    right: 20px !important;
    bottom: 116px !important;
  }
  .footer-container {
    display: flex;
    flex-direction: column;
  }
  .desi-img {
    width: 150px;
  }
  .useful {
    color: #ff6838;
    font-size: 18px;
    font-weight: bold;
    /* font-family: 'Nunito', sans-serif; */
    margin-top: 20px !important;
  }
  .hometextinfooter {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  .download-text {
    margin-left: 13% !important;
  }
}

.AppStoreIcon {
  width: 70%;
  cursor: pointer;
  margin-left: 3%;
  margin-top: -11%;
  height: 88px;
}

.media_icon {
  width: 75%;
  cursor: pointer;
  margin-bottom: 0%;
  height: 81px;
  margin-top: -5%;
}

.playStoreAppStoreIcon {
  width: 65%;
  cursor: pointer;
}

.appStoreAppStoreIcon {
  width: 60%;
  margin-top: -10%;
  margin-left: 3%;
  cursor: pointer;
}

.download-text {
  margin-left: 12%;
}

.DownloadOptions {
  margin-left: -3%;
}

.InstagramFooter,
.FaceBookFooter,
.TwitterFooter {
  cursor: pointer;
  margin: 2% 5%;
}

.footer-container {
  display: flex;
  flex-direction: row;
}

.footer-content {
  width: 25%;
  margin-top: 2%;
}

.extra {
  margin-left: 6%;
}

.footer-box {
  width: 96%;
  margin: auto;
}
@media only screen and (min-width: 450px) and (max-width: 576px) {
  .footer-container {
    flex-direction: row !important;
  }
  .desiEats_Description {
    font-size: 10px;
    width: 130%;
  }
  .desi-img {
    width: 87%;
  }
  .footer-content {
    width: 20% !important;
  }
  .useful {
    font-size: 7px;
    margin-left: 40%;
  }
  .hometextinfooter {
    margin-left: 38%;
    font-size: 102%;
  }
  .ProductHelp-Left-restpage {
    margin-left: 5% !important;
  }
}
@media only screen and (min-width: 577px) and (max-width: 769px) {
  .footer-container {
    flex-direction: row !important;
  }
  .footer-content {
    width: 30% !important;
  }
  .ProductHelp-Left-restpage {
    margin-left: 4%;
    margin-right: -20%;
  }
  .desiEats_Description {
    /* margin-left: -20%; */
    font-size: 101%;
    width: 161px;
  }
  .extra {
    margin-left: 6%;
  }
  .useful {
    font-size: 75%;
  }
  .download-text {
    margin-left: 10% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 799px) {
  .footer-container {
    flex-direction: row !important;
  }
  .footer-content {
    width: 29% !important;
  }
  .download-text {
    margin-left: 11% !important;
  }
  .hometextinfooter {
    font-size: 1.5vw;
  }
  .useful {
    font-size: 100%;
  }
}
@media only screen and (min-width: 799px) and (max-width: 948px) {
  .footer-container {
    flex-direction: row !important;
  }

  .footer-content {
    width: 29% !important;
  }

  .AppStoreIcon {
    width: 109%;
    cursor: pointer;
    margin-left: 0%;
    margin-top: -15%;
    height: 67px;
  }
  .media_icon {
    width: 118%;
    cursor: pointer;
    margin-bottom: 0%;
    height: 63px;
    margin-top: 0%;
    margin-left: -3%;
  }
}
@media only screen and (min-width: 577px) and (max-width: 799px) {
  .extra {
    width: 10% !important;
  }

  .media_icon,
  .AppStoreIcon {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 949px) and (max-width: 1200px) {
  .media_icon {
    width: 88%;
    cursor: pointer;
    margin-bottom: 12%;
    height: 66px;
    margin-top: -6%;
    margin-left: -6%;
  }
  .AppStoreIcon {
    width: 84% !important;
    cursor: pointer;
    margin-left: -3%;
    margin-top: -28%;
    height: 71px;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1500px) {
  .media_icon {
    width: 88%;
    cursor: pointer;
    margin-bottom: 0%;
    height: 80px;
    margin-top: -7%;
    margin-left: -10%;
  }
  .AppStoreIcon {
    width: 86%;
    cursor: pointer;
    margin-left: -9%;
    margin-top: -9%;
    height: 85px;
  }
}

@media (max-width: 700px) {
  .footer-box .ProductHelp-Left-restpage {
    margin-left: 0;
  }
}
