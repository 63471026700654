.filterWallethead {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 8% 5% 3% 3%;
    font-weight: 500;
    font-size: 1.2em;
}
.ApplyButtonFilterProfile:disabled{
    background-color: #9e9e9eb5!important;
}
.clearAllWalletText {
    color: #FF6838;
    cursor: pointer;
}

.DateContainerWallet {
    display: flex;
    flex-direction: row;
    width: 92%;
    justify-content: space-between;
    margin: 0% 12% 3% 3%;
}

.FilterButtonProfile {
    display: flex;
    flex-direction: row;
    width: 90%;
    gap: 5%;
    justify-content: end;
}

.CloseButtonFilterProfile {
    width: 40%;
}

.ApplyButtonFilterProfile {
    width: 40%;
}

@media only screen and (min-width:384px) and (max-width:998px) {
    .AddMoneyInput {
        width: 90%;
        font-size: 16px!important;
    }
}