.DineinResaturantHomepage {
    cursor: pointer;
}

@media (min-width:320px) and (max-width:576px) {
    .css-1wjkg3 {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0px;
        border: 0px;
        margin: 0px auto 0px 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-align: center;
        flex: 0 0 auto;
        border-radius: 50%;
        overflow: visible;
        color: rgba(0, 0, 0, 0.54);
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 5px;
        font-size: 1.125rem;
        width: 45px;
    }
}

@media (min-width:576px) and (max-width:796px) {
    .width-size {
        flex: 0 0 auto;
        width: 50%;
    }
    .css-1wjkg3 {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0px;
        border: 0px;
        margin: 0px auto 0px 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-align: center;
        flex: 0 0 auto;
        border-radius: 50%;
        overflow: visible;
        color: rgba(0, 0, 0, 0.54);
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 5px;
        font-size: 1.125rem;
        width: 45px;
    }
}

@media (min-width:796px) and (max-width:992px) {
    .width-size {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .width-size {
        flex: 0 0 auto;
        width: 50%;
    }
}