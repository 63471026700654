.modal-header {
  border-bottom: none;
}
.loadingCartPopLoader {
  position: relative;
  left: 33%;
  margin-top: 13%;
  margin-bottom: 15%;
  font-size: 122%;
}

/*CART POPUP NEW CSS*/
.cartPopupItemDetails .cartProductContainer {
  display: flex;
  align-items: flex-start;
}

.cartPopupItemDetails .cartProductContainer .cartPdtImg {
  width: 100%;
  max-width: 100px;
  height: 100px;
}

.cartPopupItemDetails .cartProductContainer .cartPdtImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cartPopupItemDetails .cartProductContainer .cartItemsDtls {
  margin-left: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.cartPopupItemDetails .cartProductContainer .cartItemsDtls p {
  margin-top: 0px;
}

.cartPopupItemDetails .cartProductContainer .cartItemsDtls .itemTtle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.cartPopupItemDetails .cartProductContainer .cartItemsDtls .itmDscrt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  font-size: 13px;
  margin-bottom: 5px;
}

.cartPopupItemDetails .cartProductContainer .cartItemsDtls .itmPrc {
  margin-bottom: 0;
  font-weight: 600;
}

/*CART POPUP NEW CSS END*/

.CartItemDescription {
  width: 85%;
  display: flex;
  margin-left: 11%;
}
.cartItem {
  border-radius: 20px;
  /* height: 282%; */
  min-height: 94px;
}

.no-data {
  padding: 5%;
}

.itemType {
  color: green;
  border: 3px solid green;
  font-size: 150%;
}
.itemTypeRed {
  color: red;
  border: 3px solid red;
  font-size: 150%;
}
.itemText {
  font-weight: 500;
  font-size: larger;
  margin-left: 20px;
}
.descItems {
  margin-left: 23%;
  width: 148%;
  margin-top: -5%;
}

.itemDescription {
  font-size: 87%;
  font-weight: 500;
  overflow: hidden;
  display: inline-block;
}

.closeButton {
  font-size: larger;
}

.itemImages {
  border-radius: 25px;
  height: 70%;
  padding: 8%;
  width: 80%;
}

.cart-btn {
  width: 30%;
  margin: 3% auto;
  background-color: #ff6838 !important;
  color: white !important;
}

.cart-container {
  display: flex;
}

.cart-img {
  /* height: 20%; */
  /* min-height: 30%; */
  /* min-width: 30%; */
  width: 20%;
}

.cart-item {
  margin-top: 1%;
}

@media screen and (max-width: 500px) {
  .itemDescription {
    font-size: 87%;
    font-weight: 500;
    overflow: hidden;
    display: inline-block;
  }
}
