    /* .menu_container{

    position: absolute;
    width: 44%;
    top: 16%;
}
.user_button{
    width: 85%;
    height: 53%;
    font-size: large;
    border-radius: 25px;
    font-weight: 500;

  }
.location_bar{
    background-color: white!important;
    color: black !important;
}
.menu_list{
    cursor: pointer;
}

.activeclass{
    color: white;
    background-color: orangered;
    border-radius: 17%;
  border: 1px solid black !important;
}
.carousel .carousel-indicators button {
    background-color: black;
    border: none;
    border-radius: 50%; 
width: 10px;
height: 10px;
margin-bottom: 10px;
display: "flex";
align-items: center;
  }
  .carousel .carousel-indicators button.active {
    background-color: orangered;
    width: 12px !important;
    height: 12px !important;
  }
  .card_item_menu_home{
    border-radius: 25px;
    width: 8vw;
    height: 8vw;
}
.food_items_menu_home{
    position: absolute;
    width: 4vw;
    top: 16%;
    left: 25%;
}
.item_title_homepage{
    font-weight: 700;
    font-size: 0.9vw;
    text-align: center;
} */
    
    .MenuitemsTitleHome {
        font-weight: 700;
    }
    
    .imagesMenuItemsHome {
        width: 67%;
        margin-left: 13%;
    }
    
    .BannerCarasoul .carousel-indicators button {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #FF6838!important;
        flex-direction: column!important;
    }
    
    .menu-item-container {
        display: flex;
        flex-direction: row;
        width: 69%;
        justify-content: space-around;
        margin: auto;
        margin: 4% auto;
        cursor: pointer;
        flex-wrap: wrap;
    }
    
    .menu-item-container .menu-item-card {
        min-width: 150px;
        min-height: 150px;
        display: inherit;
        justify-content: center;
        align-items: center;
        border: 3px solid;
        margin: 20px;
    }
    /* .menu-item-container:hover .menu-item-card:hover {
        color: orangered;
    } */
    
    .activeclass {
        color: white;
        background-color: orangered;
        border-radius: 17%;
        border: 1px solid black !important;
    }
    
    .carouselImage {
        width: 92vw !important;
        height: 22vh !important;
        border-radius: 22px;
        margin-left: 11px;
    }
    
    .carousel-item-next {
        transform: translateY(100);
    }
    
    .carousel .carousel-indicators button {
        position: relative;
        bottom: -45px;
        transform: translateY(100);
    }
    
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        display: none
    }
    
    option:focus,
    option:active {
        box-shadow: none !important;
        outline: none;
    }
    
    option:focus {
        background-color: #FFF;
        outline: none;
        border: none;
        box-shadow: none;
    }
    
    .LocationIconHeader {
        color: #FF6838;
        font-size: 1.6vw;
    }
    
    .BannerImageHome {
        width: 100%;
        height: 300px;
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 30px !important;
        border-top-right-radius: 30px !important;
        border: 1px solid rgba(0, 0, 0, 0.03);
        margin-top: 70px!important;
        border-top: 2px solid rgba(0, 0, 0, 0.03)
    }
    
    @media (min-width:320px) and (max-width:376px) {
        .imagesMenuItemsHome {
            width: 60%;
            margin: 1%;
            margin-left: 18%;
        }
        .menu-item-container .menu-item-card {
            min-width: 110px;
            min-height: 110px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 2px solid;
            margin: 10px;
            border-radius: 26px!important;
        }
        .carousel .carousel-indicators button {
            position: relative;
            bottom: -45px;
            transform: translateY(100);
        }
        .menu-item-container {
            display: flex;
            flex-direction: row;
            width: 90%;
            justify-content: space-around;
            margin: auto;
            margin: 4% auto;
            cursor: pointer;
            flex-wrap: wrap;
            margin-top: 55px;
        }
        .BannerImageHome {
            width: 400px;
            height: 150px;
            border-bottom-right-radius: 30px !important;
            border-bottom-left-radius: 30px !important;
            border-top-left-radius: 30px !important;
            border-top-right-radius: 30px !important;
            border: 1px solid rgba(0, 0, 0, 0.03);
            margin-top: -1.2px!important;
            border-top: 2px solid rgba(0, 0, 0, 0.03);
        }
        .MenuitemsTitleHome {
            font-weight: 700;
            font-size: 11px;
        }
    }
    
    @media (min-width:376px) and (max-width:450px) {
        .menu_images {
            width: 530%;
            height: 730%;
        }
        .card_item_menu_home {
            border-radius: 25px!important;
            width: 20vw;
            height: 19vw;
        }
        .activeclass {
            width: 20vw!important;
            height: 21vw!important;
            border-radius: 11%!important;
            border: 1px solid black !important;
            color: white!important;
            background-color: orangered!important;
        }
        .item_title_homepage {
            font-weight: 700;
            font-size: 2.2vw;
            margin: 3px!important;
            margin-left: 3px!important;
        }
        .food_items_menu_home {
            position: absolute;
            width: 8vw;
            top: 23%;
            left: 24%;
        }
        .banner-image {
            width: 100vw!important;
            height: auto!important;
            border-bottom-right-radius: 30px !important;
            border-bottom-left-radius: 30px !important;
            border-top-left-radius: 30px !important;
            border-top-right-radius: 30px !important;
            border: 1px solid rgba(0, 0, 0, 0.03);
            margin-top: -1.2px!important;
            border-top: 2px solid rgba(0, 0, 0, 0.03)
        }
        .menu-item-card {
            min-width: 150px;
            min-height: 150px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 1px solid;
            margin: 20px;
        }
        .menu-item-container .menu-item-card {
            min-width: 110px;
            min-height: 110px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 2px solid;
            margin: 10px;
            border-radius: 26px!important;
        }
        .menu-item-container {
            display: flex;
            flex-direction: row;
            width: 80%;
            justify-content: space-around;
            margin: auto;
            margin: 4% auto;
            cursor: pointer;
            flex-wrap: wrap;
            margin-top: 30px;
        }
        .MenuitemsTitleHome {
            font-weight: 700;
            font-size: 11px;
        }
        .imagesMenuItemsHome {
            width: 60%;
            margin: 1%;
            margin-left: 18%;
        }
    }
    
    @media (min-width:451px) and (max-width:576px) {
        .menu_images {
            width: 530%;
            height: 730%;
        }
        .card_item_menu_home {
            border-radius: 25px!important;
            width: 20vw;
            height: 19vw;
        }
        .activeclass {
            width: 20vw!important;
            height: 21vw!important;
            border-radius: 11%!important;
            border: 1px solid black !important;
            color: white!important;
            background-color: orangered!important;
        }
        .item_title_homepage {
            font-weight: 700;
            font-size: 2.2vw;
            margin: 3px!important;
            margin-left: 3px!important;
        }
        .food_items_menu_home {
            position: absolute;
            width: 8vw;
            top: 23%;
            left: 24%;
        }
        .banner-image {
            width: 100vw!important;
            height: auto!important;
            border-bottom-right-radius: 30px !important;
            border-bottom-left-radius: 30px !important;
            border-top-left-radius: 30px !important;
            border-top-right-radius: 30px !important;
            border: 1px solid rgba(0, 0, 0, 0.03);
            margin-top: -1.2px!important;
            border-top: 2px solid rgba(0, 0, 0, 0.03)
        }
        .menu-item-card {
            min-width: 150px;
            min-height: 150px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 1px solid;
            margin: 20px;
        }
        .menu-item-container .menu-item-card {
            min-width: 110px;
            min-height: 110px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 2px solid;
            margin: 10px;
            border-radius: 26px!important;
        }
        .menu-item-container {
            display: flex;
            flex-direction: row;
            width: 70%;
            justify-content: space-around;
            margin: auto;
            margin: 4% auto;
            cursor: pointer;
            flex-wrap: wrap;
            margin-top: 30px;
        }
        .MenuitemsTitleHome {
            font-weight: 700;
            font-size: 11px;
        }
        .imagesMenuItemsHome {
            width: 60%;
            margin: 1%;
            margin-left: 18%;
        }
    }
    
    @media (min-width:576px) and (max-width:767px) {
        .menu-item-card {
            min-width: 150px;
            min-height: 150px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 1px solid;
            margin: 20px;
        }
        .banner-image {
            width: 508px!important;
            height: 220px!important;
            border-bottom-right-radius: 30px !important;
            border-bottom-left-radius: 30px !important;
            border-top-left-radius: 30px !important;
            border-top-right-radius: 30px !important;
            border: 1px solid rgba(0, 0, 0, 0.03);
            margin-top: -1.2px!important;
            border-top: 2px solid rgba(0, 0, 0, 0.03);
            margin-left: 7px;
            max-width: 118vw!important;
        }
        .menu-item-container .menu-item-card {
            min-width: 110px;
            min-height: 110px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 2px solid;
            margin: 10px;
            border-radius: 26px!important;
        }
        .menu-item-container {
            display: flex;
            flex-direction: row;
            width: 63%;
            justify-content: space-around;
            margin: auto;
            margin: 10% auto;
            cursor: pointer;
            flex-wrap: wrap;
            margin-top: 40px;
        }
        .carousel .carousel-indicators button {
            position: relative;
            bottom: -47px;
            transform: translateY(100);
        }
        .MenuitemsTitleHome {
            font-weight: 700;
            font-size: 11px;
        }
        .imagesMenuItemsHome {
            width: 60%;
            margin: 1%;
            margin-left: 18%;
        }
    }
    
    @media (min-width:769px) and (max-width:992px) {
        .menu-item-card {
            min-width: 150px;
            min-height: 150px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 1px solid;
            margin: 20px;
        }
        .banner-image {
            width: 686px!important;
            height: 250px!important;
            border-bottom-right-radius: 30px !important;
            border-bottom-left-radius: 30px !important;
            border-top-left-radius: 30px !important;
            border-top-right-radius: 30px !important;
            border: 1px solid rgba(0, 0, 0, 0.03);
            margin-top: 50px!important;
            border-top: 2px solid rgba(0, 0, 0, 0.03);
            margin-left: 7px;
            max-width: 118vw!important;
        }
        .menu-item-container .menu-item-card {
            min-width: 120px;
            min-height: 120px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 2px solid;
            margin: 10px;
            border-radius: 26px!important;
        }
        .menu-item-container {
            display: flex;
            flex-direction: row;
            width: 90%;
            justify-content: space-around;
            margin: auto;
            margin: 10% auto;
            cursor: pointer;
            flex-wrap: wrap;
            margin-top: 40px;
        }
        .MenuitemsTitleHome {
            font-weight: 700;
            font-size: 13px;
        }
        .imagesMenuItemsHome {
            width: 70%;
            margin: 1%;
            margin-left: 13%;
        }
    }
    
    @media (min-width:992px) and (max-width:1200px) {
        .menu-item-card {
            min-width: 150px;
            min-height: 150px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 1px solid;
            margin: 20px;
        }
        .banner-image {
            width: 920px!important;
            height: 350px!important;
            border-bottom-right-radius: 30px !important;
            border-bottom-left-radius: 30px !important;
            border-top-left-radius: 30px !important;
            border-top-right-radius: 30px !important;
            border: 1px solid rgba(0, 0, 0, 0.03);
            margin-top: -1.2px!important;
            border-top: 2px solid rgba(0, 0, 0, 0.03);
            margin-left: 7px;
            max-width: 118vw!important;
        }
        .menu-item-container .menu-item-card {
            min-width: 130px;
            min-height: 130px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 2px solid;
            margin: 20px;
            border-radius: 40px;
        }
        .menu-item-container {
            display: flex;
            flex-direction: row;
            width: 90%;
            justify-content: space-around;
            margin: auto;
            margin: 10% auto;
            cursor: pointer;
            flex-wrap: wrap;
            margin-top: 40px;
        }
    }
    
    @media (min-width:1200px) and (max-width:1400px) {
        .menu-item-card {
            min-width: 150px;
            min-height: 150px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 1px solid;
            margin: 20px;
        }
        .banner-image {
            width: 1106px!important;
            height: 380px!important;
            border-bottom-right-radius: 30px !important;
            border-bottom-left-radius: 30px !important;
            border-top-left-radius: 30px !important;
            border-top-right-radius: 30px !important;
            border: 1px solid rgba(0, 0, 0, 0.03);
            margin-top: 70px!important;
            border-top: 2px solid rgba(0, 0, 0, 0.03);
            margin-left: 7px;
            max-width: 118vw!important;
        }
        .menu-item-container .menu-item-card {
            min-width: 130px;
            min-height: 130px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 2px solid;
            margin: 20px;
            border-radius: 40px;
        }
    }
    
    @media (min-width:1400px) and (max-width:1600px) {
        .menu-item-card {
            min-width: 150px;
            min-height: 150px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 1px solid;
            margin: 20px;
            box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
        }
        .banner-image {
            width: 1280px!important;
            height: 380px!important;
            border-bottom-right-radius: 30px !important;
            border-bottom-left-radius: 30px !important;
            border-top-left-radius: 30px !important;
            border-top-right-radius: 30px !important;
            border: 1px solid rgba(0, 0, 0, 0.03);
            margin-top: 70px!important;
            border-top: 2px solid rgba(0, 0, 0, 0.03);
            margin-left: 7px;
            max-width: 118vw!important;
        }
        .menu-item-container .menu-item-card {
            min-width: 130px;
            min-height: 130px;
            display: inherit;
            justify-content: center;
            align-items: center;
            margin: 20px;
            border-radius: 40px;
            box-shadow: 0 1.3rem 1.4rem rgb(0 0 0 / 16%);
            border: 2px solid;
        }
    }
    
    @media (min-width:1600px) and (max-width:1800px) {
        .menu-item-container .menu-item-card {
            min-width: 130px;
            min-height: 130px;
            display: inherit;
            justify-content: center;
            align-items: center;
            border: 2px solid;
            margin: 20px;
            border-radius: 40px;
            border: 2px solid;
        }
    }