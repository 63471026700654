.fvrtPgWrpr {
  padding: 30px;
}

.fvrtPgWrpr .favPgHdr {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.fvrtPgWrpr .fvPgTgln {
  background: rgba(238, 228, 228, 0.963) !important;
  padding: 10px 15px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 5px;
}

.fvrtPgWrpr .favPgScrlbr {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 20px;
}

.fvrtPgWrpr .favPgScrlbr {
  height: 58vh;
  overflow-y: scroll;
  padding: 20px 15px;
  margin-top: 15px;
}

@media (max-width: 850px) {
  .fvrtPgWrpr .favPgScrlbr {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

/*old css*/

.Favourite_title {
  font-size: x-large;
  font-weight: 600;
}

.css-bvz20t .MuiOutlinedInput-root {
  padding: 5px !important;
  width: 100% !important;
}
.openHoursFavourite,
.breakHoursFavourite {
  border-radius: 1px;
  width: 140px;
  font-size: 53%;
  color: black;
  background-color: white !important;
  /* border: 0.3px solid #ff9e81; */
  opacity: 100%;
  text-align: left;
}

.FavouriteDeliveryTime {
  height: 2vh;
  border-radius: 1px;
  width: 7vw;
  font-size: 53%;
  color: black;
  background-color: white !important;
  /* border: 0.3px solid #ff9e81; */
  margin-left: -15%;
}

.FavRestaurent_Title {
  font-weight: 600;
  font-size: 84%;
}

.Favourite_Badge {
  width: 84%;
}

.Favourite_item_card {
  overflow-y: auto;
  overflow-x: hidden;
  /* min-height: 20%!important; */
  /* max-height: 24%!important; */
  border-radius: 16px;
  /* min-height: 100px; */
  width: 124%;
  height: 336px;
}

.Favourite_item_card::-webkit-scrollbar-thumb {
  background-color: orangered !important;
  /* color of the scroll thumb */
  border-radius: 20px !important;
  /* roundness of the scroll thumb */
  border: 3px solid orangered !important;
  /* creates padding around scroll thumb */
}

.Favourite_item_card::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

.Favourite_item_card::-webkit-scrollbar-track {
  background: none;
  /* color of the tracking area */
}

.favourite_card {
  height: 77.5vh;
  width: 57vw;
  border-radius: 0px;
  border: none;
}

.favourite_image_prfile {
  width: 19.5vw;
  height: 13vw;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 18px !important;
  border-top-right-radius: 18px !important;
}

.FavCardProfile_page {
  width: 26vw;
  height: 25vw;
}

.Favourite_Badge_profile {
  background-color: rgba(238, 228, 228, 0.963) !important;
  color: black;
  width: 93vh;
  height: 5vh;
  text-align: left !important;
}

.YourFavouriteRestaurent {
  font-size: 1.3vw;
}

@media (min-width: 320px) and (max-width: 376px) {
  .profile-right-container {
    margin-top: 5%;
    min-height: 72vh;
  }
  .favRating {
    margin-left: -27px !important;
  }
  .openHoursFavourite,
  .breakHoursFavourite {
    border-radius: 1px;
    width: 123px !important;
    font-size: 46% !important;
    color: black;
    background-color: white !important;
    /* border: 0.3px solid #ff9e81; */
    opacity: 100%;
    text-align: left;
  }
  .Favourite_title {
    margin-top: -2% !important;
  }

  .FavouriteDeliveryTime {
    height: 15px;
    border-radius: 1px;
    width: 146% !important;
    font-size: 50% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: -14%;
    margin-top: 6px;
    text-align: left;
  }
  .Favourite_Badge_profile {
    display: flex;
    flex-direction: row;
    width: 150%;
    height: 28px;
  }
  .Favourite_item_card {
    height: 40%;
  }
  .favourite_card {
    height: 120vh;
  }
  .YourFavouriteRestaurent {
    font-size: 13px;
  }
  .favouriteCardProfile {
    width: 100%;
    margin-left: -4%;
  }
  .Favourite_item_card {
    overflow-y: auto;
    overflow-x: hidden;
    /* min-height: 41%!important; */
    /* max-height: 29%!important; */
    border-radius: 16px;
    /* min-height: 100px; */
    width: 171%;
    height: 270px;
  }
  .minimum_valueAllrestaurant {
    margin-top: -21%;
    margin-left: -3%;
  }
  .DeliveryHandledByAllRestuarant {
    margin-left: -10px;
    margin-top: -56px !important;
  }
  .OpenBackTime {
    font-size: 10px;
    color: green !important;
  }
  /* .Favourite_Badge_HomePage {
    left: 81%;
    margin-top: -16px;
    width: 15%;
    height: 40px;
  } */
  /* .favourite {
    top: 18%;
    left: 12%;
    height: 27px;
    width: 71%;
  } */
}

@media (min-width: 376px) and (max-width: 450px) {
  .itemRatingResText {
    font-size: 95%;
    width: 87%;
  }
  .OpenBackTime {
    font-size: 10px;
    color: green !important;
  }
  .favourite_card {
    height: 1330px;
    width: auto;
    border-radius: 0px;
    border: none;
  }
  .FavouriteDeliveryTime {
    height: 1.5vh;
    border-radius: 1px;
    width: 26vw !important;
    font-size: 55% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: -25%;
    margin-top: 7px;
    text-align: left;
    font-weight: 700;
  }
  .Favourite_Badge_profile {
    display: flex;
    flex-direction: row;
    width: 91%;
    height: 7vh;
    margin-left: 4% !important;
  }
  .YourFavouriteRestaurent {
    font-size: 13px;
  }
}
@media (min-width: 576px) and (max-width: 756px) {
  .openHoursFavourite,
  .breakHoursFavourite {
    border-radius: 1px;
    width: 121px !important;
    font-size: 46% !important;
    color: black;
    background-color: white !important;
    opacity: 100%;
    text-align: left;
  }

  .openHoursSearch {
    border-radius: 1px;
    width: 127px !important;
    font-size: 47% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    opacity: 100%;
    text-align: left;
  }
}
@media (min-width: 451px) and (max-width: 576px) {
  .OpenBackTime {
    font-size: 10px;
    color: green !important;
  }
  .favourite_card {
    height: 1200px;
    width: auto;
    border-radius: 0px;
    border: none;
  }
  .FavouriteDeliveryTime {
    height: 5.5vh;
    border-radius: 1px;
    width: 26vw !important;
    font-size: 55% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: -25%;
    margin-top: 7px;
    text-align: left;
    font-weight: 700;
  }
}

@media (min-width: 576px) and (max-width: 769px) {
  .badge123 {
    height: 23px !important;
    width: 27px !important;
    text-align: center !important;
    top: 55%;
  }
  .OpenBackTime {
    font-size: 10px;
    color: green !important;
  }
  .openHoursSearch,
  . {
    width: 21vw !important;
    font-size: 47% !important;
  }
  .FavouriteDeliveryTime {
    height: 4.5vh;
    border-radius: 1px;
    width: 14.5vw !important;
    font-size: 48% !important;
    margin-left: 19% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: -25%;
    margin-top: 7px;
    text-align: left;
    font-weight: 700;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .OpenBackTime {
    font-size: 11px;
    color: green !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .OpenBackTime {
    font-size: 12px;
    color: green !important;
  }
  .foodTitle {
    font-size: 95% !important;
  }
  .Favourite_Badge_ProductListing {
    height: 6%;
    width: 4%;
  }
  .VegOnlyDivProduct {
    width: 20%;
  }
  .SearchIconLanding {
    font-size: 170%;
  }
  .badge123 {
    right: 0%;
  }
  .landingCard_Title {
    margin-left: 4% !important;
  }
  .card_playstore,
  .card_appstore {
    width: 98%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .OpenBackTime {
    font-size: 12px;
    color: green !important;
  }
}
@media (min-width: 475px) and (max-width: 576px) {
  .Favourite_Badge_profile {
    width: 98%;
  }
}
@media only screen and (min-width: 577px) and (max-width: 786px) {
  .Favourite_item_card {
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 16px;
    width: 183%;
    height: 300px !important;
  }
  .favourite_card {
    width: 56%;
    height: 100vw;
  }
  .Favourite_Badge_profile {
    height: 7vh;
    width: 164%;
  }
  .YourFavouriteRestaurent {
    font-size: 1.7vw;
  }
  /* .delivery_time {
    width: 15vw;
    height: 17px !important;
    top: 50%;
    left: 67%;
    padding-bottom: 10px;
    text-align: left;
    margin-left: 18px;
    position: relative;
    top: -5%;
    left: 7%;
    margin-top: 16%;
    font-size: 1.5vw;
  } */
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
  /* .TrendingCardHomepage {
    min-height: 320px;
  } */
  .DescriptionProduct {
    font-size: 80%;
  }
  .minimumValueSearch {
    width: 77%;
    margin-left: -9% !important;
  }
  .VegOnlyDivProduct {
    width: 39%;
  }
  .favourite_card {
    height: 528px !important;
    width: 55vw;
  }
  .Favourite_item_card {
    height: 300px !important;
    width: 124%;
  }
  /* .open_hours_badge,
  .break_hours_badge {
    width: 140px !important;
  } */
  .FavouriteDeliveryTime {
    height: 3.1vh;
    border-radius: 1px;
    width: 10vw !important;
    font-size: 42% !important;
    margin-left: 90% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: -25%!im;
    margin-top: 7px;
    text-align: left;
    font-weight: 700;
  }
  .deliveryTimeSearch {
    margin-left: 27% !important;
    /* width: 13.1vw !important; */
    position: relative;
    left: 61%;
    top: -4% !important;
  }
  .deliveryTrending {
    position: relative;
    width: 14.2vw !important;
    top: 0.5%;
    left: 18%;
  }

  /* .Favourite_Badge_HomePage {
    left: 73%;
  } */
  /* .minimum_value_trending {
    margin-left: -9px;
  } */
  .minValueSearch {
    margin-top: -4% !important;
    margin-left: 2% !important;
  }
  /* .DeliveryHandledByTrending {
    margin-left: -17px;
    margin-top: -27px !important;
  }
  .DeliveryHandledByTrending {
    margin-top: -8% !important;
  } */

  .Restaurent_Close_home {
    margin-left: 5px;
  }
  .RatingCountItemsRestaurant {
    font-size: 12px;
  }
  .openHoursFavourite,
  .breakHoursFavourite {
    width: 108px !important;
    height: 2vw !important;
    font-size: 40% !important;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .favourite_card {
    height: 533px !important;
    width: 60.5vw;
  }
  .Favourite_Badge_profile {
    height: 7vh;
    width: 80vh;
  }

  .Favourite_item_card {
    height: 339px;
  }
  .deliveryTimeSearch {
    /* width: 12.4vw !important; */
    position: relative;
    left: 18%;
    top: 12%;
    height: 64%;
    font-size: 66% !important;
  }
  /* .Favourite_Badge_HomePage {
    left: 81%;
  } */
  /* .minimum_value_trending {
    margin-left: 3px;
    margin-top: -23px;
  } */
  /* .DeliveryHandledByTrending {
    margin-left: -6%;
    margin-top: -5% !important;
  } */
  .Restaurent_Open_Close {
    margin-left: 14%;
  }
  /* .Restaurent_Rating_star {
    margin-left: 20% !important;
  } */
  .FavouriteDeliveryTime {
    height: 5.7vh !important;
    border-radius: 1px;
    width: 11vw !important;
    font-size: 58% !important;
    margin-left: -17% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: -25%;
    margin-top: 7px;
    text-align: left;
    font-weight: 700;
  }
  /* .openBackTimeBadge {
    top: 13%;
  } */
  .Restaurent_Close_home {
    margin-left: 19%;
  }
}
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .Favourite_Badge_profile {
    width: 105%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1330px) {
  /* .minimum_value_trending {
    left: 67%;
  } */
  .openHoursFavourite,
  .breakHoursFavourite {
    border-radius: 1px;
    width: 120px !important;
    font-size: 45% !important;
    color: black;
    background-color: white !important;
    opacity: 100%;
    text-align: left;
  }
  /* .DeliveryHandledByTrending {
    left: 59%;
  } */
  /* .open_hours_badge,
  .break_hours_badge {
    width: 100%;
    font-size: 46%;
    height: 15px;
  } */
  .FavouriteDeliveryTime {
    height: 5vh;
    border-radius: 1px;
    width: 6vw;
    font-size: 51%;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: -9%;
  }
  .deliveryTimeSearch {
    /* width: 104% !important; */
    margin-left: 7% !important;
    margin-top: 7px;
    font-size: 56% !important;
  }
  .OpenBackTime {
    font-size: 71%;
    font-weight: 600;
  }
}

@media only screen and (min-width: 475px) and (max-width: 575px) {
  /* .minimum_value_trending {
    position: absolute;
    width: 42vw;
    height: 5vw;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 3.3vw;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 5px;
    margin-top: -20px;
  } */
  .Favourite_item_card {
    overflow-y: auto;
    overflow-x: hidden;
    /* min-height: 2%!important; */
    /* max-height: 2%!important; */
    height: 301px;
    border-radius: 16px;
    /* min-height: 121px; */
    width: 113%;
  }
  .favourite_card {
    height: 511px;
    width: auto;
    border-radius: 0px;
    border: none;
  }
  /* .DeliveryHandledByTrending {
    position: absolute;
    top: dashed;
    width: 47vw;
    font-size: 3.3vw;
    background: white !important;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: black;
    background-color: white !important;
    font-size: 2.7vw;
    border: 1px solid #ff6838;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 8px;
    margin-top: -16px !important;
  } */
  .Restaurent_Rating_star {
    margin-top: -3px;
  }
  /* .delivery_time {
    font-size: 61% !important;
  } */
  /* .openBackTimeBadge {
    top: 12%;
  } */
}

@media only screen and (min-width: 325px) and (max-width: 474px) {
  .Favourite_item_card {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 14% !important;
    max-height: 18% !important;
    border-radius: 16px;
    /* height: 1260px!important; */
    min-height: 100px;
    width: 110%;
  }
  .favourite_card {
    height: 650px;
  }
  .Restaurent_Close_home {
    margin-left: -10px;
  }
  .Restaurent_Rating_star {
    margin-left: -18px !important;
  }
  /* .DeliveryHandledByTrending {
    margin-left: -7px;
  } */
  /* .minimum_value_trending {
    margin-left: -7px;
  } */
  .Favourite_item_card {
    height: 551vw;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 700px) {
  /* .minimum_value_trending {
    margin-left: 0px;
    margin-top: -22px;
  } */
  .Restaurent_Title {
    font-weight: 600;
    font-size: 14px;
  }
  /* .DeliveryHandledByTrending {
    margin-left: -13px;
    margin-top: -18px !important;
  } */
  /* .Favourite_Badge_HomePage {
    left: 82%;
  } */
  /* .minimum_value_trending {
    margin-left: 1px;
    margin-top: -19px;
  } */
  .Restaurent_Close_home {
    margin-left: -8px;
  }
  .Restaurent_Rating_star {
    color: yellow;
    border: 1px black;
    width: 18px;
    margin-left: -1% !important;
  }
  .RatingCountItemsRestaurant {
    font-size: 12px;
  }
  .Restaurent_Open_Close {
    margin-left: -29%;
  }
  /* .delivery_time {
    font-size: 52% !important;
    width: 14vw !important;
  } */
  .deliveryTimeSearch {
    height: 12px !important;
    margin-left: 25% !important;
    font-size: 50.2% !important;
    width: 147% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 869px) {
  /* .DeliveryHandledByTrending {
    margin-left: -2%;
  } */
  .searchDeliveryHandle {
    margin-top: -4% !important;
    margin-left: 0% !important;
  }
  .Restaurent_Open_Close {
    margin-left: -7px;
  }
}

@media only screen and (min-width: 701px) and (max-width: 767px) {
  .deliveryTimeSearch {
    top: 0% !important;
    left: 29% !important;
    /* width: 12.7vw !important; */
    height: 12px !important;
    font-size: 49% !important;
    position: relative;
  }
  .openHoursSearch {
    height: 15px !important;
    width: 16vw !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 769px) {
  .Favourite_item_card {
    width: 186%;
  }
  .favourite_card {
    height: 86vw !important;
  }
  /* .minimum_value_trending {
    margin-left: -6px;
    margin-top: -19px;
    height: 6%;
  } */
  /* .DeliveryHandledByTrending {
    margin-left: -21px;
  } */
  .Restaurent_Open_Close {
    margin-left: -47%;
  }
}

@media only screen and (min-width: 701px) and (max-width: 766px) {
  .Restaurent_Rating_star {
    width: 3vw;
    margin-left: -24% !important;
  }
  .Restaurent_Open_Close {
    margin-left: -9% !important;
  }
}

@media only screen and (min-width: 325px) and (max-width: 576px) {
  /* .minimum_value_trending {
    position: absolute;
    width: 34vw;
    height: 4.5vw;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    background-color: orangered !important;
    font-size: 2.5vw;
    border: none;
    cursor: pointer;
    border-radius: 1px;
    margin-left: 4px;
    margin-top: -21px;
    top: 37%;
  } */
  /* .DeliveryHandledByTrending {
    margin-left: 3px;
    margin-top: -9px !important;
    font-size: 2vw;
    width: 41vw;
  } */
}

@media only screen and (min-width: 325px) and (max-width: 376px) {
  .Favourite_item_card {
    width: 180% !important;
  }
}

@media screen and (min-width: 474px) and (max-width: 576px) {
  .Favourite_item_card {
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 340px) {
  #payment-form {
    width: 99%;
  }
}
@media screen and (min-width: 376px) and (max-width: 450px) {
  .openHoursFavourite,
  .breakHoursFavourite {
    border-radius: 1px;
    width: 128px !important;
    font-size: 49% !important;
    color: black;
    background-color: white !important;
    opacity: 100%;
    text-align: left;
  }
  .FavouriteDeliveryTime {
    height: 4.7vh;
    border-radius: 1px;
    width: 7vw;
    width: 142% !important;
    font-size: 52% !important;
    color: black;
    background-color: white !important;
    border: 0.3px solid #ff9e81;
    margin-left: -15%;
  }
}
.trendingCarousel .carousel-control-prev {
  transition: none;
}

@media screen and (min-width: 701px) and (max-width: 766px) {
  .searchDeliveryHandle {
    margin-top: -7% !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .openHoursFavourite,
  .breakHoursFavourite {
    border-radius: 1px;
    width: 150px !important;
    font-size: 53% !important;
    color: black;
    background-color: white !important;
    opacity: 100%;
    text-align: left;
    height: 5.7vh !important;
  }
}

@media only screen and (min-width: 450px) and (max-width: 650px) {
  .YourFavouriteRestaurent {
    font-size: 2.7vw;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1444px) {
  .Favourite_Badge_profile {
    width: 83vh;
  }
}
@media only screen and (min-width: 380px) and (max-width: 450px) {
  .openFavRestFav {
    margin-left: -25% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 380px) {
  .openFavRestFav {
    margin-left: -69% !important;
  }
}
