.get_otp {
  width: 102px;
  height: 40px;
  border-radius: 10px;
}

.dropdown_button {
  width: 100px;
  height: 34px;
  text-align: center;
}

.signup_button {
  width: 174px;
  height: 68px;
  border-radius: 10px;
}

.login_btn {
  width: 174px;
  height: 68px;
  border-radius: 10px;
}

.change_text {
  text-align: center !important;
}

.btn232 {
  width: 100px;
}

.sign_up_button {
  width: 309px;
  height: 50px;
  border-radius: 10px;
}

.login_button,
.login_button1 {
  width: 100%;
  font-size: 1.1vw;
  height: 66%;
  color: #ff6838 !important;
  background-color: white !important;
  border: 1px solid black !important;
}

.get_otp_button_login {
  width: 66%;
  text-align: center;
}

.verify {
  width: 100%;
  background-color: white;
  color: #ff6838;
}

.get_otp {
  width: 100%;
  border-radius: 8%;
}

.form-control {
  border: 1px solid black !important;
  z-index: 0 !important;
}

.signup_button_form {
  width: 100%;
  color: white !important;
  background-color: #ff6838 !important;
}

.change_text span {
  color: #ff6838;
  /* font-size: larger; */
}

.textcolor_login {
  color: #ff6838;
  /* font-size: larger; */
}

#dropdown-basic {
  width: 27%;
  height: 87%;
  border-radius: 19px;
}

.dropdown-toggle::after {
  float: right;
  position: absolute;
  top: 43%;
  left: 23%;
}

.Account {
  font-size: x-large;
  font-weight: 600;
}

.Activebutton {
  color: white !important;
  background-color: #ff6838 !important;
  border: 1px solid black !important;
}

.HearAbout_Write_text {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0%;
  width: 65%;
  margin: 2% 0 0 14%;
}

.FormsPopupHeader.modal-header .btn-close {
  position: relative;
  top: 17px;
}

.form-containers {
  display: flex;
  flex-direction: row;
}

.btn-container {
  width: 50%;
}

.btn-container button {
  width: 75%;
  margin: auto;
  display: block;
}

@media (min-width: 320px) and (max-width: 376px) {
  .loginOtp {
    margin-left: 2% !important;
  }
  .LocationInput {
    margin-left: 11%;
  }
  .otpLoginField {
    margin-left: 56% !important;
  }
  .verify {
    width: 175%;
    margin-left: -19% !important;
  }
  .landingCard .card-body {
    margin-right: -1%;
    margin-left: 1%;
  }
  .btn-container button {
    width: 85%;
    margin: auto;
    display: block;
    font-size: 11px;
  }
  .userSignUpDatas {
    margin-left: -19%;
  }
  .hear-abt {
    margin-left: 26%;
  }
  .InputBoxSignUp {
    width: 68%;

    margin-left: 10%;
  }

  .FormsPopupHeader.modal-header .btn-close {
    position: relative;
    top: -50px;
  }

  .change_text {
    /* text-align: center!important; */
    font-size: 15px;
    display: inline;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    left: -20px;
    position: relative;
  }
  .singupLoginText {
    margin-left: 5%;
  }
  .selectBoxSignUp {
    width: 60%;
    margin-left: 19%;
  }
  .signUpTerms {
    width: 53%;
    margin-left: 8%;
  }
  #rememberMe {
    position: relative;
    left: 400%;
    font-size: 87%;
    margin-top: 33%;
  }
  .signTextLanding {
    margin-left: 1%;
    position: relative;
    left: 28%;
  }
  .termsConditionLogin {
    margin-left: 1% !important;
  }
  .ConditionPrivacyTerms {
    font-size: 93%;
  }
  .line-two {
    /* text-align: center!important; */
    font-size: 11px;
    margin-left: 8px;
    width: 75%;
    display: inline-flex;
    position: static;
  }
  .getOtpSignup {
    margin-left: 4% !important;
  }
  .ErrorTextSignup {
    margin-left: 8%;
  }
  .get_otp_button_login {
    margin-left: 4%;
    text-align: center;
    width: 115% !important;
    position: relative;
  }
  .signup_button_form {
    width: 87%;
    margin-left: 24% !important;
  }
  /* .form-control {
        border: 1px solid black !important;
        z-index: 0 !important;
        width: 80%;
        font-size: 17px!important;
    } */
}

@media (min-width: 376px) and (max-width: 450px) {
  .btn-container button {
    width: 75%;
    margin: auto;
    display: block;
    font-size: 11px;
  }
  .SignUpForm {
    margin-left: 5%;
  }
  #rememberMe {
    position: relative;
    left: 460%;
    font-size: 87%;
    margin-top: 28%;
  }
  .signUpTerms {
    width: 87%;
  }
  .signTextLanding {
    margin-left: 1%;
    position: relative;
    left: 35%;
  }
  .termsConditionLogin {
    margin-left: 1% !important;
  }
  .signup_button_form {
    width: 85%;
    margin-left: 24% !important;
  }
  .InputBoxSignUp {
    width: 63%;
    margin-left: 9%;
  }
  .singupLoginText {
    margin-left: -2%;
  }
  .ErrorTextSignup {
    text-align: center;
    margin-left: -25%;
  }
  .getOtpSignup {
    margin-left: 3% !important;
  }
  .userSignUpDatas {
    margin-left: -18%;
  }
  .hear-abt {
    margin-left: 25%;
  }
  .selectBoxSignUp {
    width: 66%;
    margin-left: 16%;
  }
  .CheckBoxContainerSignUp {
    width: 70%;
  }
  .FormsPopupHeader.modal-header .btn-close {
    position: relative;
    top: -50px;
  }
  .change_text {
    /* text-align: center!important; */
    font-size: 15px;
    display: inline;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 90%;
    /* left: -38px; */
    position: relative;
  }
  .line-two {
    /* text-align: center!important; */
    font-size: 11px;
    margin-left: 0px;
    width: 75%;
    display: inline-flex;
    position: static;
  }
  .get_otp_button_login {
    margin-left: -15px;
    text-align: center;
    width: 100% !important;
    position: relative;
  }
  /* .form-control {
        border: 1px solid black !important;
        z-index: 0 !important;
        width: 80%;
        font-size: 17px!important;
    } */
}

@media (min-width: 451px) and (max-width: 576px) {
  .btn-container button {
    width: 75%;
    margin: auto;
    display: block;
    font-size: 13px;
  }
  .SignUpForm {
    margin-left: 7.5%;
  }
  .signup_button_form {
    width: 100%;
    margin-left: 17% !important;
  }
  .signUpTerms {
    width: 93%;
    margin-left: -8%;
  }
  .FormsPopupHeader.modal-header .btn-close {
    position: relative;
    top: -50px;
  }
}

@media (min-width: 576px) and (max-width: 769px) {
  .btn-container button {
    width: 75%;
    margin: auto;
    display: block;
    font-size: 10px;
  }
  .CheckBoxContainerSignUp {
    display: flex;
    flex-direction: row;
    width: 98%;
  }
  .AcceptErrorTextSignup {
    margin-left: 12%;
  }
  .signup_button_form {
    width: 100%;
    margin-left: 22% !important;
  }
  .SignUpForm {
    margin-left: 7%;
  }
  .FormsPopupHeader.modal-header .btn-close {
    position: relative;
    top: -50px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .btn-container button {
    width: 75%;
    margin: auto;
    display: block;
    font-size: 76%;
  }
  .CheckBoxContainerSignUp {
    display: flex;
    flex-direction: row;
    width: 83%;
  }
  .FormsPopupHeader.modal-header .btn-close {
    position: relative;
    top: -50px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .hearAboutInputText {
    /* border: 1px solid black !important; */
    z-index: 0 !important;
    font-size: 15px !important;
  }
  .CheckBoxContainerSignUp {
    display: flex;
    flex-direction: row;
    width: 83%;
  }
}
