.registation_form{margin-top: 100px;}
/* .registation_form::before{content: '';background-color: #E7F3DE;position: absolute;width: 100%;height: 30px;top: 0;} */

.topbg{position: relative;z-index: -1;}
.topbg::before{content: '';background-color: #f59356;position: absolute;width: 100%;height: 30px;top: 0;z-index: -1;}
.registation{background-color: #f59356;height: 150px;}

.header-nav.registation{position: relative;}
.shrink .registation{z-index: 9999;}
/* .header-nav.registation::before{content: '';background-color: #E7F3DE;position: absolute;width: 100%;height: 30px;bottom: -30px;} */
.registationForm{width: 1200px;margin:50px auto;border-radius: 35px;background: #FFF;box-shadow: -5.14639px 48.03299px 106.35876px 0px rgba(0, 0, 0, 0.10);
  padding: 50px;}
.registationForm h2{color: #171717;text-align: center;font-size: 60px;font-style: normal;font-weight: 600;letter-spacing: -2.4px;}
.registationForm p{color: #1E1E1E;font-size: 16px;font-style: normal;font-weight: 500;line-height: 22px;
  width: 800px;text-align: center;margin: auto;margin-bottom: 20px; }
.regfrom .form-group{width: 600px;margin: auto;position: relative;margin-bottom: 20px;text-align: center;}
.regfrom label{color: #1E1E1E;font-size: 20px;font-style: normal;font-weight: 400;line-height: normal;letter-spacing: -0.8px;
  margin-bottom: 15px;}
.regfrom input{border-radius: 50px;padding: 15px 15px 15px 45px;}
.regfrom input::placeholder{font-size: 14px;}
.regfrom input:focus{box-shadow: none;border: 1px solid #ff6838!important;}
.icgreen::before{color: #ff6838;}
.icgreen img{
    width: 25px;
}
.regfrom .del-btn{
  width: 25%;
}
.regfrom .form-group span{position: absolute;top: 50%;
  left: 5%;transform: translate(-50%, -50%);}
.forget a{color: #CF341E;font-size: 16px;font-style: normal;font-weight: 400;line-height: normal;letter-spacing: -0.64px;
  text-transform: capitalize;}
  .forget{padding: 20px 0px;}
.sing{border-radius: 35px;background: #ff6838; padding: 10px 22px; display: flex;align-items: center;justify-content: center;
  color: #FFF;font-size: 16px;font-style: normal;font-weight: 600;line-height: normal;text-transform: capitalize;}
  .inblck{display: inline-block;}
.socialf{border-radius: 35px;background: #3B569A;height: 60px;display: flex;align-items: center;justify-content: center;}
.socialf, .socialg{color: #fff;font-size: 18px;font-style: normal;font-weight: 600;line-height: normal;}
.socialg{border-radius: 35px;background: #CF341E;height: 60px;display: flex;align-items: center;justify-content: center;}
.lineor{width: 600px;height: 1px;background-color: #D1D1D1;margin: auto;position: relative;margin-top: 45px;
  margin-bottom: 45px;}
.lineor h6{position: absolute;left: 50%;top: -18px;transform: translateX(-50%);color: #171717;text-align: center;font-size: 25px;font-style: normal;
  font-weight: 600;letter-spacing: -1px;background: #fff;padding: 5px 10px;}
  .logintxt{
    color: #3d4446;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    margin: 10px 0;
    float: left;
    width: 100%;
    text-align: center;
  }
  .logintxt a{color: #CF341E;}
  .logintxt a:hover{color: #ff6838;}

  @media screen and (max-width: 575px) {
    .regfrom .del-btn{
      width: 50%;
    }
  }