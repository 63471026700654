.ThreeDotsIcon {
  width: 2vw;
  height: 2vw;
  font-weight: 900;
}
.DesiEatsImageLanding {
  cursor: pointer;
}

.logo-container {
  margin-left: 5%;
}

@media (min-width: 320px) and (max-width: 376px) {
  .DesiEatsImageLanding {
    width: 100%;
    max-width: 120px;
    /* margin-left: -45%!important; */
    margin-top: 11px;
    object-fit: contain;
  }
  /* .logo-container {
        margin-left: 35%;
    } */
  .ThreeDotsIcon {
    width: 22px;
    height: 19px;
    font-weight: 900;
    /* margin-left: 3px;
    margin-top: 3px; */
  }

  .button-toggle {
    position: absolute;
    top: 6%;
    left: 37%;
  }
}

@media (min-width: 376px) and (max-width: 450px) {
  .DesiEatsImageLanding {
    width: 100%;
    max-width: 120px;
    /* margin-left: -45% !important; */
    margin-top: 11px;
    object-fit: contain;
  }
  /* .logo-container {
        margin-left: 35%;
    } */
  .ThreeDotsIcon {
    width: 25px;
    height: 25px;
    font-weight: 900;
    /* margin-left: 5px;
        margin-top: 3px; */
  }
  .button-toggle {
    position: absolute;
    top: 6%;
    left: 37%;
  }
}

@media (min-width: 451px) and (max-width: 576px) {
  .DesiEatsImageLanding {
    width: 100%;
    max-width: 120px;
    /* margin-left: -45% !important; */
    margin-top: 11px;
    object-fit: contain;
  }
  /* .logo-container {
        margin-left: 35%;
    } */
  .ThreeDotsIcon {
    width: 30px;
    height: 30px;
    font-weight: 900;
    /* margin-left: 2px;
        margin-top: 3px; */
  }
}

@media screen and (min-width: 567px) and (max-width: 769px) {
  .DesiEatsImageLanding {
    width: 100%;
    max-width: 120px;
    /* margin-left: -45% !important; */
    margin-top: 11px;
    object-fit: contain;
  }
  .CancelDineInItemProfile,
  .ChangeDineInItemProfile {
    width: 10vw;
    font-size: 1.7vw;
  }
  /* .logo-container {
        margin-left: 35%;
    } */
  .ThreeDotsIcon {
    width: 3.5vw;
    height: 3.5vw;
    /* margin-left: -32%; */
    font-weight: 900;
  }
}
@media screen and (min-width: 767px) and (max-width: 769px) {
  .LogoutButtonProfile {
    margin-left: 2%;
  }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
  .AddressDetailsContainer {
    grid-template-columns: 57% 48% !important;
    margin: auto 7% !important;
  }
  .search {
    margin-left: 8% !important;
  }
  .DesiEatsImageLanding {
    width: 100%;
    max-width: 160px;
    /* margin-left: -45% !important; */
    margin-top: 11px;
    object-fit: contain;
  }
  /* .logo-container {
        margin-left: 35%;
    } */
  .ThreeDotsIcon {
    width: 3.5vw;
    height: 3.5vw;
    font-weight: 900;
  }
}
