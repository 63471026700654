.AddressCard_Profilepage{
    width: 57vw;
    height: 37.6vw;
    border-radius: 0px!important;
}
.css-uge3vf{
  margin: 0px 20px 0px 0px!important;
}
.Manage_Address{
    font-size: 120%;
    font-weight: 700;
}
.Address_Badge{
width: 100%;
background-color: rgba(238, 228, 228, 0.963)!important;
}
.address_font{
font-weight: 700;
font-size: 140%;
color: black;
}
.homeText{
font-weight: 700;
font-size: large;
margin-bottom: 10%!important;
}
.homeText span{
    font-weight: 400;

}
.Deleteicon{
  border: 1px solid black;
  cursor: pointer;
  font-size: 1.9vw;
  padding: 2%;
}
.EditIcon{
    border: 1px solid black;
    font-size: 1.9vw;
    color: #FF6838;
    cursor: pointer;
    padding: 2%;

}
.AddButton{
width: 100%;
}
.hrLineAddressProfile{
    width: 90%;
}
.AllAddressesProfilePage{
    overflow-y: auto;
    overflow-x: hidden;
    height: 400px!important;
    border-radius: 16px;


}
.AllAddressesProfilePage::-webkit-scrollbar-thumb {

    background-color: orangered!important;    /* color of the scroll thumb */
    border-radius: 20px!important;       /* roundness of the scroll thumb */
    border: 3px solid orangered!important;  /* creates padding around scroll thumb */
  }
  .AllAddressesProfilePage::-webkit-scrollbar {
    width: 10px;  
             /* width of the entire scrollbar */
  }
  
  .AllAddressesProfilePage::-webkit-scrollbar-track {
    background:none;  
      /* color of the tracking area */
  }

  .address-icon{
    width: 20%;
    display: flex;
    justify-content: space-evenly;
  }

  .address-display{
    width: 80%;
  }

  .address-list{
    width: 80%;
    margin: 1% auto;
    max-height: 300px;
    overflow: auto;
}

.address-list::-webkit-scrollbar-thumb {

    background-color: orangered!important;    /* color of the scroll thumb */
    border-radius: 20px!important;       /* roundness of the scroll thumb */
    border: 3px solid orangered!important;  /* creates padding around scroll thumb */
  }
  .address-list::-webkit-scrollbar {
    width: 10px;  
             /* width of the entire scrollbar */
  }
  
  .address-list::-webkit-scrollbar-track {
    background:none;  
      /* color of the tracking area */
  }
.deleteAddressPopupclose{
  display: flex;
  position: relative;
  margin: auto -3%;
}
@media only screen and (min-width:376px) and (max-width:769px){
  .editConfirmButton{
    width: 70%!important;
    margin-left: 10%;
  }
.editAddressModal{
  width: 86%!important;
}
}
@media only screen and (min-width:370px) and (max-width:410px){
  .editAddressModal{
    width: 95%!important;
  }
}
@media only screen and (min-width:576px) and (max-width:769px){
  .editAddressModal{
    width: 60%!important;
    margin-left: 24%;
    }
  .editConfirmButton{
    width: 91%!important;
    margin-left: -8%;
}
  
}