.DineinCart {
  border-radius: 0px;
  width: 57vw;
  border-radius: "none";
}

.itemTittle {
  font-weight: 700;
  font-size: x-large;
}

.AddressValue {
  font-size: medium;
}

.verticalLine {
  height: 70%;
  border-left: 1px solid #000900;
}

.CancelDineInItemProfile {
  width: 6vw;
  color: white !important;
  background-color: orangered !important;
  font-weight: 600;
  font-size: 1vw;
  text-align: center;
}

.ChangeDineInItemProfile {
  width: 6vw;
  font-weight: 600;
  font-size: 1vw;
  text-align: center;
  background-color: orangered !important;
  color: white !important;
}

.DiningDateTime {
  text-align: center;
}

.NoofMemberprofile {
  text-align: center;
}

@media screen and (max-width: 576px) {
  .CancelDineInItemProfile {
    width: 60px;
    color: white !important;
    background-color: orangered !important;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
  }
  .ChangeDineInItemProfile {
    width: 62px;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    background-color: orangered !important;
    color: white !important;
  }
}
