.home_button,
.office_button,
.other_button {
  width: 100%;
  height: 105%;
  border-radius: 11%;
  border: 1px solid black !important;
  color: black !important;
  background-color: white !important;
}
.DeliveryAddressError {
  font-size: 106%;
  color: red !important;
}
.locationConfirmBtn {
  border-radius: 8px !important;
  width: 55% !important;
}
.badge123 {
  position: absolute;
  top: 29%;
  right: -14%;
  padding: 0px 4%;
  border-radius: 100%;
  background: orangered;
  color: white;
  font-size: 76%;
  /* padding: 0%; */
  font-weight: 600;
}
.confirm_btn {
  border-radius: 60px;
  width: 75%;
  margin: auto;
  background-color: #ff6838 !important;
  color: white !important;
}

.Account {
  text-align: center;
}

.btn-close {
  color: red;
}

.column-gap {
  column-gap: 5%;
}

.btnClass {
  background-color: red;
}

.btnClass.clicked {
  background-color: green;
}

.activeclassTabValue {
  /* width: 90%;
  height: 105%; */
  width: 100% !important;
  height: 105%;
  color: white !important;
  background-color: #ff6838 !important;
}

.btn:hover {
  background-color: #ff6838 !important;
  color: white !important;
}

.locationPopUp_Header .btn-close {
  position: absolute;
  left: 90%;
  top: 4%;
}

.locationPopupForm {
  margin-top: -9%;
}

.locationPopupModal .gogleFormCntnr .customSelectClass > div > div {
  cursor: text !important;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

.css-tlfecz-indicatorContainer {
  display: none !important;
}

.css-yk16xz-control {
  border: 1px solid black !important;
}

.input-style {
  margin-top: 2%;
}

@media (min-width: 320px) and (max-width: 376px) {
  .badge123 {
    top: 29%;
    padding: 0px 5%;
    font-size: 57%;
    right: -36%;
  }
  .OrderTrackRow {
    width: 140%;
  }
  .inputLocation2,
  .inputLocation3,
  .inputLocation4 {
    width: 80%;
    font-size: 14px;
  }
  .css-2b097c-container {
    width: 80%;
  }
  .home_button,
  .office_button,
  .other_button {
    display: flex;
    flex-direction: row;
    width: 124%;
    justify-content: space-around;
  }
  .itemRatingResText {
    font-size: 91% !important;
  }
  .warning {
    font-size: 10px;
    margin-top: 52px;
    margin-left: 0px;
  }
  .column-gap {
    column-gap: 0%;
    margin-left: -6%;
  }
  .confirm_btn {
    border-radius: 60px;
    width: 75%;
    margin: auto;
    background-color: #ff6838 !important;
    color: white !important;
    margin-left: 4px;
  }
  .activeclassTabValue {
    width: 124% !important;
    height: 105%;
    color: white !important;
    background-color: #ff6838 !important;
  }
}

@media (min-width: 376px) and (max-width: 450px) {
  .css-1uvydh2 {
    height: 0.8375em !important;
  }
  .home_button,
  .office_button,
  .other_button {
    display: flex;
    flex-direction: row;
    width: 115%;
    justify-content: space-around;
  }
  .activeclassTabValue {
    width: 122% !important;
    height: 105%;
  }
}

@media (max-width: 600px) {
  .confirm_btn.locationConfirmBtn {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
