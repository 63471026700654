.srchOtrWrpr {
  max-height: 60px;
  margin-top: 140px;
  max-width: 800px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.srchOtrWrpr .search {
  width: 100%;
  margin-right: 20px;
}

.searchPageButton {
  width: 100%;
  max-width: 150px;
  height: 56px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff6838 !important;
  border: none !important;
}

/* .searchIcon {
  position: relative;
  top: -46%;
  right: 0%;
  width: 5%;
  height: 30%;
} */

.search .form-control {
  padding: 0.376rem 2.75rem;
}

/* .SearchIconSE {
  font-size: 120%;
} */
.SearchButtonSE:disabled {
  background-color: gray !important;
}

.SearchHeaderRow {
  width: 100%;
}
.css-gcwvw8-MuiAutocomplete-root {
  width: 100% !important;
}

.scrollbutton {
  margin-top: 5%;
}

.cardsearch {
  overflow-y: auto;
  height: 600px !important;
  border-radius: 16px;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}

.cardsearch::-webkit-scrollbar-thumb {
  background-color: orangered !important;
  /* color of the scroll thumb */
  border-radius: 20px !important;
  /* roundness of the scroll thumb */
  border: 3px solid orangered !important;
  /* creates padding around scroll thumb */
}

.cardsearch::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

.cardsearch::-webkit-scrollbar-track {
  background: none;
  /* color of the tracking area */
}

@media (min-width: 992px) and (max-width: 1200px) {
  .css-uge3vf {
    color: white !important;
  }
}

@media (max-width: 800px) {
  .srchOtrWrpr {
    margin-top: 100px;
  }

  .scrollbutton {
    max-width: 95vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 600px) {
  .srchOtrWrpr .search {
    margin-right: 10px;
  }
  .searchPageButton {
    width: 100%;
    max-width: 56px;
  }
}
