.loaderWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0);
  /* pointer-events: painted; */
  /* backdrop-filter: blur(30px); */
  z-index: 99999999999999999999;
}

.loaderWrapper .MuiCircularProgress-root {
  color: #ff6838;
}
