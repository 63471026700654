.tabSticky {
  background-color: #fff !important;
  position: fixed;
  width: 100%;
  z-index: 100;
}
.dataNotFoundTabPanel {
  text-align: center;
  width: 100%;
  font-weight: 600;
  margin-top: 40px;
}
.foodTitleProductList {
  font-weight: 700;
  font-size: 90%;
}
@media (min-width: 1600px) and (max-width: 2000px) {
  .tabSticky {
    top: 11%;
    width: 70% !important;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .tabSticky {
    top: 12%;
    width: 70% !important;
  }
}
@media (min-width: 796px) and (max-width: 1200px) {
  .tabSticky {
    top: 10%;
    width: 70% !important;
  }
}
@media (min-width: 320px) and (max-width: 796px) {
  .AddressDetailsContainer {
    grid-template-columns: 1fr;
    /* width: 73% !important; */
    /* gap: 5% !important; */
  }
}
@media (min-width: 576px) and (max-width: 796px) {
  .tabSticky {
    top: 15%;

    width: 67% !important;
  }
}
@media (min-width: 376px) and (max-width: 576px) {
  .tabSticky {
    top: 10%;
    width: 100% !important;
  }
  .checkout_button {
    margin-left: 2% !important;
  }
  .confirm_btn {
    margin-left: 5%;
  }
}

@media (min-width: 320px) and (max-width: 376px) {
  .tabSticky {
    top: 8%;
    width: 100% !important;
  }
}
.tab-container {
  width: 100%;
}

.cat-name {
  margin: 2% 0 2% 4%;
}

.itemDescriptionRestaurant {
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  border: 1px solid #000000;
  border: none !important;
  width: 100%;
  font-size: small;
  font-weight: 400;
}

.item-card {
  padding: 1%;
  display: flex;
  flex-direction: row;
  width: 49%;
  margin: 1% 1% 0% 3%;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}

/* .item-card-tabpanel {
  padding: 1%;
  display: flex;
  flex-direction: row;
  width: 45%;
  margin: 1% 1% 0% 3%;
} */

.item-card-tabpanel {
  padding: 10px;
  border-radius: 14px;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
  width: 100%;
}

.item-card-tabpanel .crdInrWrpr {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.item-card-tabpanel .dishImage {
  max-width: 120px;
  width: 100%;
  height: 120px;
}

.item-card-tabpanel .dishImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.item-card-tabpanel .dshDtls {
  width: 100%;
  margin-left: 15px;
}

.item-card-tabpanel .dshDtls .dshTtle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.item-card-tabpanel .dshDtls .dshDscpt {
  font-size: 12px;
  color: gray;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}

.item-card-tabpanel .btnWrpr {
  margin-left: auto;
  max-width: 120px;
}

.item-card-tabpanel .prcContnr .offer-price {
  width: 100%;
}

.item-card-tabpanel .prcContnr p {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
  color: rgb(95, 95, 95);
}

.css-1ujnqem-MuiTabs-root {
  overflow: hidden;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}

.floating-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#basic-button {
  background-color: #ff6838;
  color: white;
  border-radius: 40px;
  position: relative;
  top: -66%;
  left: -50%;
}

/* .item-card div{
  margin: 0 2%;

} */

/* .item-card-container {
  display: flex;
  flex-wrap: wrap;
  width: 94%;
  margin: 0 auto -6% auto;
} */

.item-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 20px;
  width: 94%;
  margin: 0 auto -6% auto;
}

.category-theme {
  margin: 0 0 12% 0;
}

.btn-div-cont {
  position: relative;
}

.add-btn {
  /* position: absolute;
  right: 25%;
  bottom: 10%; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.add-btn button {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
  background-color: orangered !important;
  color: white !important;
}

.in-de-btn {
  width: 120%;
  background-color: orangered !important;
  color: white !important;
}

.add-tab-btn {
  margin: auto !important;
}

.card1 {
  width: 10%;
}

.card2 {
  width: 60%;
}

.card3 {
  width: 30%;
}

.striking-text {
  text-decoration: line-through;
  font-weight: 100;
  margin-bottom: 0%;
}

.price-offer-container {
  display: flex;
  flex-direction: row;
}

.offer-price {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.currently-unavailable {
  background-color: orangered;
  color: white;
  padding: 2px 4px;
  height: 25px;
  border-radius: 10px;
  font-size: 0.9em;
  width: max-content;
  margin-top: 8px;
}

.not-allow-cursor {
  cursor: not-allowed;
}

@media (min-width: 320px) and (max-width: 376px) {
  .item-card-container {
    /* width: 200%; */
    flex-wrap: wrap;
    display: flex;
    margin: 0 auto 10% auto;
    flex-direction: column;
  }
  .item-card {
    width: 80%;
  }
  .item-card {
    padding: 1%;
    display: flex;
    flex-direction: row;
    width: 75%;
    margin: 10% 1% 0% 3%;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
  }
  .css-1ujnqem-MuiTabs-root {
    overflow: hidden;
    min-height: 48px px;
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 450px;
    display: flex;
    background-color: white;
  }
  .css-k008qs {
    display: flex;
    width: 20%;
    margin-right: 35px;
  }

  /* .currently-unavailable {
    background-color: #ff4500;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    height: 25px;
    padding: 4px 5px;
    margin-left: 10px;
    font-weight: 600;
  } */
}

@media (min-width: 376px) and (max-width: 450px) {
  .item-card-container {
    /* width: 200%; */
    flex-wrap: wrap;
    display: flex;
    margin: 0 auto 10% auto;
    flex-direction: column;
  }
  .item-card {
    width: 80%;
  }
  .item-card {
    padding: 1%;
    display: flex;
    flex-direction: row;
    width: 75%;
    margin: 10% 1% 0% 3%;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
  }
  .css-1ujnqem-MuiTabs-root {
    overflow: hidden;
    min-height: 48px px;
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 450px;
    display: flex;
    background-color: white;
  }
  .css-k008qs {
    display: flex;
    width: 20%;
    margin-right: 35px;
  }

  /* .currently-unavailable {
    background-color: orangered;
    color: white;
    padding: 2px 10px;
    height: 25px;
    border-radius: 10px;
    font-size: 12px;
  } */
}

@media (min-width: 451px) and (max-width: 576px) {
  .item-card-container {
    /* width: 200%; */
    flex-wrap: wrap;
    display: flex;
    margin: 0 auto 10% auto;
    flex-direction: column;
  }
  .item-card {
    width: 80%;
  }
  .item-card {
    padding: 1%;
    display: flex;
    flex-direction: row;
    width: 75%;
    margin: 10% 1% 0% 3%;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
  }
  .css-1ujnqem-MuiTabs-root {
    overflow: hidden;
    min-height: 48px px;
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 150%;
    display: flex;
    background-color: white;
  }
  .css-k008qs {
    display: flex;
    width: 20%;
    margin-right: 35px;
  }

  /* .currently-unavailable {
    background-color: orangered;
    color: white;
    padding: 2px 4px;
    height: 25px;
    border-radius: 10px;
    font-size: 0.9em;
  } */
}

@media (min-width: 576px) and (max-width: 769px) {
  .item-card-container {
    /* width: 100%; */
    flex-wrap: wrap;
    display: flex;
    margin: 0 auto 10% auto;
  }
  .item-card {
    width: 80%;
  }
  .item-card {
    padding: 1%;
    display: flex;
    flex-direction: row;
    width: 75%;
    margin: 10% 1% 0% 3%;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
  }
  .css-1ujnqem-MuiTabs-root {
    overflow: hidden;
    min-height: 48px px;
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 150% !important;
    display: flex;
    background-color: white;
  }
  .css-k008qs {
    display: flex;
    width: 20%;
    margin-right: 35px;
  }

  .card2 {
    width: 60%;
    margin-left: 10px;
  }
  /* .currently-unavailable {
    background-color: #ff4500;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    height: 25px;
    padding: 3px 10px;
    margin-left: -20px;
    font-weight: 600;
    width: 80%;
    margin-top: 28px;
  } */
}

@media (min-width: 769px) and (max-width: 992px) {
  .AddVarientButton {
    width: 72% !important;
    margin-left: 11% !important;
  }
  .item-card-container {
    /* width: 100%; */
    flex-wrap: wrap;
    display: flex;
    margin: 0 auto 10% auto;
  }
  .item-card {
    width: 80%;
  }
  .item-card {
    padding: 1%;
    display: flex;
    flex-direction: row;
    width: 75%;
    margin: 10% 1% 0% 3%;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
  }
  .css-1ujnqem-MuiTabs-root {
    overflow: hidden;
    min-height: 48px px;
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 150% !important;
    display: flex;
    background-color: white;
  }
  .css-k008qs {
    display: flex;
    width: 50%;
  }

  /* .currently-unavailable {
    padding: 5px 2px;
    height: 25px;
    font-size: 60%;
    margin-top: 38%;
    font-weight: 600;
    margin-left: -20%;
  } */
  .card2 {
    width: 60%;
    padding-left: 5px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .card2 {
    width: 60%;
    padding-left: 5px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .dataNotFoundTabPanel {
    padding: 2%;
    text-align: center;
    /* margin-left: -47%; */
  }
  .card2 {
    width: 60%;
    padding-left: 7px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1000px) {
  .dataNotFoundTabPanel {
    text-align: center;
    /* margin-left: 41%;
    margin-top: 8%; */
  }
}
