.btn {
  background-color: #ff6838;
  color: white;
  /* border: none; */
  /* background-color: white; */
  cursor: pointer;
  border: 1px solid white;
}

.btn:hover {
  background-color: white !important;
  color: #ff6838ed !important;
  cursor: pointer;
  border: 1px solid black !important;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: white;
}

body::-webkit-scrollbar-thumb {
  background-color: orangered;
  border-radius: 20px;
}

/* body {
    scrollbar-width: thin;
} */

@media screen and (max-width: 376px) {
  body {
    overflow-x: hidden;
    min-height: 100vh;
    max-width: 100%;
    margin: 0 0;
  }
}

@media (max-width: 600px) {
  .btn:hover {
    color: white !important;
    background-color: #ff6838ed !important;
  }
}
