.icons-style {
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
  padding: 2% 4%;
}

.all-icon {
  font-size: 1.5vw;
  margin: auto;
  width: 100%;
}

.iconsRestaurentView {
  cursor: pointer;
}

.cardTitle {
  font-weight: 700;
  font-size: 150%;
}

.userProfileFilled {
  text-align: center;
  margin: 1%;
}

.your-cart-text {
  font-size: 28px;
  text-align: center;
  margin: 4% 0 2% 0;
}

.clear-all {
  width: 90%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 26px;
  margin-bottom: 15px;
  color: orangered;
}

.icons-container {
  display: flex;
  flex-direction: row;
  width: 55%;
  justify-content: space-evenly;
  margin: auto;
}

.cart-desc {
  /* font-Weight: 600; */
  text-align: center;
  /* margin: 1% auto 1% auto; */
  /* width: 70%; */
}

.cutlery-content {
  /* margin: 1% auto 1% 4%; */
  margin: -3% auto 0% 4%;
  width: 100%;
  font-size: 1vw;
}

.higlight {
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .icons-style {
    border: 1px solid black;
    border-radius: 50%;
    cursor: pointer;
    padding: 2% 4%;
  }
  .all-icon {
    font-size: 4.5vw;
    margin: auto;
    width: 100%;
  }
  .your-cart-text {
    font-size: 28px;
    text-align: center;
    margin: 4% 0 2% 0;
  }
  .cart-desc {
    font-weight: 600;
    text-align: center;
    margin: 1% auto 1% auto;
    /* width: 70%; */
    font-size: 15px;
  }
  .cutlery-content {
    /* margin: 4% auto 4% 4%; */
    margin: 0% auto 0% 5%;
    width: 100%;
    font-size: 4vw;
    /* text-align: center; */
  }
}

@media (min-width: 576px) and (max-width: 796px) {
  .icons-style {
    border: 1px solid black;
    border-radius: 50%;
    cursor: pointer;
    padding: 2% 4%;
  }
  .all-icon {
    font-size: 2.5vw;
    margin: auto;
    width: 100%;
  }
  .your-cart-text {
    font-size: 4vw;
    text-align: center;
    margin: 4% 0 2% 0;
  }
  .cart-desc {
    font-weight: 600;
    text-align: center;
    margin: 1% auto 1% auto;
    width: 70%;
  }
  .cutlery-content {
    margin: -2% auto 2% 4%;
    width: 100%;
    font-size: 4vw;
    text-align: center;
  }
}

@media (min-width: 796px) and (max-width: 992px) {
  .icons-style {
    border: 1px solid black;
    border-radius: 50%;
    cursor: pointer;
    padding: 2% 4%;
  }
  .all-icon {
    font-size: 2.5vw;
    margin: auto;
    width: 100%;
  }
  .your-cart-text {
    font-size: 125%;
    text-align: center;
    margin: 4% 0 2% 0;
  }
  .cart-desc {
    font-weight: 600;
    text-align: center;
    margin: 1% auto 1% auto;
    width: 85%;
    font-size: 83%;
  }
  .cutlery-content {
    margin: -2% auto 2% 4%;
    width: 100%;
    font-size: 78%;
    text-align: center;
  }
  .order-text p {
    font-size: 92%;
  }
  .foodTitle {
    font-size: 90% !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .icons-style {
    border: 1px solid black;
    border-radius: 50%;
    cursor: pointer;
    padding: 2% 4%;
  }
  .all-icon {
    font-size: 2.5vw;
    margin: auto;
    width: 100%;
  }
  .your-cart-text {
    font-size: 24px;
    text-align: center;
    margin: 4% 0 2% 0;
  }
  .cart-desc {
    font-weight: 600;
    text-align: center;
    margin: 1% auto 1% auto;
    width: 70%;
  }
  .cutlery-content {
    margin: -2% auto 2% 4%;
    width: 100%;
    font-size: 15px;
    text-align: center;
  }
}
