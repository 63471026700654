.Login_Or_Sign {
  width: 28%;
  height: 88%;
}
.spaceSelfPickup {
  margin-top: 2%;
}
.SelfPickUpDiv {
  display: flex;
  flex-direction: row;
}
.have_an_accoubt {
  font-size: small;
  text-align: center;
  font-weight: 600;
}

.EstimatedDate {
  width: 73%;
}

.login_signup {
  font-size: small;
  font-weight: 600;
}

.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  margin-left: -18%;
  padding: 9%;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.contactless {
  background-color: white !important;
  justify-content: unset;
  display: flex;
  margin: 0;
}

.contactless_delivery {
  font-weight: 500;
  color: black;
  font-size: 130%;
  margin-bottom: 2%;
  float: left;
}

.order_content_details {
  color: black;
  font-weight: 400;
  float: left;
  font-size: 0.9vw;
  margin-bottom: 5% !important;
}

.contactless_deliveryBadgePayment {
  margin-left: 10%;
}

.checkmarkpaymentLogin {
  width: 2vw;
}

.contactLessPayment {
  color: black;
  /* font-size: 1.2rem; */
  width: auto;
  text-align: left;
  font-weight: 600;
  margin: 2% 3%;
}

.contactLessInformationPayment {
  color: hsl(0deg 4% 31%) !important;
  font-size: 0.8rem;
  text-align: left;
  font-weight: 600;
  margin: 2% 3%;
}

.BadgeContactLessPayment {
  color: black;
  font-size: 1.2rem;
  text-align: left;
  font-weight: 600;
}

.contentContainerPayment {
  width: 75%;
}

.deliveryTimePayment {
  display: flex;
  margin: auto 2%;
}

.formSelectContainer {
  display: flex;
  margin: auto;
  width: 92%;
}

.hrLinepayment {
  margin: 4% 10% !important;
}

.LoginAccountPayment {
  width: 80%;
  margin: 3% 8%;
}
@media screen and (min-width: 320px) and (max-width: 992px) {
  .LoginAllTimeDate {
    margin-left: 2.5%;
  }
  .SwitchToggleMenu {
    margin-top: -5%;
  }
}
@media screen and (min-width: 320px) and (max-width: 376px) {
  .contactless {
    background-color: rgb(217, 217, 217, 0.3) !important;
    width: 98%;
    justify-content: unset;
    display: flex;
    margin: auto 4%;
  }
  .contactLessInformationPayment {
    color: hsl(0deg 4% 31%) !important;
    font-size: 0.8rem;
    text-align: left;
    font-weight: 600;
    margin: 2% 3%;
  }
  .formSelectContainer {
    display: flex;
    margin: auto;

    width: 113%;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .Login_Or_Sign {
    width: 59%;
    height: 88%;
  }
  .checkmarkpaymentLogin {
    width: 20px;
  }
  .contactLessPayment {
    color: black;
    font-size: 15px;
    width: auto;
    text-align: left;
    font-weight: 600;
    margin: 6% 3%;
  }
}

@media screen and (min-width: 376px) and (max-width: 450px) {
  .contactless {
    background-color: rgb(217, 217, 217, 0.3) !important;
    width: 98%;
    justify-content: unset;
    display: flex;
    margin: auto 4%;
  }
  .contactLessInformationPayment {
    color: hsl(0deg 4% 31%) !important;
    font-size: 0.8rem;
    text-align: left;
    font-weight: 600;
    margin: 2% 3%;
  }
  .formSelectContainer {
    display: flex;
    margin: auto;
    width: 92%;
    flex-wrap: wrap;
  }
  .Login_Or_Sign {
    width: 59%;
    height: 88%;
  }
  .checkmarkpaymentLogin {
    width: 20px;
  }
  .contactLessPayment {
    color: black;
    font-size: 18px;
    width: auto;
    text-align: left;
    font-weight: 600;
    margin: 6% -6%;
  }
}

@media screen and (min-width: 320px) and (max-width: 414px) {
  .css-igs3ac {
    left: 3% !important;
    right: 3% !important;
    bottom: 7% !important;
    top: 4% !important;
  }
  .formSelectContainer {
    width: 122% !important;
  }
  .css-slyssw {
    margin: 0px 0px 0px 0px;
  }
  .selectBoxPaymentTwo {
    width: 104%;
    left: 9%;
    position: relative;
    font-size: 0.8em;
    top: 11%;
    height: 44px;
  }
}

@media screen and (min-width: 415px) and (max-width: 450px) {
  .selectBoxPaymentTwo {
    width: 111%;
    left: 22%;
    position: relative;
    font-size: 1.1em;
    top: 0%;
    height: 53px;
  }
}
@media screen and (min-width: 450px) and (max-width: 576px) {
  .selectBoxPaymentTwo {
    width: 111%;
    left: 22%;
    position: relative;
    font-size: 1.1em;
    top: 0%;
    height: 53px;
  }
  .contactless {
    background-color: rgb(217, 217, 217, 0.3) !important;
    width: 98%;
    justify-content: unset;
    display: flex;
    margin: auto 6%;
  }
  .contactLessInformationPayment {
    color: hsl(0deg 4% 31%) !important;
    font-size: 0.8rem;
    text-align: left;
    font-weight: 600;
    margin: 2% 3%;
  }
  .formSelectContainer {
    display: flex;
    margin: auto;
    width: 92%;
    flex-wrap: wrap;
  }
  .Login_Or_Sign {
    width: 59%;
    height: 88%;
  }
  .checkmarkpaymentLogin {
    width: 20px;
  }
  .contactLessPayment {
    color: black;
    font-size: 15px;
    width: auto;
    text-align: left;
    font-weight: 600;
    margin: 6% 3%;
  }
}

@media screen and (min-width: 576px) and (max-width: 796px) {
  .contactless {
    background-color: rgb(217, 217, 217, 0.3) !important;
    width: 98%;
    justify-content: unset;
    display: flex;
    margin: auto 6%;
  }
  .contactLessInformationPayment {
    color: hsl(0deg 4% 31%) !important;
    font-size: 0.8rem;
    text-align: left;
    font-weight: 600;
    margin: 2% 3%;
  }
  .formSelectContainer {
    display: flex;
    margin: auto;
    width: 92%;
    flex-wrap: wrap;
  }
  .Login_Or_Sign {
    width: 59%;
    height: 88%;
  }
  .checkmarkpaymentLogin {
    width: 20px;
  }
  .contactLessPayment {
    color: black;
    font-size: 15px;
    width: auto;
    text-align: left;
    font-weight: 600;
    margin: 6% 3%;
  }

  .selectBoxPaymentTwo {
    width: 111%;
    left: 22%;
    position: relative;
    font-size: 1.1em;
    top: 0%;
    height: 53px;
  }
}

@media screen and (min-width: 796px) and (max-width: 992px) {
  .css-1uvydh2 {
    height: 0.4375em !important;
  }
  .css-igs3ac {
    inset: -4px 5px 4px !important;
  }
  .EstimatedDate {
    margin-top: 1% !important;
  }
  .css-vubbuv {
    position: relative;
    font-size: 1.5rem !important;
  }
  .css-177ic5c {
    width: 30%;
  }
  .selectBoxPaymentTwo {
    font-size: 0.7em;
    height: 33.5px;
    left: -47%;
    position: relative;
    top: 5.2%;
    width: 98%;
  }
  .css-1bn53lx {
    width: 98%;
    font-size: 0.7rem !important;
  }
  /* .css-igs3ac{
        inset: none!important;
           left: 1%!important;
        right: 31%!important;
        top: 1%!important;
        bottom: 14%!important;
    } */

  .css-slyssw {
    margin: 0px 32px 0px 0px;
  }

  .contactless {
    background-color: rgb(217, 217, 217, 0.3) !important;
    width: 106%;
    justify-content: unset;
    display: flex;
    margin: auto 0%;
  }
  .contactLessInformationPayment {
    color: hsl(0deg 4% 31%) !important;
    font-size: 0.8rem;
    text-align: left;
    font-weight: 600;
    margin: 2% 3%;
  }
  .formSelectContainer {
    display: flex;
    margin: auto;
    width: 148%;
    flex-wrap: wrap;
  }
  .Login_Or_Sign {
    width: 51%;
    height: 88%;
  }
  .checkmarkpaymentLogin {
    width: 20px;
  }
  .contactLessPayment {
    font-size: 13px;
    margin: 2% 0%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .css-slyssw {
    margin: 0px 34px 0px 0px;
  }
  .selectBoxPaymentTwo {
    width: 106%;
    left: -39%;
    position: relative;
    font-size: 0.7em;
    top: 13%;
    height: 35px;
  }
  .contactless {
    background-color: rgb(217, 217, 217, 0.3) !important;
    width: 106%;
    justify-content: unset;
    display: flex;
    margin: auto 0%;
  }
  .contactLessInformationPayment {
    color: hsl(0deg 4% 31%) !important;
    font-size: 0.8rem;
    text-align: left;
    font-weight: 600;
    margin: 2% 3%;
  }
  .formSelectContainer {
    display: flex;
    margin: auto;
    width: 120%;
    flex-wrap: wrap;
  }
  .Login_Or_Sign {
    width: 59%;
    height: 88%;
  }
  .checkmarkpaymentLogin {
    width: 20px;
  }
  .contactLessPayment {
    color: black;
    font-size: 13px;
    width: auto;
    text-align: left;
    font-weight: 600;
    margin: 8% 0%;
  }

  /* .css-igs3ac {
    left: 1% !important;
    right: 29% !important;
    top: 3% !important;
    bottom: 16% !important;
  } */
}

@media screen and (min-width: 1200px) and (max-width: 1800px) {
  /* .css-igs3ac {
    left: 3% !important;
    right: 3% !important;
    top: 6% !important;
    bottom: 14% !important;
  } */
  .selectBoxPaymentTwo {
    position: relative;
    top: 13%;
    height: 40px;
    left: 6%;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1800px) {
}

.saveMoreTxtcolor {
  color: orangered;
}
