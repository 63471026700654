body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url(./Asserts/background/DOODLE.png); */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 1800px) {
  body {
    width: 100%;
  }
  #root {
    width: 100%;
    margin: 0 0;
    overflow-x: hidden;
  }
}

.loader {
  border: 3px solid white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

.loder-position {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
}
@keyframes rotate {
  0% {
    transform: rotateZ(-360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
.loading-text {
  color: white;
  font-family: Arial;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: 1%;
}

.loader-container {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.TrendingCardHomepage .Favourite_Badge_HomePage {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 25px;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TrendingCardHomepage .Favourite_Badge_HomePage .favourite {
  font-size: 40px;
}

.TrendingCardHomepage {
  width: 100%;
  min-height: 280px;
}

.TrendingCardHomepage .DeliveryHandledByTrending {
  position: absolute;
  top: calc(150px - 17px);
  width: 100%;
  font-size: 12px;
  background: white !important;
  background-color: #555;
  color: black;
  background-color: whitesmoke !important;
  border-radius: 0px;
  cursor: pointer;
}

.TrendingCardHomepage .minimum_value_trending {
  position: absolute;
  width: 100%;
  /* max-width: 160px; */
  max-width: max-content;
  height: auto;
  background-color: #555;
  color: white;
  background-color: orangered !important;
  font-size: 11px;
  border: none;
  cursor: pointer;
  border-radius: 1px;
  top: 112px;
  right: 4px;
}

.TrendingCardHomepage .open_hours_badge,
.TrendingCardHomepage .break_hours_badge {
  position: absolute !important;
  left: 6px;
  right: unset !important;
  margin-left: 0 !important;
  background: white !important;
  color: black !important;
}

.TrendingCardHomepage .open_hours_badge {
  top: 35px;
}

.TrendingCardHomepage .break_hours_badge {
  top: 58px;
  bottom: unset !important;
  margin-top: unset !important;
  margin-bottom: unset !important;
}

.TrendingCardHomepage .openBackTimeBadge {
  position: absolute;
  top: 10px;
  background: white !important;
  left: 6px;
  font-size: 12px !important;
  /* width: 100%; */
  border-radius: 5px;
}

.TrendingCardHomepage .openBackTimeBadge .OpenBackTime {
  font-size: 1em !important;
  color: green !important;
}

.TrendingCardHomepage .delivery_time,
.TrendingCardHomepage .deliveryTimeSearch {
  position: absolute !important;
  top: 84px !important;
  left: unset !important;
  right: 6px !important;
  text-align: left;
  margin-left: 0px !important;
  background-color: white !important;
  color: black !important;
  height: auto !important;
  padding: 4px;
}

.TrendingCardHomepage .Restaurent_Title {
  font-weight: 600;
  font-size: medium;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 51px;
  margin: 0;
}

.RestCloseDiv br {
  display: none;
}

.TrendingCardHomepage .Restaurent_SubTitle {
  margin-top: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.inptCntFld.MuiTextField-root {
  width: 100%;
}

.cartApiBtn {
  position: relative;
}

.cartApiBtn .badge123 {
  width: 20px;
  height: 20px;
  top: 0;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

@media (max-width: 600px) {
  .btn:hover {
    color: white !important;
    background-color: #ff6838ed !important;
  }

  .contactLessPayment.slfPckupTxt {
    margin-left: auto;
    font-size: 14px;
  }

  .Restaurent_Rating_star {
    position: relative;
    top: 0px;
  }
}
