.restaurent-header-container {
  width: 70%;
}

.restaurent-header-container-full-width {
  width: 100%;
}

.viewCartContainer {
  position: fixed;
  bottom: 80px;
  right: 25px;
}

.res-cart-container {
  width: 30%;
  position: fixed;
  right: 0;
  top: 13%;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 88%;
  z-index: 100;
  box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 12%);
}

.res-container {
  display: flex;
  flex-direction: row;
}

.res-image-container {
  width: 70%;
  padding: 1%;
  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: white;
}

.res-adress-container {
  width: 100%;
  display: flex;
  padding: 1%;
}

@media screen and (max-width: 376px) {
  .res-image-container {
    width: 33%;
    padding: 1%;
    display: flex;
  }
  .res-cart-container {
    width: 30%;
  }
  .viewCartContainer {
    position: fixed;
    bottom: 77px;
    right: 15px !important;
    width: 115px;
  }
}

@media (min-width: 376px) and (max-width: 450px) {
  .viewCartContainer {
    position: fixed;
    bottom: 77px;
    right: 15px !important;
    width: 115px;
  }
}

@media (min-width: 450px) and (max-width: 576px) {
  .viewCartContainer {
    position: fixed;
    bottom: 77px;
    right: 15px !important;
    width: 115px;
  }
}

@media screen and (max-width: 1440px) {
  .logo-size {
    width: 34%;
    padding: 1%;
    display: flex;
  }
  .res-cart-container {
    width: 30.5%;
    margin-top: 31px;
  }
}
